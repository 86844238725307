.available {
  border-radius: 20px !important;
}
.workExp {
  border-bottom: 1px solid #d7d8e2;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  margin-top: 15px;
  margin-bottom: 15px;
}

.TOpSkils ul {
  display: flex;
  column-gap: 10px;
  margin-bottom: 8px;
}
.TOpSkils ul li span {
  font-size: 16px;
  background: #0053cd;
  color: #fff;
  padding: 5px 21px;
  display: inline-block;
  border-radius: 20px;
}
.TOpSkils h5 {
  font-size: 14px;
  font-weight: 600;
  margin: 13px 0 10px;
}
.selRating {
  display: flex;
  align-items: center;
  padding-bottom: 6px;
}
.selRating label {
  color: #000;
  font-size: 14px;
  font-weight: 400;
}
.selRating span {
  width: 14px;
  height: 14px;
  display: flex;
  border: 1px solid #0053cd;
  border-radius: 100px;
  margin: 0 2px;
}
.selRating span.filled {
  background: #0053cd;
}
.bostns {
  position: relative;
  margin: 23px 0 0;
}
button.ShareProfile::after {
  display: none;
}
button.ShareProfile {
  background: #0053cd14;
  border: 0;
  font-size: 16px;
  font-weight: 600 !important;
  color: #0053cd;
  margin-left: 13px;
  padding: 5px 10px !important;
  padding-right: 38px;
  height: 38px !important;
  border-radius: 8px;
  position: relative;
  min-width: 100px !important;
}
button.ShareProfile::before {
  content: "";
  position: absolute;
  right: 17px;
  top: 44%;
  width: 9px;
  height: 9px;
  border: 2px solid #0053cd;
  border-top: 0;
  border-right: 0;
  transform: translateY(-50%) rotate(-45deg);
}
.bostns ul {
  position: absolute;
  width: 165px;
  border: 1px solid #0053cd;
  border-radius: 10px;
  right: 0;
  top: 50px;
  background: #fff;
  padding: 0px !important;
}
.bostns ul li:hover {
  background-color: #3e97d33d;
}
.bostns ul li a {
  display: flex;
  align-items: center;
  column-gap: 8px;
  text-decoration: none;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  padding: 7px 10px;
}
.workExp ul {
  margin: 0;
}
.workExpSkill {
  display: flex;
  margin: 20px 0 20px;
  align-items: center;
}
.workExpSkill h6 {
  padding: 0;
  font-weight: bold;
  margin: 0;
}
.workExpSkill span {
  font-size: 13px;
}
.workExpSkill p {
  margin: 0;
  font-size: 13px;
}
.workExp img {
  height: 60px;
}

.openwork i {
  margin-bottom: 10px;
}

.openwork p {
  font-size: 15px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  margin-bottom: 0;
  color: #0053cd;
}

figure.profileCircle img {
  width: 100%;
  border-radius: 100px;
  max-width: 100px;
  max-height: 100px;
  min-height: 100px;
  min-width: 100px;
  object-fit: cover;
  object-position: top;
  border: 3px solid rgb(11, 11, 219);
}

.openwork {
  background: #fff;
  height: calc(100% - 6px);
  border: 1px dashed #0053cd;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 15px;
}

figure.bntColor {
  background: #0e53cd;
  padding: 50px 0;
  border-radius: 12px 12px 0 0;
}

.new_feeds {
  margin-top: 20px;
  background-color: #fff;
  border-radius: 12px;
  padding: 25px 20px;
  border: 0.93px solid #d7d8e2;
}

/**/
.findcontract button {
  color: #0053cd !important;
}

.findcontract {
  background: url(../../../assets/images/find_contract_bg.svg) no-repeat center
    top;
  background-color: #0053cd;
  border-radius: 12px;
  padding: 25px;
  margin-bottom: 20px;
}

.findprofessionals button {
  color: #4fc219 !important;
}

.findprofessionals {
  background: url(../../../assets/images/find_contract_bg.svg) no-repeat center
    bottom;
  background-color: #4fc219;
  border-radius: 12px;
  padding: 20px 25px;
  margin-bottom: 20px;
}

.findjobs button {
  color: #e88715 !important;
}

.findjobs {
  background: url(../../../assets/images/find_contract_bg.svg) no-repeat center
    top;
  background-color: #e88715;
  border-radius: 12px;
  padding: 25px;
  margin-bottom: 20px;
}

/**/
.payrates p {
  font-weight: 500;
}

.payrates h3 {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
}

.payrates {
  border-top: 1px solid #d7d8e2;
  border-bottom: 1px solid #d7d8e2;
}

/**/
.switchToggle input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
  position: absolute;
}

.switchToggle label {
  cursor: pointer;
  text-indent: -9999px;
  width: 41px;
  max-width: 41px;
  height: 20px;
  background: #d1d1d1;
  display: block;
  border-radius: 100px;
  position: relative;
}

.switchToggle label:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

.switchToggle input:checked + label,
.switchToggle input:checked + input + label {
  background: #3e98d3;
}

.switchToggle input + label:before,
.switchToggle input + input + label:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 5px;
  width: 16px;
  height: 16px;
  border-radius: 90px;
  transition: 0.3s;
  text-indent: 0;
  color: #fff;
}

.switchToggle input:checked + label:before,
.switchToggle input:checked + input + label:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 10px;
  width: 16px;
  height: 16px;
  border-radius: 90px;
  transition: 0.3s;
  text-indent: 0;
  color: #fff;
}

.switchToggle input:checked + label:after,
.switchToggle input:checked + input + label:after {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.switchToggle label:active:after {
  width: 41px;
}

.toggle-switchArea {
  margin: 10px 0 10px 0;
}

/**/
.edit_share ul {
  gap: 15px;
  margin-top: 10px;
  margin-right: 10px;
}

.edit_share ul li {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

/**/
.profilepicture {
  margin-top: -70px;
  position: relative;
  z-index: 9;
  margin-bottom: 10px;
}

.profilepicture img {
  max-width: 174px;
  border-radius: 100px !important;
  max-height: 175px;
  min-height: 157px;
  min-width: 157px;
}

/**/
.availabeforwork {
  background-color: #0053cd !important;
  background: url(../../../assets/images/availabeforwork_bg.png) no-repeat top
    right;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.availabeforwork::before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgb(0, 0, 0, 0.2);
  z-index: -1;
}
/**/
.googleadsblock .googleads button {
  border-radius: 50px;
}

.googleadsblock .googleads {
  background-color: #003782;
  border-radius: 12px;
}

/**/
.postad::before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  background: url(../../../assets/images/dotsbg_left.svg) no-repeat top;
  background-size: contain;
  width: 150px;
  height: 150px;
}

.postad::after {
  position: absolute;
  right: 0;
  bottom: 0;
  content: "";
  background: url(../../../assets/images/dotsbg_right.svg) no-repeat top;
  background-size: contain;
  width: 150px;
  height: 100px;
}

.postad {
  background: linear-gradient(100.71deg, #9af1ac -63.63%, #0053cd 55.87%);
  border-radius: 12px;
  position: relative;
}

.postad h2 {
  color: #ffd600;
  font-size: 54px;
  position: relative;
  display: inline-block;
  margin-bottom: 30px;
}

.postad h2::before {
  position: absolute;
  content: "";
  background-color: #ffd600;
  left: 0;
  right: 0;
  bottom: -10px;
  height: 4px;
  width: 100%;
}

/**/
.letsconnect .new_feeds .formblock {
  background-color: #0053cd;
  padding: 45px 30px;
}

.letsconnect .new_feeds ul {
  padding-left: 0;
}

.letsconnect .new_feeds ul li {
  margin-top: 25px;
  font-size: 14px;
}

.letsconnect .new_feeds ul li,
.letsconnect .new_feeds ul li a {
  color: #fff;
}

.letsconnect .new_feeds ul li a {
  text-decoration: none;
}

.letsconnect .new_feeds {
  background-color: #f8f9f9;
  color: #fff;
  padding: 0;
  overflow: hidden;
}

.followUs {
  margin-top: 20px;
}

/**/
.automateamerica .automateamericablock h2 {
  color: #ffd600;
  font-weight: 400;
}

.automateamerica .automateamericablock {
}

.automateamerica .automateamericablock {
  background: url(../../../assets/images/automate_america_bg.jpg) no-repeat
    center;
  border-radius: 12px;
  background-size: cover;
}

/**/
.advertiseyourschool {
  margin-top: 1.5rem;
}

.advertiseyourschool .advertiseblock h4 {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
}

.advertiseyourschool .advertiseblock h2 {
  font-size: 63px;
  color: #fff;
  font-weight: 800;
}

.advertiseyourschool .advertiseblock::before {
  position: absolute;
  content: "";
  background: url(../../../assets/images/advertiseblock_shape.svg) no-repeat
    left;
  background-size: contain;
  top: 0px;
  left: 0;
  width: 300px;
  height: 300px;
  z-index: -1;
}

.advertiseyourschool .advertiseblock {
  overflow: hidden;
  background-color: #0053cd;
  border-radius: 12px;
  position: relative;
  z-index: 1;
}

/**/
.whatsnew .whatsnewblock {
  position: relative;
  background-color: #0053cd;
  border-radius: 12px;
  z-index: 1;
}

.whatsnew .whatsnewblock::before {
  background: url(../../../assets/images/whatsnew_graphic.svg) no-repeat top;
  background-size: 100%;
  height: 70%;
  width: 100%;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  opacity: 0.5;
}
/**/
.FondOpportuniy {
  padding: 50px 0;
}
.SearchData {
  background: #fff;
  border: 1px solid #e4e5e8;
  display: flex;
  border-radius: 6px;
  align-items: center;
  justify-content: space-around;
}

.SearchData .FormGroup:first-child {
  width: 60%;
  border-right: 1px solid #e4e5e8;
}

.SearchData .FormGroup .textwhite input::placeholder {
  color: #fff !important;
  font-weight: 500;
  text-align: center;
}

.SearchData .FormGroup {
  padding: 10px 10px 10px 10px;
  display: flex;
  position: relative;
  align-items: center;
  width: 40%;
}

.SearchData .FormGroup input {
  border: 0;
  outline: none;
  width: 100%;
  
}

.SearchData .FormGroup img {
  margin-right: 6px;
  height: 20px;
  object-fit: contain;
}

.SearchData .FormGroup input[type="submit"] {
  background: #0053cd;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  padding: 16px 38px;
  border-radius: 6px;
}

.SearchData .FormGroup:nth-child(2) {
  width: 52%;
}

/**/
span.boosted {
  border-radius: 100px !important;
  background: #e67136 !important;
  justify-self: unset;
  padding: 2px 7px;
  text-transform: capitalize;
}
.PenigSkilRate ul {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  gap: 40px;
}

.PenigSkilRate.fullcontractSkilRate ul li {
  min-width: 22%;
  width: auto;
}

.PenigSkilRate ul li {
  width: auto;
}

.PenigSkilRate ul li label {
  font-size: 18px;
  color: #0053cd;
  font-weight: 600;
}

.PenigSkilRate ul li span {
  font-size: 14px;
  color: #0e0e0ecc;
  font-weight: 500;
  width: 100%;
  display: table;
}
.PenigSkil h5,
.PenigSkilRate h5 {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}
/**/
@media (max-width: 1440px) {
  .advertiseyourschool .advertiseblock h2 {
    font-size: 42px;
    color: #fff;
    font-weight: 700;
  }
  .findcontract,
  .findjobs {
    padding: 15px;
  }
}
@media (max-width: 991px) {
  .letsconnect .new_feeds .formblock {
    padding: 20px;
  }
  .feeds .dropdown {
    padding: 7px;
    border-radius: 4px;
    border: 1px solid #ddd;
  }
  .advertiseyourschool .advertiseblock {
    text-align: center;
  }
  .advertiseyourschool .advertiseblock h2 {
    font-size: 33px;
    color: #fff;
    font-weight: 700;
    text-align: center;
  }
  .postad h2 {
    font-size: 42px;
  }
  .PenigSkilRate ul {
    gap: 15px;
}
}
@media (max-width: 767px) {
  .new_feeds {
    padding: 10px !important;
  }
  .advertiseyourschool .advertiseblock h2 br {
    display: none;
  }
  .advertiseyourschool .advertiseblock h2 {
    font-size: 28px;
    color: #fff;
    font-weight: 700;
  }
  .postad h2 {
    font-size: 32px;
  }
  .SearchData .FormGroup{
    width: 100% !important;
}
.PenigSkilRate ul {
  gap: 10px;
}
.PenigSkilRate ul li label{
  font-size: 14px;
}
.PenigSkilRate ul li span{
  font-size: 12px;
}
.PenigSkil h5, .PenigSkilRate h5{
  font-size: 12px;
}
}
@media (max-width: 575px) {
  .profilepicture {
    display: block;
    margin-bottom: 20px;
  }
}
