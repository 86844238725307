.viewProiflePage {
    /* background: #F7F7F7;
    padding: 40px 0; */
}

.viewProfileLeft h3 {
    margin-bottom: 16px;
    font-size: 20px;
    color: #141414;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ProTags ul li span {
    background: #0053CD;
    color: #fff;
    padding: 7px 29px;
}

.selfRating ul li span {
    background: #fff;
    width: 15px;
    height: 15px;
    border: 1px solid #0053CD;
    padding: 0;
    margin: 0;
}

.profileHead {
    border: 1px solid #D7D8E2;
    border-radius: 13px;
    background: #fff;
}

.skillset h6 {
    font-size: 17px;
    font-weight: 500;
}

.selfRating ul li.active span {
    background: #0053CD;
}

.profileCover img {
    width: 100%;
}

.selfRating {
    display: flex;
    align-items: center;
    padding: 2px 0 15px;
}

.selfRating span {
    font-size: 14px;
    margin-right: 6px;
    font-weight: 400;
}

.profileCover {
    position: relative;
}

.endroProfile span img {
    max-width: 55px;
    border-radius: 100px;
    margin-right: 12px;
}

.endroProfile div h5 {
    font-size: 18px;
    margin: 0;
    color: #000000;
}

.endroProfile div h6 {
    font-size: 15px;
    color: #000000;
    margin: 4px 0 0;
}

.endroProfile div span {
    font-size: 14px;
    margin: 0;
    color: #000;
    opacity: 0.5;
}

.endroProfile {
    display: flex;
    align-items: center;
    margin: 0 0 20px;
}

.ebdrotabs ul li.active span {
    color: #0053CD;
    font-weight: 500;
    border-bottom: 2px solid;
    border-radius: 0;
}

.ebdrotabs ul {
    margin: 30px 0 10px !important;
}

.ebdrotabs ul li span {
    background: transparent !important;
    padding: 0 !important;
    font-size: 16px;
    color: #8d8d8d;
    margin-right: 35px;
}

.profileCover span img {
    width: auto;
}

.profileCover span {
    position: absolute;
    top: 15px;
    right: 15px;
}

.profileName {
    padding: 0px 27px 25px;
}

.prfoleHead button {

    background: #0053CD;
    border: 0;
    border-radius: 100px;
    margin-left: 7px;
    padding: 6px 19px;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    border: 1px solid #0053CD;

}

.prfoleHead div {
    display: flex;
    align-items: center;
}

.prfoleHead div img {
    margin: 0;
    margin-left: 10px;
}

.prfoleHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.prfoleHead img {
    max-width: 157px;
    border-radius: 100px;
    margin-top: 30px;
    position: relative;
}

.prfoleHead label {
    font-weight: 500;
    color: #000;
}

.profileName h4 {
    font-size: 18px;
    font-weight: 500;
    margin: 20px 0 9px;
}

.connectBtns button {
    background: #0e53cd;
    border: 0;
    border-radius: 5px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 20px;
}

.connectBtns span {
    background: #fff;
    border: 0;
    border-radius: 5px;
    color: #0e53cd;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 20px;
    border: 1px solid #0e53cd;
    margin: 0 15px;
}

.profileName h4 span {
    font-size: 14px;
}

.profileName span img {
    margin-right: 6px;
    position: relative;
    top: -2px;
}

.location span {
    width: 100%;
    display: table;
    margin: 0 0 8px;
}

.location {
    margin: 0 0 15px;
}

.PayRate h5 {
    font-size: 14px;
    font-weight: 600;
}

.PayRate ul {
    padding: 0;
    display: flex;
}

.PayRate ul li {
    width: 24.33%;
}

.PayRate ul li label {
    font-size: 14px;
    font-weight: 500;
    width: 100%;
}

.PayRate ul li span {
    color: #0053CD;
    font-size: 18px;
    font-weight: 500;
}

.BostProfile {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.BostProfile button {
    background: #E88715;
    color: #fff;
    border: 0;
    font-weight: 600;
    padding: 13px 18px;
    border-radius: 7px;
}

.BostProfile ul {
    display: flex;
    align-items: center;
    color: #9499A1;
}

.BostProfile ul li {
    margin-right: 13px;
}

.BostProfile li a {
    border: 1px solid #D4D4D4;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
}

.reviewSection {
    border: 1px solid #D7D8E2;
    border-radius: 13px;
    background: #fff;
    margin-top: 28px;
    padding: 30px 0;
}

.reviewSection h3 {
    padding: 0 27px 17px;
}

.revieStar img {
    max-width: 30px;
    border-radius: 100px;
    margin-right: 11px;
}

.revieStar h4 {
    font-size: 15px;
    font-weight: 400;
    margin: 0;
}

.revieStar div img {
    max-width: 100%;
}

.reviwSection p {
    color: #00000080;
    font-size: 12px;
    margin: 7px 0 0;
}

.textReview {
    padding: 0 27px 26px;
    display: flex;
    align-items: center;
}

.textReview span img {
    max-width: 42px;
    border-radius: 100px;
    margin-right: 13px;
}

.textReview .formGroup {
    width: 100%;
    position: relative;
}

.textReview .formGroup input {
    height: 42px;
    background: #F7F7F7;
    border: 0;
    border-radius: 5px;
    padding: 0 14px;
    width: 100%;
}

.textReview .formGroup label {
    position: absolute;
    right: 13px;
    top: 9px;
}

.revieStar .reply {
    margin-left: auto;
}

.revieStar .reply a {
    font-size: 12px;
    text-decoration: none;
    font-weight: 400;
    display: flex;
}

.revieStar .reply a img {
    margin: 0 2px 0;
}


.reviwSection {
    padding: 0 26px 19px;

    margin-bottom: 25px;
}

.reviwSection:not(:last-of-type) {
    border-bottom: 1px solid #ddd;
}

.reviwSection.noborder {
    border: 0;
    margin-bottom: 10px;
}

.revieStar {
    display: flex;
}

.loadMore {
    border-top: 1px solid #eee
}

.loadMore a {
    padding-top: 12px;
    text-align: center;
    width: 100%;
    display: table;
    color: #0053CD;
    font-weight: 500;
    text-decoration: none;
}

.personalHead {
    border: 1px solid #D7D8E2;
    border-radius: 13px;
    background: #fff;
    margin-top: 21px;
    overflow: hidden;
    padding-bottom: 14px;
}

.editBns span {
    margin-left: 10px;
}

ul.secureFiles {
    padding: 0 20px;
}

ul.secureFiles li .ouetDoc {
    display: flex;
    align-items: center;
    background: #F7F7F7;
    width: 100%;
    padding: 8px 10px;
    border-radius: 5px;
}

ul.secureFiles li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 8px 0;
}

ul.secureFiles li button {
    background: #0053CD33;
    border: 0;
    border-radius: 4px;
    padding: 7px 9px;
    margin-left: 13px;
}

ul.secureFiles li .ouetDoc img {
    margin-right: 7px;
}

ul.bankInformation,
ul.Emergency,
ul.LanguageFlag {
    padding: 14px 20px 5px;
    margin: 0;
}

ul.bankInformation li,
ul.Emergency li,
ul.LanguageFlag li {
    padding: 4px 0;
    font-size: 15px;
}

ul.LanguageFlag li img {
    margin-right: 5px;
}

.personalName {
    display: flex;
    align-items: center;
    background: #f7f7f7;
    border-bottom: 1px solid #00000014;
    padding: 9px 15px;
    justify-content: space-between;
}

.personalName h4 {
    color: #141414;
    font-size: 18px;
    margin: 0;
    font-weight: 500;
}

.personalHead p {
    color: #000000;
    font-size: 16px;
    padding: 20px 20px;
    margin: 0;
}

.personlDetal {
    padding: 0 20px 13px;
}

.personlDetal span {
    width: 100%;
    display: table;
}

.personlDetal label {
    font-size: 19px;
}




.skillset {
    border: 1px solid #D7D8E2;
    border-radius: 13px;
    background: #fff;
    margin-top: 28px;
    padding: 1.5rem;
}

.skilhead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 17px;
}

.skilhead h3 {
    font-size: 20px;
    font-weight: 600;
    color: #141414;
}

.skilladd span {
    margin-left: 11px;
}

.skillset h4 {
    font-size: 16px;
    color: #000000;
    font-weight: 500;
    margin: 30px 0 13px;
}

.skillset p {
    color: #000000;
    font-size: 14px;
    font-weight: 400;
}

.skillset ul {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
}

.skillset ul li {
    margin-right: 13px;
}

.skillset ul li span {
    background: #F5F7FD;
    display: inline-block;
    border-radius: 100px;
    padding: 10px 37px;
    margin-bottom: 13px;
}

.experset {
    display: flex;
}

.experset h4 {
    margin: 0 0 10px;
    font-size: 16px;
    font-weight: 500;
}

.experset h5 {
    font-size: 14px;
    font-weight: 400;
}

.experset p {
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    opacity: 0.5;
}

.experset span {
    margin-right: 19px;
}

.experset {
    margin-bottom: 30px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 30px;
}

.compDetails {
    width: 95%;
}

.compDeteails {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 9px 0 30px;
}

.compDeteails .BostProfile {
    width: 100%;
    justify-content: flex-end;
}

.experset .compDeteails {
    width: 100%;
}

.skillset .experset:last-child {
    border: 0;
    margin: 0;
    padding: 0;
}

.skilhead h4 {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
}

.accountPay ul {
    border: 1px solid #ddd;
    border-radius: 10px;
    margin: 32px 0 0;
    padding: 0;
}

.accountPay ul li {
    padding: 23px;
    width: 25%;
    margin: 0;
    border-right: 1px solid #ddd;
}

.accountPay ul li h4 {
    font-size: 14px;
    margin: 0 0 12px;
    font-weight: 600;
    max-width: 90%;
}

.accountPay ul li p {
    font-size: 14px;
    margin: 0;
    font-weight: 400;
}

.RateSatr h5 {
    font-size: 16px;
    font-weight: 500;
}

.RateSatr h5 img {
    margin-right: 4px;
}

.proslideOut {
    border: 1px solid #ddd;
    border-radius: 10px;
    /* padding: 18px 18px 44px; */
    margin: 0 0;
    position: relative;
}

.proslideOutdetails {
    padding: 18px 18px 44px;
}

.proslideOutHead {
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.leftProHead span {
    font-size: 12px;
    font-weight: 600;
    margin: 0;
}

.proslideOut .leftProHead h4 {
    margin: 6px 0 23px;
}

.proslideOut p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.proslideOut a {
    background: #0053CD;
    width: 100%;
    position: absolute;
    bottom: 0;
    text-align: center;
    color: #fff;
    text-decoration: none;
    font-weight: 400;
    font-size: 15px;
    padding: 8px 0;
    border-radius: 0 0 5px 5px;
    left: 0;
}

.InterviewQues ul li {
    width: 47%;
    font-size: 14px;
}

.InterviewQues ul li span {
    font-size: 14px;
    font-weight: 700;
    padding: 0;
    background: transparent;
    width: 100%;
}

@media (max-width:767px) {
    .viewProiflePage {
        /* padding: 20px 0; */
    }

    .profileHead {
        margin: 6px 0;
    }

    .profileName {
        padding: 17px 15px 17px;
    }

    .prfoleHead img {
        max-width: 80px;
        margin-top: 0px;
    }

    .connectBtns div {
        gap: 10px !important;
    }

    .connectBtns button {
        font-size: 13px;
        padding: 10px 11px;
        min-width: 100px;
    }

    .skillset {
        margin-top: 18px;
        padding: 17px 14px;
    }

    .skillset h4 {
        margin: 10px 0 13px;
    }

    .skillset ul li span {
        padding: 5px 15px;
    }

    .skillset ul li {
        margin-right: 6px;
    }

    .reviewSection {
        margin-top: 16px;
        padding: 21px 0;
    }

    .reviewSection h3 {
        padding: 0 13px 4px;
    }

    .reviwSection {
        padding: 0 13px 19px;
    }

    .skilhead h3 {
        font-size: 18px;
        margin-bottom: 0;
    }

    .InterviewQues ul li {
        width: 100%;
        font-size: 14px;
    }

}