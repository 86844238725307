.aggremtn {
    padding: 60px 0;
}
.agreeHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}
.greBtn {
    text-align: end;
}
.greBtn button {
    margin-left: 17px;
  
}
.agreeHead h4 {
    font-size: 24px;
    color: #141414;
    font-weight: 600;
}

.agrementUper {
    background: #fff;
    border-radius: 13px;
    padding: 60px;
}
.AgreBrdHead {
    border: 1px solid #000;
    text-align: center;
    padding: 19px 0;
}
.AgreBrdname h5 {
    text-transform: uppercase;
    margin: 0;
}
.AgreBrdname {
    border: 1px solid #000;
    text-align: center;
    padding: 19px 0;
    border-top: 0;
    text-transform: uppercase;
    font-size: 40px;
    border-bottom: 0;
}
.leftAgremnt {
    border: 1px solid #000;
    margin-bottom: 24px;
}
span.maindeHd{
    width: 100%;
    display: table;
    padding: 8px 20px;
    font-size: 17px;
    color: #000;
    font-weight: 600;
    text-decoration: underline;
    text-transform: uppercase;
}
.leftAgremnt ul {
    padding: 0;
    margin: 0;
}
.leftAgremnt ul li {
    border-top: 1px solid #000;
    padding: 0px 20px;
    font-size: 16px;
    color: #000;
    font-weight: 600;
    display: flex;
    /* justify-content: space-between; */
}
.leftAgremnt p{
    border-top: 1px solid #000;
    padding: 8px 20px;
    font-size: 16px;
}
.leftAgremnt ul li span {
    min-width: 50%;
    height: 100%;
    padding: 10px 0;
}
.leftAgremnt ul li label {
    padding: 8px 20px;
    border-left: 1px solid #000;
}
.agreDisc {
    border: 1px solid #000;
    padding: 26px;
}
.agreDisc h5 {
    text-transform: uppercase;
    font-weight: 700;
    text-decoration: underline;
}
.agreDisc p {
    font-weight: 500;
}
.agreDisc h6 {
    margin-top: 40px;
    font-weight: 700;
    font-size: 18px;
}
@media (max-width: 1200px) {
    .agrementUper{
        padding: 40px;
    }
    .leftAgremnt ul li {
        font-size: 14px;
        padding: 0 8px;

    }
    .leftAgremnt ul li label{
        padding: 8px;
        word-break: break-all;
    
    }
    .agreDisc h6 {
        font-size: 16px;
    }
}
@media (max-width: 757px) {
    .agreDisc h6 {
        font-size: 14px;
    }
}