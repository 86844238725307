figure.profileCircle img {
    width: 100%;
    border-radius: 100px;
    max-width: 100px;
    max-height: 100px;
    min-height: 100px;
    min-width: 100px;
    object-fit: cover;
    object-position: top;
}
