.Reviewcontarctdetail   {
    background: linear-gradient(180deg, #0053CD 0%, rgba(20, 136, 255, 0.5) 100%);  
    position: relative;
    min-height: 100vh;
  }
.Reviewcontarct {
    position: relative;
}
.Reviewcontarct::before {
    position: absolute;
    content: "";
    background: url(../../../../assets/images/circle_review.svg) no-repeat right;
    background-size: contain;
    left: 0;
    width: 175px;
    height: 100%;
    z-index: 99;
    top: 40%;
}
.Reviewcontarct::after {
    position: absolute;
    content: "";
    background: url(../../../../assets/images/circlereview_before.svg) no-repeat right;
    background-size: cover;
    right: 0;
    width: 175px;
    height: 100%;
    z-index: 99;
    top: 20%;
}
.Reviewcontarctdetail::after {
    position: absolute;
    content: "";
    background: url(../../../../assets/images/circlereview_before.svg) no-repeat right;
    background-size: contain;
    right: 0;
    width: 175px;
    height: 100%;
    z-index: 99;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
}
.Reviewcontarctdetail::before {
    position: absolute;
    content: "";
    background: url(../../../../assets/images/circle_review.svg) no-repeat right;
    background-size: contain;
    left: 0;
    width: 122px;
    height: 100%;
    z-index: 99;
    top: 40%;
    transform: translateY(-40%);
    z-index: -1;
}
.Reviewcontarct {
    background: transparent;
    padding: 50px 0;
    position: relative;
}

.Reviewcontarct h4 {
    font-size: 30px;
    text-align: center;
    font-weight: 600;
    margin-bottom: 0;
    
}

.Reviewcontarct p {
    font-size: 13px;
    text-align: center;
    color: #000000CC;
    margin: 9px 0 0;
}

/* .reviewContaractOuter {
    background: #fff;
    border-radius: 12px;
    padding: 20px 50px;
    margin-top: 40px;
}
.CatList {
    border-bottom: 1px solid #D9D9D9;
    padding: 20px 0;
}
.CatList span {
    color: #0e0e0e96;
    font-size: 15px;
    margin-bottom: 5px;
    display: table;
}
.CatList h6 {
    font-size: 17px;
    color: #000000;
}

.revcontbox {
    position: relative;
    height: 100%;
}
.revcontbox::before {
    position: absolute;
    right: 80px;
    content: "";
    width: 1px;
    height: 100%;
    background: #D9D9D9;
}
.revcontboxinner {
    margin-bottom: 23px;
}
.revcontboxinner label {
    background: #0053CD;
    color: #fff;
    padding: 9px 18px;
    border-radius: 100px;
    margin-right: 14px;
    margin-bottom: 10px;
}
.revcontboxinner .RadioShow label {
    background: transparent;
    color: #000;
    padding: 0;
    font-size: 15px;
    font-weight: 500;
}
.revcontboxinner .RadioShow {
    display: flex;
}
.regisFooter span {
    font-size: 15px;
    background: #fff;
    border: 1px solid #0053CD33;
    padding: 15px 40px;
    border-radius: 7px;
    color: #000;
    float: left;
    text-decoration: none;
}
.regisFooter button {
    font-size: 15px;
    background: #0053CD;
    border: 1px solid #0053CD33;
    padding: 15px 40px;
    border-radius: 7px;
    color: #fff;
    text-decoration: none;
    margin-left: auto;
    float: right;
}
.regisFooter
{
    margin-top: 30px;
} */


/**/
.Reviewcontarctform .headingstyle {
    /* background: #0053CD !important;
    padding-left: 15px;
    padding-right: 15px; */

}

/* .Reviewcontarctform li {} */
.Reviewcontarctform li {
    border-radius: 0px;
    border-bottom: 0.5px solid rgba(0, 83, 205, 0.2);
    /* overflow: hidden; */
    padding: 20px;
    margin-bottom: 0;
}

.Reviewcontarctform .headingdes {
    /* padding-left: 15px;
    padding-right: 15px; */
}

.Reviewcontarctform h3 {
    font-size: 19px !important;
    font-weight: 600;
    line-height: 54px;
    letter-spacing: 0.2199999988079071px;
    text-align: left;
    color: #0053CD !important;


}

.Reviewcontarctform h5,
.Reviewcontarctform label {
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 28px;
    text-align: left;

}

.Reviewcontarctform p {
    font-size: 14px !important;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    border: 0.5px solid rgba(0, 83, 205, 0.2);
    border-radius: 12px;
    padding: 15px;

}

/**/
.Reviewcontarctform .fromGroup {
    /* max-width: 354px; */
    margin: 0 0 23px;
    position: relative;
}
.Reviewcontarctform .fromGroup select + span{
    display: none;
}
.Reviewcontarctform .fromGroup input[type='text'], 
.Reviewcontarctform .fromGroup input[type='number'], 
.Reviewcontarctform .fromGroup input[type='date'], 
.Reviewcontarctform .fromGroup select, 
.Reviewcontarctform .fromGroup input[type='email'], 
.Reviewcontarctform .fromGroup input[type='datetime-local'] {
    background: #fff;
    border: 1px solid #0053cd33;
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    display: table;
    padding: 0 22px;
    height: 56px;
    font-size: 15px;
    border-radius: 8px;
    outline: none;
    box-shadow: 0 0 22px -10px #ddd inset;
}

.Reviewcontarctform .datepicker{
    margin-left: 15px;
}
.Reviewcontarctform .fromGroup input + p{
    border: 0;
    padding: 0;
}
.Reviewcontarctform .headingdes{
    /* background: #fff; */
}
.Reviewcontarctform .fromGroup label {
    margin-bottom: 11px;
    font-size: 15px;
    font-weight: 500;
}
.regisFooter {
    margin-top: 30px;
    position: relative;
    top: 0px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #0000001f;
    padding-top: 25px;
}
.regisFooter button {
    font-size: 15px;
    background: #0053cd;
    border: 1px solid #0053cd33;
    padding: 15px 40px;
    border-radius: 7px;
    color: #fff;
    text-decoration: none;
    margin-left: auto;
}

/**/

/* 
input[type="radio"] {
	appearance: none;
	-webkit-appearance: none;
	width: 20px;
	height: 20px;
	border: 1px solid #fff;
	border-radius: 50%;
	margin-right: 10px;
	background-color: transparent;
	position: relative;
	top: 6px;
}

input[type="radio"]:checked::before {
	content: "";
	display: block;
	width: 12px;
	height: 12px;
	background-color: #ca8489;
	border-radius: 50%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
 */

