.checkboxwrapper .round {
    position: relative;
    margin-right: 10px;
}

.checkboxwrapper .round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 18px;
    width: 18px;
    display: block;
}

.checkboxwrapper .round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 4px;
    opacity: 0;
    position: absolute;
    top: 4px;
    transform: rotate(-45deg);
    width: 12px;
}

.checkboxwrapper .round input[type="checkbox"] {
    visibility: hidden;
    display: none;
    opacity: 0;
}

.checkboxwrapper .round input[type="checkbox"]:checked+label {
    background-color: #66bb6a;
    border-color: #66bb6a;
}

.checkboxwrapper .round input[type="checkbox"]:checked+label:after {
    opacity: 1;
}

.boosttable {
    margin-top: 0.3rem;
}
.boosttable th{
    padding: 7px;
}
.boosttable td{
    border-bottom: 1px solid #eee;
    padding: 7px;
    font-size: 14px;
}