.chatOuter {
    background: #F6F6F6;
    padding-bottom: 40px;
}
.Toastify__toast-container {
    z-index: 9999  !important;
  }

.chatLeft {
    width: 33%;
    /* padding: 40px 30px; */
}

.chatRight {
    width: 70%;
    border-left: 1px solid #ddd;
    /* padding-left: 30px;
    padding-right: 30px; */
    padding-bottom: 30px;

}

.chatMain {
    display: flex;
    background: #fff;
    border: 1px solid #ddd;
}

.chatLeft h3 {
    font-size: 20px;
    font-weight: 600;
    margin: 0 0 3px;
}

.chatLeft h3 span {
    font-size: 15px;
}

.leftchathead h4 {
    font-size: 12px;
    color: #FF0000;
    font-weight: 600;
}

.leftchathead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    padding-bottom: 11px;
    margin-bottom: 21px;
}

.leftchathead button {
    background: #2880DA;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    border: 0;
    border-radius: 100px;
    padding: 7px 12px;
}

.seahChat input {
    height: 48px;
    background: #F5F7FD;
    border: 1px solid #E4E5E8;
    padding: 0 13px;
    width: 100%;
    font-size: 14px;
    border-radius: 12px;
}

.h360{
    max-height: calc(100vh - 280px);
    overflow-y: auto;
}

ul.memberList {
    padding: 0;
    margin: 15px 0 15px;
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid #ddd;
}

ul.memberList li {
    padding: 0 16px;
    border-bottom: 2px solid #fff;
    cursor: pointer;
}

ul.memberList li.active span {
    color: #2880DA;
    font-weight: 600;
}

ul.memberList li.active {
    border-color: #2880DA;
}

ul.userList {
    padding: 0;
    margin: 0;
}

ul.userList li {
    display: flex;
    padding: 10px 10px;
    border-radius: 10px;
    align-items: center;
}

ul.userList img {
    max-width: 48px;
    border-radius: 10px;
    margin-right: 14px;
}

ul.userList li h5 {
    font-size: 14px;
    margin: 0;
    font-weight: 600;
}

ul.userList li h6 {
    color: #3C3C3E;
    font-size: 12px;
    font-weight: 600;
    margin: 2px 0 0;
    word-break: break-all;
}

ul.userList li.active {
    background: #2880DA;
}

ul.userList li.active h5,
ul.userList li.active h6 {
    color: #fff;
}

.chatLOgin {
    text-align: right;
    margin: 30px 0 20px;
    color: #2880DA;
    font-size: 13px;
    font-weight: 500;
    padding-left: 30px;
    padding-right: 30px;
}

.chatRightHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #dddd;
    padding-bottom: 15px;
    margin-bottom: 15px;
    padding-left: 30px;
    padding-right: 30px;
}

.leftHead {
    display: flex;
    align-items: center;
}

.leftHead img {
    margin-right: 13px;
}

.leftHead h5 {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
}

.leftHead span {
    color: #000000;
    font-size: 12px;
    font-weight: 600;
}

.usrChat {
    display: flex;
    margin-bottom: 10px;
}

.usrChat div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    margin-bottom: 7px;
}

.usrChat span {
    margin-right: 12px;
}

.usrChat label {
    background: #F5F7FD;
    padding: 12px 16px 12px 16px;
    border-radius: 12px;
    clear: both;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 7px;
    display: inline-block;
    word-break: break-word;
}

.usrChat.clientchta {
    justify-content: flex-end;
}

.usrChat.clientchta span {
    margin: 0 0 0 12px;
  
}

.usrChat.clientchta > div {
    width: 90%;
}
.usrChat.clientchta label {
    background: #2880DA;
    color: #fff;
}

.usrChat.clientchta div {
    align-items: flex-end;
}

.sentCaht {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    position: relative;

}

.sentCaht span {
    font-size: 20px;
    font-weight: 600;
    color: #000000;
    opacity: 0.4;
    margin-right: 20px;
}

.sentCaht .formGroup {
    width: 100%;
    position: relative;
}

.sentCaht .formGroup input {
    height: 48px;
    border: 1px solid #E2E8F0;
    border-radius: 12px;
    padding: 0 14px;
    font-size: 14px;
    width: 100%;
}

.sentCaht label {
    position: absolute;
    right: 17px;
    top: 11px;
    font-size: 20px;
    font-weight: 600;
    color: #615EF0;
}

.pageChat {
    height: calc(100vh - 400px);
    overflow-y: auto;
    padding-left: 30px;
    padding-right: 30px;
}

.main_faq_page .container {
    display: flex;
}

.objectFitContain {
    object-fit: contain;
}

/* style.module.css */
.dropdownMenu {
    position: absolute;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    z-index: 1000;
}

.dropdownMenu button {
    display: block;
    width: 100%;
    padding: 8px 16px;
    border: none;
    background: none;
    text-align: left;
    cursor: pointer;
}

.dropdownMenu button:hover {
    background-color: #f1f1f1;
}

.Chatalert {
    padding: 35px 30px 40px;
    text-align: center;
}

.Chatalert span {
    background: #0f53cd2b;
    width: 60px;
    height: 60px;
    display: flex;
    border-radius: 100px;
    align-items: center;
    justify-content: center;
    margin: 0 auto 13px;
    color: #2880DA;
    font-size: 28px;
}

.Chatalert h4 {
    font-size: 27px;
    font-weight: 600;
}

.BloackBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 30px 0;
}

.BloackBtn button {
    background: white;
    border: 1px solid #2880DA;
    color: #2880DA;
    margin: 0 10px;
    width: 50%;
    border-radius: 6px;
    padding: 8px 0;
}

.BloackBtn button:last-child {
    background: #0f53cd;
    color: #fff;
}

span.CloseModal button {
    filter: inherit;
}

span.CloseModal {
    position: absolute;
    right: 9px;
    top: 12px;
    z-index: 9;
}

textarea{
    resize: none;
    border: 0;
    width: 100%;
}

.mic{
    background: rgba(0, 83, 205, 0.2);
    border-radius: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px;

}
.send{
    background: #217ed8;
    border-radius: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px;

}
.micsend{
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
}
.cardfootertextarea{
    padding-right: 100px;
    background-color: #fff;
    width: 100%;
    border-top: 3px solid #e7e7e7;
    border-radius: 0;
}

@media (max-width: 991px) {
    
.chatLeft, .chatRight {
    width: 100%;
    /* padding: 40px 30px; */
}

.chatMain{
    flex-direction: column;
}
}
@media (max-width: 767px) {

}