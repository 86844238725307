.dashboard_sec{
    padding: 100px 0px;
}
.nav_short{
    margin-left: auto;
    float: right;
    gap: 10px;
}
.nav_short li button{
    border: 1px solid #B1CBFF;
    height: 45px;
    border-radius: 6px !important;
    color: #5C5C5C !important;
    font-size: 16px;
    padding: 5px 16px;
 }
 .nav_short li button i{
    color: #0053CD;
 }
.dashboard_sec h1{
    font-size: 27px;
    font-weight: 600;
    color: #000;
    margin-bottom: 0px;
}
.card_headers{
    padding: 15px;
    background-color: #F5F7FD !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.card_contract{
    margin-bottom: 20px;
    border-radius: 16px;
    border: 1px solid #D7D8E2;
    overflow:hidden;
}
.ongoing{
    background-color: #FFC702;
    border-radius: 33px;
    font-size: 16px;
    color: #000;
    font-weight: 500;
    padding: 7px 16px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card_contract h3{
    font-size: 17px;
    color: #000;
    font-weight: 600; 
    line-height: 25px;
    margin-bottom: 0px;
}
.card_contract p{
    font-size: 13px;
    color: #000;
    font-weight: 400; 
    line-height: 19px;
    margin-bottom: 0px;

}

.flex_details{
    display: flex;
    align-items: center;
    justify-content: space-between;

}
.flex_details p{
    font-size: 18px;
    font-weight: 500;
    color: #000;
    margin-bottom: 0px;
}
.contract_details{
    padding: 25px 15px;
    background-color: #fff;
    border-bottom: 1px solid #D7D8E2;
}

.flex_details{
    margin-bottom: 20px;
}
.flex_cintent_ic{
    padding-right: 30px;

}

.border_rr{
    border-right: 1px solid #D7D8E2;
}
.flex_cintent{
    padding-left: 10px;
}
.flex_imageProfile{
    height: 91px;
    width: 91px;
    border-radius: 50%;
    overflow: hidden;
    border: 3px solid #0053CD;
}
.flex_imageProfile img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;

}
.flex_cintent_ic h4{
    font-size: 18px;
    font-weight: 600;
    color: #000;
    margin-bottom: 0px;
}
.flex_cintent_ic p{
    font-size: 16px;
    font-weight: 400;
    color: #525252;
    margin-bottom: 0px;
    line-height: 24px;
}
.flex_cintent_ic h6{
    font-size: 16px;
    font-weight: 500;
    color: #0053CD;
    margin-bottom: 0px;
    line-height: 24px;
}
.flex_cintent_ic{
    width: calc(100% - 92px);
    padding-left: 15px;
}
.flex_cintent p{
    color: rgba(0, 0, 0, 0.8);
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
}

.timesheet_card{
    border-radius: 16px;
    margin-bottom: 20px;
    background-color: #F5F7FD;
    padding: 20px;
}
.timesheet_card h3{
    font-size: 23px;
    font-weight: 600;
    line-height: 34px;
    color: #000;
}
.card_body{
    background-color: #fff;
    padding: 25px 15px;
}
.timesheet_card p{
    font-size: 14px;
    font-weight: 500;
    line-height: 30px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.timesheet_card p.clr_red{
color: #FF424D;
}
.timesheet_card p.clr_yellow{
color: #FFC053;
}

.timesheet_card p.clr_green{
color: #2BC155;
}
.rec_card p{
    background-color: rgba(0, 83, 205, 0.09);
    padding: 6px 20px;
   margin-bottom: 15px;
   font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: #000;
    border-radius: 8px;
}    
.viewssa{
    background-color: #0053CD;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 100%;
    height: 48px;
    margin-top: 10px;
}
.viewssa:hover{ background-color: #0053CD;
    color: #fff;
opacity: .9;}









