/* Main container for the text editor */
.text-editor {
    width: 100%;
    height: 300px;
    margin: 20px auto;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.toolbar {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #eaeaea;
    z-index: 1000;
    position: relative;
}

.fullscreen-toolbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #eaeaea;
    padding: 10px;
    z-index: 9999;
    border-bottom: 1px solid #ddd;
}

.toolbar button {
    background: none;
    border: none;
    padding: 10px;
    cursor: pointer;
    font-size: 16px;
    transition: background 0.3s;
    margin-right: 10px;
    outline: none;
    color: #000;
}


.toolbar button.active {
    background-color: #a0baec;

    border-radius: 5px;
}


.toolbar select {
    margin-left: auto;
    padding: 5px;
}

/* Editable area */
.editable {
    flex-grow: 1;
    padding: 10px;
    outline: none;
    /* Remove default outline */
    transition: border 0.3s;
    overflow-y: auto;
    background-color: #fff;
    text-align: left;
    cursor: text;
    /* min-height: 200px; */
}

/* Editable area focus */
.editable p,
.editable span,
.editable label {
    color: #000 !important;
}
.editable:focus {
    border: 1px solid #007bff;
    /* Blue border on focus */
}

/* Fullscreen styles */
.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
    width: 100%;
}

.fullscreen .editable {
    padding: 20px;
    overflow-y: auto;
    flex-grow: 1;
    background-color: #fff;
    border: none;
    width: 100vw;
    height: 300px;
    padding: 50vh;
    margin-top: 50px;
}

.isSpeaking {
    padding-top: 10px;
    padding-left: 20px;

}

.fullScreenIsSpeaking {
    padding-top: 70px;
    margin-bottom: -40px;
    padding-left: 20px;
}

.isSpeaking .fullscreen {
    /* position: absolute; */
    z-index: 9999;
    /* padding-top: 20vh; */
    /* margin-top: 50px; */
    /* padding: 50vh;
    margin-top: 50px; */
}

/* Adjust the cursor only when hovering over the scrollbar */
.editable::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
}

/* When the user hovers over the scrollbar thumb, change the cursor */
.editable::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3); /* Color of the scrollbar thumb */
    border-radius: 4px;
}

.editable::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.5); /* Change color when hovering */
}

.editable::-webkit-scrollbar-track {
    background: #f1f1f1; /* Track background */
    border-radius: 10px;
}

/* Only change the cursor when hovering over the scrollbar */
.editable::-webkit-scrollbar-thumb:hover {
    cursor: pointer; /* Cursor changes to a pointer when hovering over the scrollbar thumb */
}

/* Optional: Change cursor when actively dragging the scrollbar */
.editable:active {
    cursor: grabbing; /* Cursor changes to grabbing when actively dragging the content */
}