/* Floating Admin Chat Logo positioned at the bottom-right corner */
@media(min-width: 1920px){
  .floatingIcon {
    bottom: 70px;
    right: 70px;
  }
}
.floatingIcon {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 9999;
  cursor: pointer;
  padding: 10px;
  -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
  animation: heartbeat 1.5s ease-in-out infinite both;
  border-radius: 50px;
  font-size: 1.1em;
  padding: 20px 0;
  color: #fff;
  border-radius: 50px;
  margin: auto;

}

.floatingIcon:hover span {
  display: block;
  transition: all ease-in-out 0.5s;
}

.floatingIcon span {
  display: none;
  transition: all ease-in-out 0.5s;
  color: white;
  font-size: 0;
}

.floatingIcon:hover span {
  font-size: 1.0em;
}

.floatingIcon:hover i {
  margin-left: 0px;
  transform: rotate(360deg);
}

.floatingIcon:hover .span {
  visibility: visible;
}

@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}