.UserProfileOuter {
    border: 1px solid #D7D8E2;
    padding: 20px;
    background: #fff;
}

.UerImage h6 {
    font-size: 18px;
    font-weight: 500;
    margin: 11px 0 10px;
}

.UerImage h6 span {
    font-size: 14px;
    font-weight: 400;
    padding-left: 8px;
}

.UerImage h6 i {
    color: #ff8200;
    font-size: 13px;
}

.UerImage h5 {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 9px;
}

.UerImage h4 img {
    height: 20px;
}

.UerImage h4 {
    font-size: 14px;
    color: #0E0E0E;
    font-weight: 400;
}

.UerImage figure img {
    margin-right: 5px;
    max-width: 100px;
    height: 100px;
    border-radius: 100px;
}

.UserProfileOuter p {
    font-size: 15px;
    font-weight: 400;
    color: #000;
    margin: 12px 0 12px;
    /* max-width: 490px; */
}

.payRates span {
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 8px;
    display: table;
}

.payRates ul {
    padding: 0;
    margin: 0 0 35px;
    display: flex;
}

.payRates ul li {
    width: 160px;
}

.payRates ul li h6 {
    font-weight: 500;
    font-size: 14px;
    margin: 0 0 3px;
}

.payRates ul li label {
    color: #0053CD;
    font-size: 18px;
    font-weight: 500;
}

.SipBtns button {
    font-size: 16px;
    font-weight: 400;
    background: #0053CD;
    color: #fff;
    padding: 12px 34px;
    border-radius: 8px;
    border: 0;
    margin-right: 11px;
    border: 1px solid #0053CD;
}

.SipBtns button.availble {
    background: #fff;
    color: #0053CD;
}

.SipBtns span {
    background: #F5F7FD;
    padding: 13px 17px;
    border-radius: 8px;
    color: #0053CD;
    font-size: 18px;
}

.aboutProfile,
.ProfileReview {
    border: 1px solid #D7D8E2;
    padding: 20px;
    background: #fff;
    border-radius: 12px;
    margin: 26px 0;
}

.aboutProfile h4,
.ProfileReview h4 {
    font-size: 20px;
    font-weight: 500;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.aboutProfile h4 i {
    color: #0053CD;
}

.aboutProfile p {
    font-size: 14px;
    line-height: 20px;
    color: #00000080;
    font-weight: 400;
    margin: 0;
}

.ProfileReview ul {
    padding: 0;
    margin: 0;
}

.reviesTop {
    display: flex;
}

.reviesTop span {
    margin-right: 10px;
    width: 30px;
    height: 30px;
}

.reviesTop div {
    font-size: 15px;
    font-weight: 500;
}

.reviesTop div img {
    clear: both;
    display: table;
    max-width: 105px;
}

.ProfileReview ul li p {
    font-size: 13px;
    font-weight: 400;
    color: #00000080;
    margin: 13px 0 0;
}

.ProfileReview ul.review_media > li {
    border-bottom: 1px solid #D7D8E2;
    padding-bottom: 22px;
    margin-bottom: 22px;
}

.SeeAll a {
    color: #0053CD;
    text-decoration: none;
    font-size: 20px;
    font-weight: 400;
}

.SeeAll {
    text-align: center;
}

.aboutProfile ul {
    padding: 0;
    margin: 18px 0 27px;
    display: flex;
    flex-wrap: wrap;
}

.aboutProfile ul li {
    margin-right: 40px;
}

.aboutProfile ul li a {
    color: #00000080;
    text-decoration: none;
    font-weight: 500;
}

.style_aboutProfile__ltgCn ul li.active a {
    color: #0053CD;
    border-bottom: 1px solid;
    padding-bottom: 6px;
}

.Endrosement {
    display: flex;
    align-items: center;
    margin: 30px 0 30px;
}

.Endrosement span img {
    margin-right: 13px;
    max-width: 100px;
    border-radius: 100px;
}

.Endrosement h6 {
    font-size: 18px;
    font-weight: 500;
    margin: 0;
}

.Endrosement h5 {
    font-size: 16px;
    font-weight: 400;
    margin: 3px 0;
}

.Endrosement span {
    font-size: 14px;
    font-weight: 400;
    color: #000000b0;
}

.ProfileReview ul li:last-child {
    border: 0;
    padding: 0;
    margin: 0;
}

.SeeAll {
    text-align: center;
    border-top: 1px solid #ddd;
    padding: 15px 0 0;
    margin-top: 26px;
}

ul.skillsTab {
    padding: 0;
    margin: 20px 0 0;
}

ul.skillsTab li {
    margin-right: 10px;
}

ul.skillsTab li span {
    background: #F5F7FD;
    font-size: 14px;
    padding: 12px 30px;
    border-radius: 100px;
    color: #0E0E0E;
    display: flex;
}

ul.OccupationTab {
    flex-wrap: wrap;
}

ul.OccupationTab li > div{
    display: flex;
    max-width: 70%;
    /* border-bottom: 1px solid #ddd; */
    padding-bottom: 20px;
    margin-bottom: 20px;
    align-items: center;
}

ul.OccupationTab li:last-child {
    border-bottom: 0px solid #ddd;
    padding-bottom: 0px;
    margin-bottom: 0px;
}

ul.OccupationTab li span img {
    margin-right: 15px;
}

ul.OccupationTab li h6 {
    font-size: 14px;
    font-weight: 500;
    color: #181818;
    margin: 0;
}

ul.OccupationTab li h5 {
    font-size: 12px;
    font-weight: 400;
    margin: 5px 0;
}

ul.OccupationTab li h4 {
    font-size: 12px;
    font-weight: 400;
    display: inherit;
}

ul.OccupationTab li h4 span {
    color: #0077B5;
}

ul.OccupationTab li p {
    font-size: 12px;
    line-height: 1.3;
}

.InterviewQueston li {
    align-items: center;
}

.InterviewQueston li span img {
    max-width: 50px;
    border-radius: 100px;
}

.ApplicatioInner {
    border: 1px solid #D7D8E2;
    border-radius: 10px;
    padding: 20px;
    margin-top: 20px;
}

.ApplicatioInner h4 {
    display: inherit;
    font-size: 18px;
    font-weight: 600;
}

.ApplicatioInner h4 span.Open {
    color: #1A7B1A;
    font-size: 18px;
    font-weight: 600;
}

.ApplicatioInner span {
    font-size: 14px;
    font-weight: 400;
}

.ApplicatioInner label {
    display: inherit;
    background: #E671360D;
    max-width: 140px;
    text-align: center;
    padding: 7px 0;
    border-radius: 9px;
    margin-top: 10px;
    color: #E67136;
    font-size: 14px;
    font-weight: 400;
}

.ApplicatioInner h6 {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    margin: 14px 0 0;
}

.ApplicatioInner h4 span {
    color: red;
}

.ApplicatioInner label.Jonhrs {
    background: #BBFFBB80;
    color: #1A7B1A;
}

.Ediuca ul li {
    width: 100%;
}

@media (max-width: 767px) {

    .UserProfileOuter,
    .aboutProfile,
    .ProfileReview {
        padding: 20px;
    }

    .SipBtns span,
    .SipBtns button {
        padding: 8px 12px;
        font-size: 12px;
    }

}

@media (max-width: 767px) {

    .UserProfileOuter,
    .aboutProfile,
    .ProfileReview {
        padding: 10px;
    }

    .aboutProfile ul {
        margin: 9px 0 9px;
    }

    .aboutProfile h4,
    .ProfileReview h4 {
        font-size: 16px;
    }

    .SeeAll {
        padding: 5px 0 0;
        margin-top: 16px;
    }

    .OccupationTab li span img {
        margin-right: 10px;
    }


}

@media (max-width: 575px) {
    .SeeAll a {
        font-size: 16px;
    }

    ul.OccupationTab li {
        max-width: 100%;
    }

    .aboutProfile .social-media ul li,
    .aboutProfile .OccupationTab.InterviewQueston ul li {
        margin-right: 0;
    }

    .aboutProfile ul li {
        margin-right: 20px;
    }
}