.signuppage,
.signUPContainer {
  height: auto;
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  padding-top: 0px;
  padding-bottom: 10px;
}

.promotePlanCard {
  background: #fff;
  border-radius: 10px;
  padding: 21px;
  position: relative;
  padding-top: 30px;
  margin-bottom: 17px;
}

.promotePlanCard button i {
  font-size: 10px;
  margin-left: 3px;
}

.promotePlanCard.active button {
  background: #0053CD;
}

.check_round {
  position: absolute;
  right: 14px;
  top: 14px;
  color: #0053CD;
  font-size: 18px;

}

.promotePlanCard button {
  background: #BBBBBB;
  color: #fff;
  font-size: 14px;
  margin: 0 auto;
  display: table;
}

.promotePlanCard p {
  font-size: 14px;
  color: #0E0E0E;
  text-align: center;
}

.promotePlanCard h5 {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

.uploadcontent h6 {
  color: #fff;
  font-size: 19px;
  margin: 9px 0;
  font-weight: 600;
}

.uploadbox {
  border: 1px dashed #D5D8E2;
  border-radius: 10px;
  text-align: center;
  min-height: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

}

.uploadnav {
  display: flex;
  column-gap: 13px;
  margin: 23px 0 23px;

}

.uploadnav li img {
  height: 100px;
  border-radius: 10px;
}

.uploadnav li i {
  position: absolute;
  right: -12px;
  top: -12px;
  color: #0b5cd1;
  background: white;
  padding: 5px 7px;
  border-radius: 50%;
}

.uploadnav li {
  width: 20%;
  position: relative;
}

.uploadcontent p {
  color: #fff;
}

.uploadcontent img {
  position: relative;
}

.uploadbox input {
  font-size: 0;
  padding: 42px 10px 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.signuppage::before {
  /* background: url(../../../assets/images/reg_bg.jpg) repeat 50%;
  background-size: contain;
  -webkit-backface-visibility: .7;
  backface-visibility: .7;
  bottom: 0;
  content: "";
  left: 0;
  opacity: .07;
  position: relative;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1; */
}

.signupHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 40px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.rightSign {
  display: flex;
  align-items: center;
}

.rightSign p {
  font-size: 14px;
  color: #000000;
  font-weight: 400;
  margin-bottom: 40px;
}

.rightSign p a {
  color: #0053cd;
  font-weight: 600;
  text-decoration: none;
}

.rightSign button {
  border: 1px solid #0053cd;
  border-radius: 100px;
  background: #fff;
  color: #0053cd;
  font-size: 14px;
  font-weight: 600;
  padding: 11px 17px;
  margin-left: 16px;
}

span.Leftntm {
  position: absolute;
  top: 34px;
  left: 40px;
  z-index: 9;
}

.signUPContainer .fromGroup input[type='text'],
.signUPContainer .fromGroup input[type='number'],
.signUPContainer .fromGroup input[type='date'],
.signUPContainer .fromGroup input[type='email'],
.signUPContainer .fromGroup input[type='datetime-local'] {
  background: #fff;
  border: 1px solid #0053cd33;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  display: table;
  padding: 0 22px;
  height: 48px;
  font-size: 15px;
  border-radius: 10px;
  outline: none;
  border-radius: 8px !important;
}


.signUPContainer .fromGroup textarea {
  background: #fff;
  border: 1px solid #0053cd33;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  /* display: table; */
  padding: 13px 22px;
  height: 200px;
  font-size: 15px;
  border-radius: 8px;
  outline: none;
  box-shadow: 0 0 22px -10px #ddd inset;
}

.signUPContainer .fromGroup select {
  background: #fff;
  border: 1px solid #0053cd33;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  display: table;
  padding: 0 22px;
  height: 48px;
  font-size: 15px;
  border-radius: 30px;
  color: #727272;
  outline: none;
  appearance: none;
  box-shadow: 0 0 22px -10px #ddd inset;
}


.signUPContainer .fromGroup img {
  /* background: #fff;
  border: 1px solid #0053cd33; */
  border-radius: 90px;
  height: 32px;
  width: 32px;
  margin-bottom: 10px;
}

.signUPContainer .fromGroup {
  /* max-width: 354px; */
  max-width: 100%;
  margin: 0 0 23px;
  position: relative;
}

.signUPContainer .fromGroup span {
  position: absolute;
  right: 14px;
  bottom: 14px;
}

.signUPContainer .fromGroup input[type='submit'] {
  max-width: 180px;
  background: #0053cd;
  color: #fff;
  font-weight: 500;
}

.signUPContainer .fromGroup span.editNUmber {
  position: static;
  margin: 8px auto 23px;
  display: table;
  border: 1px solid #0053cd;
  border-radius: 100px;
  font-size: 15px;
  font-weight: 600;
  padding: 8px 30px;
}

.signUPContainer .fromGroup label {
  margin-bottom: 11px;
  /* margin-bottom: 11px;
  font-size: 15px;
  font-weight: 500;
  display: block;
  color: #fff; */
}

.signUPContainer .fromGroup button {
  font-size: 15px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #000000;
}

.signUPContainer .fromGroup .otpFields input[type='text'] {
  width: 53px !important;
  height: 53px !important;
  padding: 0;
  margin: 0 10px 0 0;
}

.signUPContainer .fromGroup h6 {
  display: flex;
  align-items: center;
  margin: 16px 0 0;
  font-size: 14px;
  justify-content: center;
}

.signUPContainer .fromGroup h6 a {
  color: #0053cd;
  font-weight: 600;
  text-decoration: none;
  padding: 0 4px;
}

.signUPContainer .fromGroup span.editNUmber img {
  margin-left: 10px;
}

.register h5 {
  font-size: 22px;
  text-align: center;
  color: #0053cd;
  font-weight: 600;
}

.register p {
  text-align: center;
  color: #0e0e0e;
  opacity: 0.5;
  font-size: 14px;
  font-weight: 400;
}

label.Addinfo {
  border: 1px solid #0053cd;
  font-size: 14px;
  padding: 8px 20px;
  border-radius: 100px;
  font-weight: 500;
  margin: 0 auto 20px;
  display: table;
}

a.goDashbord {
  font-size: 15px;
  background: #0053cd;
  color: #fff;
  text-decoration: none;
  padding: 13px 29px;
  border-radius: 7px;
  margin: 0 auto;
  display: table;
  font-weight: 500;
}

.outerRegister {
  outline: 2px solid #0053CD33;
  background: #fff;
  padding: 40px 50px;
  max-width: 890px;
  margin: 32px auto 0;
  border-radius: 15px;
  background: #0053CD;
}

.outerRegister h6 {
  font-size: 16px;
  text-align: center;
  color: #0053cd;
  font-weight: 500;
  margin-bottom: 43px;
  text-transform: uppercase;
}

.innerRegiseter h4 {
  font-size: 16px;
  text-align: center;
  color: #0e0e0e;
}

.innerRegiseter p {
  font-size: 14px;
  color: #0e0e0e;
  padding: 0 30px;
  margin: 15px 0 20px;
}

.innerRegiseter a {
  font-size: 15px;
  background: #0053cd;
  color: #fff;
  text-decoration: none;
  padding: 13px 29px;
  border-radius: 7px;
  margin: 0 auto;
  display: table;
  font-weight: 500;
  width: 100%;
  text-align: center;
}

.registerLeft h4 {
  font-size: 32px;
  /* color: #0053cd; */
  /* max-width: 60%; */
  line-height: 50px;
  color: #fff;
}

.registerLeft p {
  font-size: 15px;
  color: #0e0e0e;
  opacity: 0.5;
  max-width: 500px;
  width: 100%;
  color: #333;
}

/*ul.radioBTn li label {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  padding-left: 7px;
}

ul.radioBTn li {
  list-style: none;
 margin-bottom: 34px;
  position: relative;
  width: 45%;
}

ul.radioBTn li label .OuterToptip label img {
  max-width: 30px;
}

ul.radioBTn li label .OuterToptip {
  top: 0px;
  right: -35px;
  left: inherit;
}

ul.radioBTn li:nth-child(even) .topTipHover .cuatomeTop .OuterToptip {
  left: -220px;
}

ul.radioBTn li label .Toltip {

  max-width: 375px;
  width: 100%;
  right: 0;
  right: 0;
  display: inline-block;
  width: 100%;
  min-width: 375px;
  bottom: initial;
  top: 29px;
  z-index: 9;
}

 ul.radioBTn {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;

}

ul.radioBTn h6 {
  font-size: 14px;
  color: #fff;
  position: relative;
  font-weight: 400;
} */

.Bar span {
  width: 100%;
  height: 5px;
  background: #d9d9d9;
  display: table;
}

.registerLeft {
  position: relative;
}

.registerLeft::before {
  height: calc(100% - 50px);
  width: 23px;
  position: absolute;
  right: 0px;
  top: 0;
  bottom: 0;
  margin: auto;
  background-repeat: no-repeat;
  content: '';
  background-size: 100% 100%;
  background-image: url('../../../assets/images/line.png');
}

.ck.ck-content {
  min-height: 180px;
}

.regisFooter span {
  font-size: 15px;
  background: #fff;
  border: 1px solid #0053cd33;
  padding: 14px 40px;
  border-radius: 7px;
  color: #000;
  float: left;
  text-decoration: none;
  width: 140px;
  height: 100%;
}

.regisFooter button {
  font-size: 15px;
  background: #0053cd;
  border: 1px solid #0053cd33;
  padding: 14px 40px;
  border-radius: 7px;
  color: #fff;
  text-decoration: none;
  margin-left: auto;
  width: 160px;
  height: 100%;
  height: 50px;
}

.regisFooter>div {
  padding: 0 !important;
  height: 50px;
}

.DraftBtns button {
  min-width: 260px;
}

.DraftBtns {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
}

.regisFooter {
  margin-top: 30px;
  position: relative;
  top: 0px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #0000001f;
  padding-top: 25px;
}

.draftBTn button {
  background: #fff;
  border: 1px solid #0053cd;
  color: #0053cd;
  font-weight: 500;
  margin-right: 13px;
}

.draftBlue.draftBTn button {
  background: #0053cd !important;
  border: 1px solid #0053cd !important;
}

ul.selectCat li {
  list-style: none;
}

ul.selectCat li span {
  position: static !important;
  border: 1px solid #31313133;
  font-size: 13px;
  color: #8c8c8c;
  padding: 10px 13px;
  border-radius: 100px;
  margin-right: 6px;
  display: flex;
  margin-top: 11px;
}

ul.selectCat {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
}

ul.selectCat li.active span {
  background: #0053cd;
  color: #fff;
  font-weight: 600;
}

.radStepOne {
  display: flex;
  align-items: center;
}

.radStepOne li {
  margin-right: 23px;
  margin-bottom: 4px !important;
}

.radStepOne li label {
  color: #000 !important;
}

.error {
  color: rgb(255, 0, 0);
  font-size: 15px;
  margin-top: 5px;
}

.editors .fromGroup {
  max-width: 100%;
}

.editors .fromGroup span {
  position: static !important;
  color: #000 !important;
}

.EditBtn {
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
}

.EditBtn h4 {
  color: #0e0e0e;
  font-size: 17px;
  margin: 0;
  font-weight: 600;
}

.EditBtn button {
  color: #0053cd;
  font-size: 16px;
  border: 0;
  background: transparent;
  font-weight: 500;
}

.EditBtn button i {
  margin-right: 2px;
}

.addOccuatin {
  display: flex;
  align-items: center;
}

.signUPContainer .addOccuatin button {
  display: table;
  padding: 0 22px;
  height: 48px;
  font-size: 15px;
  border-radius: 8px;
  outline: none;
  box-shadow: 0 0 22px -10px #ddd inset;
  background: #fff;
  border: 1px solid #0053cd33;
  color: #545454;
}

.addOccuatin button {
  background: #0e53cd;
  color: #fff;
  border: 0;
  padding: 12px 14px;
  border-radius: 5px;
  margin-left: 10px;
}

.DraftBtns .draftBTn:first-child button {
  background: #fc0d1b;
  border-color: #fc0d1b;
  color: #fff;
}

.DraftBtns .draftBTn:nth-child(2) button {
  background: #c2c2c2;
  border-color: #c2c2c2;
  color: #fff;
}

.AddBtn button:nth-last-child(1){
  margin-left: 2px;
} 
.AddBtn button {
  width: 38px;
  height: 38px;
  border-radius: 100px;
  background: #0e53cd;
  padding: 0;
  color: #fff;
  border: 0;
  font-size: 32px;
  font-weight: 600;
  min-width: 38px;
  margin-left: 0px;
  line-height: 26px;
}

.AddBtns label {
  display: block;
}

.AddBtn {
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* flex-direction: column; */
}

/* .rateDropdown {
  display: flex;
  padding-bottom: 15px;
  max-width: 100%;
} */
.dropdown {
  border: 1px solid #0053cd33;
  border-radius: 20px;
  padding: 0 22px;
  font-size: 15px;
  background: #fff;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  display: table;
  padding: 10px 22px;
  font-size: 15px;
  outline: none;
  box-shadow: 0 0 22px -10px #ddd inset;
}

.dropdown li {
  padding: 10px;
}

.dropdown li:hover {
  border: 1px solid #0053cd33;
  border-radius: 10px;
  background-color: #fff;
}

/* .dropdown {
  background-color: red !important;
  width: 400px !important;
} */

.fileUpload {
  width: 110px;
  height: 110px;
  background: #ddd;
  display: flex;
  border-radius: 100px;
  margin-bottom: 12px;
  position: relative;
}

.fileUpload input {
  position: absolute;
  font-size: 0;
  border: 0;
  padding: 20px;
  width: 100%;
  height: 100%;
}

.fileUpload span {
  position: absolute;
  background: #e7e7e7;
  width: 100%;
  height: 100%;
  border-radius: 100px;
  left: 0;
  border: 1px dashed #bdbdbd;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  color: #545454;
  font-size: 13px;
  pointer-events: none;
  padding: 0 18px;
  text-align: center;
  line-height: 1.1;
}

.rateradio > div {
  margin-bottom: 10px;
}

.rateradio label {
  font-weight: 400;
  font-style: italic;
  font-size: 15px;
  color: #333;
  margin-left: 10px;
}

.ratedRadio h6 {
  font-size: 15px;
  margin-bottom: 14px;
}

.documentHeading {
  font-size: 25px;
  color: #0053cd;
}

.text {
  font-size: 11px;
}

.dropdownMenu {
  max-height: 300px;
  /* Adjust as needed */
  overflow-y: auto;
}

.fixedHeader {
  background-color: white;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  position: sticky;
  top: 0;
  z-index: 1;
}

.fileLabel {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.fileInput {
  display: block;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.asterisk {
  color: 'red';
  margin-Left: '2px';
}

.starlabel {
  color: #fff;
  font-weight: 500;
  font-size: 16px;
}

.starlabel:after {
  content: " *";
  color: red;
}

.inputWithDropdown {
  margin-bottom: 20px;
}

button.addDetalBtn {
  color: #fff;
  min-width: 230px;
}

.Subacription button {
  background: #0053CD;
  color: #fff;
  margin: 17px auto 0 !important;
  display: table;
  float: none;
  padding: 9px 59px;
  border: 0;
  border-radius: 8px;
}

ul.radioBTn {
  /* align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 0;
  row-gap: 25px; */

}

ul.radioBTn li label {
  color: #fff !important;
}

ul.radioBTn li.active > div p,
ul.radioBTn li.active > div h4{
  color: #ffffff;
}
ul.radioBTn li.active > div{
  background: #51ABFF;
}
ul.radioBTn li > div p,
ul.radioBTn li > div h4{
  color: #858585;
}
ul.radioBTn li.active label input[type=radio]{
  border: 2px solid #fff !important;
}
ul.radioBTn li > div{
  align-items: flex-start;
  background: #ffffff;
  border-radius: 7px;
  display: flex;
  justify-content: space-around;
  list-style: none;
  position: relative;
  width: 100%;
}

/**/
.registerRight {
  margin-top: 20px;
}

.registerRight .fromGroup select+span {
  position: absolute;
  top: 50%;
  margin-top: 20px;
  transform: translateY(-50%);
  right: 15px;
}

.registerRight .fromGroup {
  margin-bottom: 30px;
  position: relative;
}

.registerRight input {
  border: 0.5px solid #FFFFFF;
}

.registerRight label {
  /* font-family: "Poppins", sans-serif !important;
  ;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  margin-bottom: 10px;
  color: #fff; */

}

.asterisk {
  color: 'red';
  margin-Left: '2px';
}

.starlabel:after {
  content: " *";
  color: red;
}



/* new css*/
.navradio {
  gap: 25px;
  margin: 20px 0px;
}

.navradio li label {
  font-size: 14px;
  font-weight: 500;
}

.textareaSize {
  height: 324px;
  resize: none;
}

.uploadbox {
  border: 1px dashed #D5D8E2;
  height: 176px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  margin-bottom: 15px;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
}

.uploadcontent p {
  color: #d4d5d9;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0px;
}

.uploadcontent h6 {
  margin: 10px 0px;
}

.filePos {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.uploadImages {
  height: 118px;
  width: 118px;
  position: relative;
  border-radius: 14px;
}

.uploadImages img {
  height: 100%;
  width: 100%;
  border-radius: 14px;
}

.uploadnav {
  gap: 10px;
}

.uploadImages i {
  position: absolute;
  right: 9px;
  top: 9px;
  color: #fff;
  font-size: 14px;
}


/* add-promotion css */
.promotePlanCard {
  background-color: #F3F8FF;
  border-radius: 10px;
  margin-bottom: 20px;
  position: relative;
  padding: 20px 15px;
  text-align: center;
  width: 100%;
}

.promotePlanCard h6 {
  color: #0E0E0E;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.promotePlanCard p {
  color: #0E0E0E;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin: 10px 0px;
}

.btnClick {
  height: 48px;
  background-color: #BBBBBB !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff !important;
  font-size: 14px !important;
  width: 160px;
  padding: 5px 15px;
  margin: auto;
}

.btnClick i {
  font-size: 10px;
}

.btnClick:hover {
  background-color: #0053CD !important;
}

.promotePlanCard.active .btnClick {
  background-color: #0053CD !important;
}

.promotePlanCard .check_round {
  display: none;
  position: absolute;
  top: 11px;
  right: 12px;
  color: #0053CD;
}

.promotePlanCard.active .check_round {
  display: block !important;
}

/* Boost Add  css*/
.img_boost {
  /* height: 100%; */
  /* width: 100%; */
  border-radius: 14px;
  margin-bottom: 0;
  /* background: #f3f8ff; */
  text-align: center;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */

}

.img_boost img {
  border-radius: 14px;
  background: #f3f8ff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 310px;
    width: 100%;
    object-fit: cover;
    object-position: top;
}

.text_boost h6 {
  color: rgba(0, 0, 0, 0.7);
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 10px;
}

.text_boost p {
  color: rgba(0, 0, 0, 0.7);
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;

}

.text_boost {
  margin-bottom: 20px;

}

.text_week {
  border-radius: 14px;
  background-color: #F3F8FF;
  padding: 25px;
}

.flex_week {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text_inner h5 {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 0px;
}

.text_inner p {
  color: #0E0E0E;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.text_inner {
  margin-bottom: 20px;
}

.text_reds {
  position: relative;
  /* padding-left: 40px; */
  color: #FF0000;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  display: flex;
  align-items: center;
}
.relativePos{
  position: relative;
   background-color: green;
  
 
 }
.text_reds img{
  width: 50px;
  height: 50px;
  padding-right: 5px;
 /* position: absolute;
 left: 0px;
 top: 0px; */
}

.right_border {
  padding-right: 100px;
  border-right: 1px solid #D9D9D999;
  min-height: 300px;
}

.right_pad {
  padding-left: 70px;
}

.payment_outer {
  background-color: #F3F8FF;
  border-radius: 14px;
  margin-bottom: 25px;
  padding: 30px 25px;
}

.payment_outer h6 {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #000;
}

.payment_outer p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #000;
  margin-bottom: 30px;
}

.payment_subtotal h5 {
  color: #8890A0;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.border_b {
  border-top: 1px dashed #979797;
  padding-top: 9px;
}

.border_b h5 {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
}

.form_left .custom_control {
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
  padding: 6px 39px 6px 14px;
  height: 64px !important;
  border-radius: 8px;
  margin-bottom: 20px;
  position: relative;
  display: flex;
  align-items: center;
}

.form_left select:focus {
  background-color: transparent;
  color: #fff;
  border: 1px solid #fff;
}

.form_left select option {
  color: #000;
}

.value_form p {
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 0px;
}

.value_form h6 {
  color: #fff;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 0px;
}

.custom_control::after {
  content: '';
  position: absolute;
  border: 8px solid #fff;
  right: 17px;
  top: 19px;
  border-top: 8px solid transparent;
  border-right: 8px solid transparent;
  transform: rotate(-45deg);
}


.text_boost .input-group label {
  font-size: 2.8rem;
}

.text_boost input[type=radio] {
  appearance: none;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-clip: content-box;
  border: 2px solid #000 !important;
}

.text_boost input[type="radio"]:checked {
  border: 2px solid #ffffff !important;
  filter: invert(1);
}

.raio_custom label {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 16px;
  color: #fff;
}

.custom_cards {
  background-color: #FAFAFA;
  height: 70px;
  border-radius: 10px;
  padding: 7px 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 66px;
  position: relative;
  margin-bottom: 20px;
}

.custom_cards h6 {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: #181E22;
  margin-bottom: 0px;
}

.custom_cards p {
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  color: #181E22;
  margin-bottom: 0px;

}

.custom_cards img {
  position: absolute;
  left: 15px;
  top: 14px;
}

.raio_custom2 input[type="radio"] {
  background-color: #fff !important;
  padding: 4px;
  border: 2px solid #fff !important;
}

.form_left .custom_control2 {
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
  padding: 6px 39px 6px 14px;
  height: 64px !important;
  border-radius: 8px;
  margin-bottom: 20px;
  position: relative;
  display: flex;
  align-items: center;

}

.custom_control2 label {
  position: absolute;
  right: 15px;
  top: 20px;
}


.custom_control2 p {
  font-size: 14px;
}

.pay_now {
  font-size: 14px;
  background-color: #0053CD;
  height: 48px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: #fff;
  width: 100%;
}

@media (min-width:991px) and (max-width: 2100px){
  .ScrollDiv {
    max-height: calc(100vh - 380px);
    height: auto;
    overflow-y: auto;
    border-radius: 10px;
    padding: 0px 20px 0 0;
  }
}
@media (max-width:991px) {
  .registerLeft::before {
    display: none;
  }
  .ScrollDiv {
    max-height: 100%;
    overflow-y: hidden;
    padding-right: 0;
  }
}

@media (max-width:767px) {
  ul.radioBTn li {
    width: 100%;
  }

  ul.radioBTn {
    row-gap: 12px;
  }

  .registerLeft img {
    display: none;
  }
}












@media (max-width:767px) {
  .registerLeft h4 {
    line-height: 31px;
  }

  .OuterToptip {
    bottom: 58px;
    max-width: 290px;
    left: -10px;
  }

  .signUPContainer .fromGroup p {
    color: #fff;
  }

  .rateradio label {
    color: #fff;
  }

  .registerLeft img {
    display: none;
  }
}
@media (max-width: 575px) {
  .signUPContainer .fromGroup .otpFields input[type="text"] {
      width: 38px !important;
      height: 38px !important;
      margin-right: 1px;
  }
}