.rbc-header {
    background: #79b8e4;
    color: white;
    padding: 10px;
    font-size: 12px;
  }
  
  .rbc-event {
    padding: 0px !important;
    border-radius: 5px !important;
    border: none !important;
  }
  
  .rbc-background-event {
    padding: 0px !important;
    border-radius: 0px !important;
    border: none !important;
    background-color: transparent !important;
  }
  
  .rbc-time-slot {
    background-color: #eaf3fa;
    color: #74a4c3;
    border-color: aqua !important;
  }
  
  .rbc-event-label {
    display: none !important;
  }
  
  .rbc-events-container {
    width: 100% !important;
  }
  
  .rbc-timeslot-group {
    min-height: 120px !important;
  }
.calenderBlock{
  padding: 50px 0;
  /* background-color: #F7F7F7; */
}
  .calendertopBar{
    display: grid;
    grid-template-columns: 1fr 200px;
    margin-bottom: 30px;
    align-items: center;
  }
  .calendertopBar h1{
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 0;
    letter-spacing: 0;
  }
  .calenderBlock .grid{
    display: grid;
    grid-template-columns: 1fr 0.4fr;
    grid-gap: 16px;
  }
  .calenderBlock .grid .left{
    padding: 20px;
    background-color: #fff;
    border-radius: 12px;
  }
  .calenderBlock .grid  .right .informationItem, .calenderBlock .grid  .right  .recentItem {
    padding: 20px;
    background-color: #fff;
    border-radius: 12px;
    margin-bottom: 16px;
  }
  .calenderBlock .grid  .right  .recentItem {
    margin-bottom: 0;
  }
  .calenderBlock .grid  .right .informationItem ul {
    padding-left: 20px!important;
  }
  .calenderBlock .grid  .right .informationItem ul li{
    display: block;
    padding-left: 22px;
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 8px;
    position: relative;
  }
  .calenderBlock .grid  .right .informationItem ul li::before{
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    background-color: red;
    top: 4px;
    border-radius: 50%;
    left: 0;
  }
  .calenderBlock .grid  .right .informationItem ul li:nth-of-type(1)::before{background-color: #2880DA;}
  .calenderBlock .grid  .right .informationItem ul li:nth-of-type(2)::before{background-color: #FF9E81;}
  .calenderBlock .grid  .right .informationItem ul li:nth-of-type(3)::before{background-color: #FFD800;}

  .calenderBlock .grid .right .recentItem .item{
    position: relative;
    margin-left: 15px;
  }
  .calenderBlock .grid .right .recentItem .item::before {
    content: '';
    position: absolute;
    top: 8px;
    left: 16px;
    width: 2px;
    height: 100%;
    bottom: 0;
    border-left: 2px dashed #D9D9D9;
    z-index: 1;
}
.calenderBlock .grid .right .recentItem .item:last-child:before{
  content: unset;
}
  .calenderBlock .grid .right .recentItem .item{
    display: grid;
    grid-template-columns: 32px 1fr;
    grid-gap: 16px;
    padding-bottom: 16px;
  }
  .calenderBlock .grid .right .recentItem .item .image span{
    display: inline-block;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #D9D9D9;
    z-index: 2;
    position: relative;
  }
  .calenderBlock .grid .right .recentItem .item .text h6 {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 2px;
    line-height: 20px;
}
.calenderBlock .grid .right .recentItem .item .text p{
    margin-bottom: 0;
    font-size: 12px;
    color: #707070;
}
.calenderBlock .grid .right .recentItem h5, .calenderBlock .grid .right .informationItem h5{
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 20px;
}
.AddEventsModal{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000038;
  width: 100%;
  height: 100%;
  z-index: 99;
  /* display: flex!important; */
  align-items: center;
  justify-content: center;
}
.AddEventsModal .item{
  width: 500px;
  height: auto;
  background-color: #fff;
  padding: 37px;
  border-radius: 20px;
}
.AddEventsModal .item .form-group{
  margin-bottom: 20px;
}
.AddEventsModal .item .form-group label{
  width: 100%;
  display: block;
  margin-bottom: 5px;
}
.AddEventsModal .item .form-group input{
  width: 100%;
  display: block;
  padding: 10px 26px;
  border: 1px solid #D6D6D6;
  border-radius: 10px;
}
.AddEventsModal .item h4 {
  text-align: center;
  font-size: 30px;
  letter-spacing: 0;
  font-weight: 700;
  margin-bottom: 30px;
}
.AddEventsModal .item button.btn {
  padding: 10px;
  font-size: 20px;
  margin-top: 15px;
}
.rbc-toolbar{
  flex-direction: row-reverse;
}

@media (max-width: 991px) {
  .calenderBlock .grid{
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }
}