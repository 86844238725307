.HeaderMain {
    padding: 6px 50px;
    background: white;
}

.HeaderMain ul {
    margin: 0 auto;
}

.HeaderMain ul li {
    font-size: 13px;
    margin: 0 7px;
}

.HeaderMain ul li a {
    color: #0E0E0E !important;
    font-size: 13px;
}

.HeaderMain ul li.active a {
    color: #0053CD !important;
    font-weight: 600;
}

.Notify ul::before {
    position: absolute;
    content: "";
    background: #ddd;
    left: -6px;
    height: 100%;
    width: 1px;
    top: 0;
}
.Notify ul {
    margin: 0;
    display: flex;
    /* border-left: 1px solid #ddd; */
    padding: 3px 0;
    padding-left: 31px;
    justify-content: space-evenly;
    position: relative;
}

.Notify ul li {
    list-style: none;
    margin: 0 11px;
    position: relative;
}


.Notify ul li span {
    width: 5px;
    height: 5px;
    background: #E67136;
    border-radius: 100px;
    font-size: 0;
    display: flex;
    position: absolute;
    top: 6px;
    right: -1px;
}
.fromGroup input[type='text']{
    background: #fff;
  border: 1px solid #0053cd33;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  display: table;
  padding: 0 22px;
  height: 56px;
  font-size: 15px;
  border-radius: 10px;
  outline: none;
  border-radius: 8px !important;
}
@media (max-width: 1440px) {
    .HeaderMain ul li a {
        font-size: 10px;
    }
    .HeaderMain ul li {
        font-size: 12px;
        margin: 0px;
    }
}
@media (max-width: 1024px) {
    .HeaderMain ul li a {
        font-size: 10px;
    }
}

@media (max-width: 991px) {
    .navbarmenutoglr .navbar-toggler{
        position: absolute;
    right: 0;
    top: 7.5px;
    }
    .Notify{
        /* right: 80px; */
    }
    .HeaderMain ul li a {
        font-size: 12px;
    }
    .Notify ul::before {
        left: auto;
        right: -15px;
    }
    .HeaderMain {
        padding: 6px 10px;
        background: white;
    }
    .Notify ul{
        gap: 15px;
    }
    .Notify{
        position: absolute;
        right: 60px;
        top: 50%;
        transform: translateY(-50%);
    }
    .navbar-toggler{
        position: relative;
        right: 0px;
    }

    #navbarSupportedContent{
        right: 0;
        position: absolute;
        left: 0;
        left: 0;
        top: 66px;
        background: #fff;
        width: 100%;
        z-index: 9;
        padding: 25px;
    }
}

@media (max-width:767px)
{
    .Notify ul {

        padding: 0;
    }
    .Notify ul::before
    {
        right: -10px
    }
    .Notify  {
        /* right: 48px;
        top: 15px; */
    }
}

@media  (max-width:767px) and (orientation: landscape){
    .Notify  {
        /* right: 68px;
        top: 15px; */
    }
}