.leftEligibal .formGroup label {
    font-size: 16px;
    color: #0E0E0E;
    font-weight: 500;
    padding-right: 30px;
}

.leftEligibal .formGroup select {
    height: 53px;
    padding: 0 13px;
    border-radius: 9px;
    border: 1px solid #0053CD33;
    width: 100%;
    font-size: 14px;
    margin: 6px 0 0;
}

.leftEligibal .formGroup {
    margin-bottom: 23px;
}

.EligibalModal {
    padding: 20px;
}

.rightEligibal {
    background: #0053CD0A;
    padding: 19px;
    border-radius: 9px;
    height: 100%;
}

.rightEligibal img {
    margin-bottom: 10px;
}

.rightEligibal p {
    font-size: 13px;
}

.leftEligibal .formGroup textarea {
    height: 116px;
    padding: 10px 13px;
    border-radius: 9px;
    border: 1px solid #0053CD33;
    width: 100%;
    font-size: 14px;
    margin: 6px 0 0;
}

p.unique-text {
    color: red !important;
    /* Force the text to be red */
    font-style: italic;
    font-weight: bold;
    font-size: 1.1em;
    text-align: center;
    margin-top: 10px;
}


.profileModal {
    text-align: center;
}

.profileModal img {
    margin-top: -21px;
    margin-bottom: 10px;
}

.profileModal h4 {
    color: #101828;
    font-size: 22px;
    font-weight: 600;
    max-width: 400px;
    margin: 0 auto 10px;
}

.profileModal p {
    font-size: 15px;
    line-height: 22px;
    padding: 0 30px;
}

.eligibalBtn button {
    margin: 0 6px;
}

.eligibalBtn {
    padding-bottom: 20px;
    padding-top: 11px;
}

.jobDetails {
    padding: 70px 0;
}

.Chatalert {
    padding: 35px 30px 40px;
    text-align: center;
}

.Chatalert span {
    background: #0f53cd2b;
    width: 60px;
    height: 60px;
    display: flex;
    border-radius: 100px;
    align-items: center;
    justify-content: center;
    margin: 0 auto 13px;
    color: #0f53cd;
    font-size: 28px;
}

.Chatalert h4 {
    font-size: 27px;
    font-weight: 600;
}

.BloackBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 30px 0;
}

.BloackBtn button {
    background: white;
    border: 1px solid #0f53cd;
    color: #0f53cd;
    margin: 0 10px;
    width: 50%;
    border-radius: 6px;
    padding: 8px 0;
}

.BloackBtn button:last-child {
    background: #0f53cd;
    color: #fff;
}

span.CloseModal button {
    filter: inherit;
}

span.CloseModal {
    position: absolute;
    right: 9px;
    top: 12px;
    z-index: 9;
}

/* video modal  css */

.videoModalItem {
    padding: 37px !important;
}

.videoModalItem .formGroup {
    margin-bottom: 26px;
}

.videoModalItem .formGroup input,
.videoModalItem .formGroup select {
    display: block;
    padding: 16px 26px;
    font-size: 14px;
    font-weight: 500;
    width: 100%;
    border: 1px solid #D4D4D4;
    border-radius: 10px;
}

.videoModalItem .formGroup textarea {
    width: 100%;
    padding: 16px 26px;
    font-size: 14px;
    font-weight: 400;
    resize: none;
    height: 113px;
    border: 1px solid #D4D4D4;
    border-radius: 10px;
    color: #000;
}

.videoModalItem input[type="submit"] {
    width: 100%;
    text-align: center;
    padding: 14px;
    background-color: #0053CD;
    color: #fff;
    border-radius: 10px;
    border: 1px solid #0053CD;
    font-size: 16px;
}

.videoModalItem .formGroup:nth-of-type(3) {
    display: grid;
    grid-template-columns: 20px 1fr;
    grid-gap: 10px;
    align-items: center;
}

.videoModalItem .formGroup:nth-of-type(3)>div {
    display: grid;
    grid-template-columns: 1fr 90px;
    grid-gap: 10px;
}

.videoModalItem .formGroup:nth-of-type(3)>div button {
    color: #333333;
    font-size: 14px;
    border: 1px solid #ADADAD;
    border-radius: 10px;
    background-color: transparent;
    padding: 5px 10px;
}

.videoModalItem .formGroup:nth-of-type(3)>div input {
    border: unset;
    padding: 0;
}

.videoModalItem .formGroup:nth-of-type(3)>div p {
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 500;
}

.bgcolor {
    background-color: #000;
}

/**/
.ProfileEditHead {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    flex-direction: column;
    margin-bottom: 30px;
    /* padding: 15px 15px 0 15px; */
}

.ProfileEditHead span {
    position: relative;
    margin-right: 13px;
    width: 60px;
    height: 60px;
}

.ProfileEditHead .image {
    width: 100%;
    border-radius: 50%;
}

.ProfileEditHead span img {

    position: relative;
    pointer-events: none;
    z-index: 1;
    width: 61px;
    height: 61px;
    object-fit: cover;
    cursor: pointer;
}

.ProfileEditHead input[type="file"] {
    position: absolute;
    left: 0;
    top: 0;
    font-size: 0;
    cursor: pointer;
    padding: 20px;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.middle {
    transition: .5s ease;
    opacity: 1;
    position: absolute;
    top: 80%;
    left: 90%;
    transform: translate(-80%, -40%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    background: #f2f2f2;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.middle img {
    width: 12px;
    filter: brightness(100) invert(1);
}

.ProfileEditHead:hover .image {
    opacity: 0.3;
}

.ProfileEditHead:hover .middle {
    opacity: 1;
}

/**/
.uploadimagefile input {
    opacity: 0;
    z-index: 1;
}

.uploadimagefile {
    position: relative;
    z-index: 1;
}

.uploadimage i {
    margin-right: 10px;
}

.uploadimage {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #0053CD;
    border-radius: 50px;
    z-index: -1;
}