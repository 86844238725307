/* 
.signUPContainer form{
  max-width: 991px;
  padding-left: 15px;
  padding-right: 15px;
  margin: auto;
  width: 100%;
}
.signUPContainer{
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url('../../../../assets/images/signbk.png');
  position: relative;
  padding-top: 80px;
  padding-bottom: 100px;
} */
 
 .registerBord,
 .registerOuter{
  height: 100%;
 }
  ul.radioBTn {
    /* display: inline-block; */

}
ul.radioBTn li label{
  color: #fff !important;
}
ul.radioBTn li >div{
  display: block;
  width: 100%;
}
ul.radioBTn li {
  background: #f0f8ff;
  border-radius: 12px;
  /* display: inline-block;
  list-style: arabic-indic; */
  position: relative;
  width: auto;
  /* margin-right: 30px; */
}
/**/
.registerRight {
  margin-top: 20px;
}
.registerRight .fromGroup select + span{
  position: absolute;
  top: 50%;
  margin-top: 20px;
  transform: translateY(-50%);
  right: 15px;
}
.registerRight .fromGroup{
  margin-bottom: 30px;
  position: relative;
}
.registerRight input{
  border: 0.5px solid #FFFFFF;
}
.registerRight label{
  font-family: "Poppins", sans-serif !important;;
font-size: 16px;
font-weight: 500;
line-height: 28px;
text-align: left;
margin-bottom: 10px;
color: #fff;
display: block;

}
.asterisk {
  color: 'red';
  margin-Left: '2px';
}
.fromGroup input[type='text']{
  background: #fff;
  border: 1px solid #0053cd33;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  display: table;
  padding: 0 22px;
  height: 56px;
  font-size: 15px;
  border-radius: 10px;
  outline: none;
  border-radius: 8px !important;
}



/* new css*/
.navradio{
  gap: 25px;
  margin: 20px 0px;
}
.navradio li label{
  font-size:14px;
  font-weight: 500;
}
.textareaSize{
  height: 324px;
  resize: none;
}
.uploadbox{
  border: 1px dashed #D5D8E2;
  height: 176px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  margin-bottom: 15px;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
}
.uploadcontent p{
  color: #d4d5d9;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0px;
}
.uploadcontent h6{
  margin: 10px 0px;
}

.filePos{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.uploadImages{
  height: 118px;
  width: 118px;
  position: relative;
  border-radius: 14px;
}
.uploadImages img{
  height: 100%;
  width: 100%;
  border-radius: 14px;
}
.uploadnav{
  gap: 10px;
}
.uploadImages i{
  position: absolute;
  right: 9px;
  top: 9px;
  color: #fff;
  font-size: 14px;
}


/* add-promotion css */
.promotePlanCard{
  background-color: #F3F8FF;
  border-radius: 10px;
  margin-bottom: 20px;
  position: relative;
  padding: 20px 15px;
  text-align: center;
  width: 100%;
}

.promotePlanCard h6{
  color: #0E0E0E;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.promotePlanCard p{
  color: #0E0E0E;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin: 10px 0px;
}
.btnClick{
  height: 48px;
  background-color: #BBBBBB !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff !important;
  font-size: 14px !important;
  width: 160px;
  padding: 5px 15px;
  margin: auto;
}
.btnClick i{
  font-size: 10px;
}
.btnClick:hover{
  background-color: #0053CD !important;
}
.promotePlanCard.active .btnClick{
  background-color: #0053CD !important;
}
.promotePlanCard .check_round{
  display: none;
  position: absolute;
  top: 11px;
  right: 12px;
  color: #0053CD;
}
.promotePlanCard.active .check_round{
  display: block !important;
}

/* Boost Add  css*/
.img_boost{
  height: 450px;
  width: 445px;
  border-radius: 14px;
  margin-bottom: 15px;

}
.img_boost img{
  height:100%;
  width: 100%;
  border-radius: 14px;
  object-fit: cover;
}
.text_boost h6{
  color: rgba(0, 0, 0, 0.7);
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
}

.text_boost p{
  color: #f1f1f1;
  font-size: 17px;
  font-weight: 400;
  line-height: 25px;
 
}
.text_boost{
  margin-bottom: 30px;
 
}
.text_week{
  border-radius: 14px;
  background-color: #F3F8FF;
  padding: 25px;
}
.flex_week{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text_inner h5{
  color: #000;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 0px;
}
.text_inner p{
  color: #0E0E0E;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}
.text_inner{
  margin-bottom: 20px;
}
.text_reds{
  position: relative;
  padding-left: 40px;
  color: #FF0000;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}
.text_reds img{
  position: absolute;
  left: 0px;
  top: 0px;
}
.right_border{
  padding-right: 100px;
  border-right:1px solid #D9D9D999 ;
min-height: 300px;}

.right_pad{
  padding-left: 70px;
}
.payment_outer{
  background-color: #F3F8FF;
  border-radius: 14px;
  margin-bottom: 25px;
  padding: 30px 25px;
}

.payment_outer h6{
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #000;
}
.payment_outer p{
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #000;
  margin-bottom: 30px;
}
.payment_subtotal h5{
  color: #8890A0;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.border_b{
  border-top: 1px dashed #979797;
  padding-top: 9px;
}
.border_b h5{
  color: #000;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
}
.form_left .custom_control{
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
  padding: 6px 39px 6px 14px;
  height: 64px !important;
  border-radius: 8px;
  margin-bottom: 20px;
  position: relative;
  display: flex;
  align-items: center;
}
.form_left select:focus{
  background-color: transparent;
  color: #fff;
  border: 1px solid #fff;
}
.form_left select option{
  color: #000;
}
.value_form p{
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 0px;
}
.value_form h6{
  color: #fff;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 0px;
}
.custom_control::after {
  content: '';
  position: absolute;
  border: 8px solid #fff;
  right: 17px;
  top: 19px;
  border-top: 8px solid transparent;
  border-right: 8px solid transparent;
  transform: rotate(-45deg);
}


.text_boost .input-group label{
  font-size: 2.8rem;  
}

.text_boost input[type=radio]{
  appearance: none;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-clip: content-box;
  border: 2px solid #000 !important;
}

.text_boost input[type="radio"]:checked {
  border: 2px solid #ffffff !important;
  filter: invert(1);
}
.raio_custom label{
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 16px;
  color: #000;
}

.custom_cards{
  background-color: #FAFAFA;
  height: 70px;
  border-radius: 10px;
  padding: 7px 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 66px;
  position: relative;
  margin-bottom: 20px;
}

.custom_cards h6{
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: #181E22;
  margin-bottom: 0px;
}
.custom_cards p{
  font-size: 10px;
  font-weight: 400;
  line-height:14px;
  color: #181E22;
  margin-bottom: 0px;

}
.custom_cards img{
  position: absolute;
  left: 15px;
  top: 14px;
}

.raio_custom2 input[type="radio"] {
  background-color: #fff !important;
  padding: 4px;
  border: 2px solid #fff !important;
}

.form_left .custom_control2{
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
  padding: 6px 39px 6px 14px;
  height: 64px !important;
  border-radius: 8px;
  margin-bottom: 20px;
  position: relative;
  display: flex;
  align-items: center;

}

.custom_control2 label{
  position: absolute;
  right: 15px;
  top: 20px;
}


.custom_control2 p{
  font-size: 14px;
}
.pay_now{
  font-size: 14px;
  background-color: #0053CD;
  height: 48px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: #fff;
  width: 100%;
}


@media (min-width:991px) and (max-width: 2100px){
  .ScrollDiv {
    max-height: calc(100vh - 380px);
    height: auto;
    overflow-y: auto;
    border-radius: 10px;
    padding: 0px 20px 0 0;
  }
}
@media (max-width:991px){
  .ScrollDiv {
    max-height: 100%;
    overflow-y: hidden;
    padding-right: 0;
  }
}
@media (max-width:767px)
{
  ul.radioBTn li {
    width: 100%;
}
ul.radioBTn {
  row-gap: 12px;
}
.registerLeft img
{
  display: none;
}
}










