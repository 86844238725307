.textstatusongoing {
    position: relative;
    margin-top: 28px;
}
.textstatusongoing span{
    position: absolute;
    /* background: #EAB01B; */
    right: 0px;
    top: -38px;
    padding: 4px 10px;
    color: #fff;
    border-radius: 18px 0px 0px 18px;
    font-size:  14px !important;
}
.bannerthumbimage figure img{
    height: 300px;
    width: 100%;
}
@media (max-width: 991px) {
    .textstatuscompleted span {
        right: -9px;
        top: -30px;
    }
}