html,
body,
#root {
  height: 100%;
}

body {
  overflow-x: hidden;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body select, .form-select{
  appearance: none !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  background-image: url(./assets/images/dropdn.png) !important;
  background-position: 96% !important;
  background-size: 10px !important;
  background-repeat: no-repeat !important;
}

.slick-next:focus, .slick-next:hover, .slick-prev:focus, .slick-prev:hover{
  /* background: url(http://localhost:3000/static/media/arrow_next.d03098f06a7d8297a148.svg) no-repeat center !important; */
}
#shareModal .modal-dialog
{
  max-width: 670px;
}
.availabe-text {
  margin-top: -13px;
  justify-content: flex-end;

}
.newheadingstyle.newHeadProfile p {
  color: #000;
  font-size: 15px !important;
  font-weight: 500;
  margin-bottom: 5px;
}
.AboutProfile h6 {
  font-size: 16px;
  font-weight: 500;
  margin: 12px 0 5px;
  text-transform: uppercase;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.pac-container{
  border-top: 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

body {
  background: #f7f7f7;
}

.dropdown button {
  background: #f7f7f7 !important;
  border: 0;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  display: table;
  padding: 0 22px;
  height: 48px;
  font-size: 14px;
  border-radius: 8px !important;
  color: #095ad0 !important;
  outline: none;
  appearance: none;
  text-align: left;
  font-weight: 400 !important;
}

.css-t3ipsp-control,
.select__input-container {
  min-height: 48px !important;
  border-radius: 8px !important;
  padding: 0 !important;
  margin: 0 !important;
}

.select__control {
  border-radius: 8px !important;
  background-color: #f7f7f7 !important;
}

.select__control.css-13cymwt-control {
  background: #f7f7f7;
  border: 0.5px solid #0053cd33;
  border-radius: 8px !important;
  height: 48px;
}

.select__menu {
  position: absolute;
  z-index: 999;
}
.basic-select .select__input-container,
.basic-multi-select .select__input-container{
  height: 48px;
  display: block;
}
.select__control.css-13cymwt-control .select__value-container {
  height: 48px;
  /* padding: 0 !important; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 15px;
  margin: 0px 0 0 !important;
  flex-wrap: nowrap;
}

.select__control.css-13cymwt-control .select__input-container,
.select__control.css-13cymwt-control .select__input-container input {
  height: 48px;
  border-radius: 50px;

}
.heightautoscroll.paidads .dropdown-menu.show{
  width: calc(100% - 25px);
}
.heightautoscroll .dropdown-menu.show{
  padding: 0 !important;
}
.dropdown-menu.show {
  width: 100%;
  padding: 10px 10px;
  max-height: 300px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.proSlider .slick-slide {
  margin: 0 7px;
}

.dropdown-menu.show input {
  max-width: 100% !important;
  width: 100% !important;
  margin: 0 !important;
  height: 48px;
  border: 1px solid #0053cd;
}

.Reviewcontarctform .dropdown button {
  margin-left: 0 !important;
}

.proSlider .slick-track {
  display: flex;
}

.canclebtn {
  font-size: 15px;
  background: #f2f2f2;
  border: 1px solid #0053cd33;
  padding: 15px 40px;
  border-radius: 7px;
  color: #0053cd;
  text-decoration: none;
  /* margin-left: auto; */
}

.loginbtn {
  font-size: 15px;
  background: #0053cd;
  border: 1px solid #0053cd33;
  padding: 15px 40px;
  border-radius: 7px;
  color: #fff;
  text-decoration: none;
  margin-left: auto;
}

img.customeTolImg {
  max-width: 25px;
  margin-left: 4px;
  height: auto !important;
  background: transparent !important;
}

.cuatomeTop.activetooltip {
  opacity: 1;
  visibility: visible;
}
.cuatomeTop {
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
  z-index: 9;
  position: relative;
  min-width: 300px;
}

button.btn.avail-btn {
  background: #fff;
  border: 1px solid #0053CD;
  color: #0053CD;
}

.topTipHover  i:hover + .cuatomeTop,
.topTipHover i:hover + span + .cuatomeTop {
  opacity: 1;
  visibility: visible;
  transition: 0.5s;
  z-index: 999999;
  position: relative;
  min-width: 300px;
}

.dropdown-toggle {
  position: relative;
}
.addModalPro .dropdown-toggle::after,
.Reviewcontarctdetailform .dropdown-toggle::after,
.heightautoscroll .dropdown-toggle::after{
 right: 20px;
}
.dropdown-toggle::after {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
}

.modal.show .modal-dialog {
  /* max-width: 820px; */
}

.modal-body {
  padding: 0 !important;
  border-radius: 0 0 12px 12px !important;
  overflow: hidden;
}
/**/
.completedcontractshadowone .shadow{
  box-shadow: none !important;
}
/**/
.pagination {
  gap: 15px;
  justify-content: center;
}
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: .25rem;
}
.pagination .page-item {
  border-radius: 5px;
}
.pagination .page-link {
  /* background: transparent; */
  color: #000;
  border-radius: 50px !important;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
}
.page-item.disabled .page-link {
  background-color: transparent;
  opacity: 0.8;
}
.page-item.active .page-link {
  background-color: #0053cd;
  opacity: 1;
  border: 1px solid #0053cd;
  color: #fff;
}
/**/
input[type=password]::-ms-reveal,
input[type=password]::-ms-clear
{
    display: none;
}
/**/

@media (max-width:1500px) {
  .contractpages.h-100 {
    /* height: auto !important; */
  }
}
@media (max-width:991px) {
  .navbar .navbar-collapse {
    position: absolute;
    text-align: left;
    background: #fff;
    width: 100%;
    left: 0;
    padding: 0 25px;
    top: 70px;
    z-index: 9;
  }
  button.navbar-toggler {
    padding: 0 0;
    background-size: 10px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  button.navbar-toggler span {
    background-size: 19px;
  }
}
@media (max-width:767px) {
  .form-control {
    font-size: 14px !important;
    height: 48px !important;
  }

  body h3 {
    font-size: 21px;
  }

  .navbar .col-lg-1.col-2 {
    width: 30%;
    order: 2;
  }

  .recommendedjobs .slick-list .slick-slide {
    /* padding-right: 0; */
  }

  .form-check.form-switch {
    padding-left: 10px;
  }

  li.col.badge.bg-lightblue.text-black.rounded-pill {
    padding: 8px 0;
  }

  .Contracts.mt-5 {
    margin-top: 20px !important;
  }

  .form-check.form-switch {
    /* width: 100%; */
  }

  .navbar .col-lg-7.col-6 {
    width: 10%;
    order: 3;
  }

  .Contracts .container {
    padding: 0;
  }

  .navbar .col-4 {
    padding: 0;
    width: 60%;
  }

  .btn.bg-white span {
    font-size: 14px !important;
  }

  .topTipHover label {
    color: #fff;
  }





  .topTipHover i:hover + .cuatomeTop,
  .cuatomeTop {
    min-width: 290px;

  }

  .bgbluegradient.contractradio.py-4 {
    overflow: hidden;
  }

  .customRadio li:nth-child(2n) .MobOTP {
    left: -52%;
  }

  .jusify-content-end {
    margin-left: auto;
    float: right;
  }

  .Toltip {
    padding: 5px 10px;
    left: -30px;
  }

  .customRadio li:last-child .MobOTP {
    left: -28%;
  }

  /* .col-5 {
    width: 100%;
  }

  .col-7 {
    width: 100%;
  } */

  .btn.btn-white.needhelp {
    padding: 12px 11px;
    font-size: 12px !important;
    min-width: 105px;
  }

  .mob-justify {
    justify-content: space-between !important;
    padding-top: 11px;
  }

  .text-36,
  .text-38 {
    font-size: 25px !important;
  }

  .pt-5 {
    padding-top: 1rem !important;
  }

  .safe-btns .col-8 {
    width: 100%;
  }

  .safe-btns .col-8 .col-6:first-child {
    width: 100%;
  }

  .safe-btns {
    position: relative;
  }

  .safe-btns .col-8 button {
    position: absolute !important;
    right: 11px;
    top: 22px;
  }


  .h-100 {
    height: auto !important;
  }

  .h-100.bg-blue,
  .h-100.bg-yellow {
    height: 100% !important;
  }
}

@media (max-width: 575px) {
  .text-36,
  .text-38 {
    font-size: 22px !important;
  }

  p {
    font-size: 12px !important;
  }
}

/* App.css */
.bg-lightbluewhite  {
  background: #EBF1FB;
}


.confirmBtn {
  background-color: #0053CD;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
}

.cancelBtn {
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
}
.Toastify__toast-container {
  z-index: 9999 !important;
}

.swal-custom-button:hover {
  background-color: #2835ac;
  /* Darker shade on hover */
}

.swal-cancel-button {
  background-color: #793d2e;
  /* Customize cancel button color */
  color: #fff;
}

@media (max-width: 575px) {
  .availabe-text{
    justify-content: center;
}
}