.HeaderMain {
    padding: 10px 0px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.HeaderMain .foirmGroup {
    position: relative;
    width: 460px;
    display: flex;
}
.HeaderMain .foirmGroup input {
    width: 100%;
    height: 44px;
    border: 1px solid #E4E5E8;
    border-radius: 10px;
    padding: 0 39px;
    font-size: 15px;
}
.HeaderMain .foirmGroup button {
    position: absolute;
    right: 2px;
    border: 0;
    top: 2px;
    background: transparent;
}
.HeaderMain .foirmGroup span {
    position: absolute;
    left: 14px;
    top: 50%;
    transform: translateY(-50%);
}
.HeaderMain .foirmGroup span img {
    max-width: 18px;
}
.HeaderMain ul {
    margin: 0 auto;
}
.HeaderMain ul li {
    font-size: 13px;
    margin: 0 13px;
}
.HeaderMain ul li a{
    color: #0E0E0E !important;
}
.HeaderMain ul li.active a{
    color: #0053CD !important;
    font-weight: 600;
}
.Notify ul {
    margin: 0;
    display: flex;
    border-left: 1px solid #ddd;
    padding: 3px 0;
    padding-left: 31px;
}
.Notify ul li {
    list-style: none;
    margin: 0 11px;
    position: relative;
}
.Notify ul li span {
    width: 5px;
    height: 5px;
    background: #E67136;
    border-radius: 100px;
    font-size: 0;
    display: flex;
    position: absolute;
    top: 6px;
    right: -1px;
}
@media (max-width: 991px) {
    .HeaderMain {
        padding: 6px 10px;
    }
}

@media (max-width: 768px) {
    .HeaderMain .foirmGroup{
        width: 300px;
    }
}

@media (max-width: 575px) {
    .HeaderMain .foirmGroup{
        width: 200px;
    }
}
@media (max-width: 375px) {
    .HeaderMain .foirmGroup{
        width: 140px;
    }
}