.UserProfileOuter{
    border: 1px solid #D7D8E2;
    /* padding: 40px; */
    background: #fff;
}
.UserProfileOuter h2{
    padding: 40px 20px;
}
.UserProfileOuter ul li:hover, .UserProfileOuter ul li.active{
    background: rgba(0, 83, 205, 0.102);

}
.UserProfileOuter ul li{
    position: relative;
    padding: 20px 50px 20px 20px;
    border-bottom: 1px solid #f3f3f3;
}
.UserProfileOuter ul li:before{
    position: absolute;
    content: "";
    background-color: blue;
    right: 20px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;

}
.UserProfileOuter ul li.active::before{
    opacity: 1;
}