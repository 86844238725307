.myfriendsshare {
    position: absolute;
    bottom: 5px;
    right: 10px;
}

.status {
    background: #0053CD;
    padding: 5px 10px;
    color: #fff;
    font-size: 14px;
    border-radius: 18px 0 0 18px;
    text-transform: capitalize;
    position: absolute;
    right: 0;
}

.figure.profileCircle img {
    width: 100%;
    border-radius: 100px;
    /* max-width: 100px;
    max-height: 100px;
    height: 100px;
    width: 100px; */
    object-fit: cover;
    object-position: top;
}

figure.profileCircle img {
    width: 100%;
    border-radius: 100px;
    max-width: 79%;
}