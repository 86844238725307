/* body,html{
    height: 100%;
    margin: 0;
    background: #7F7FD5;
   background: -webkit-linear-gradient(to right, #91EAE4, #86A8E7, #7F7FD5);
    background: linear-gradient(to right, #91EAE4, #86A8E7, #7F7FD5);
} */

.chat {
    margin-top: auto;
    margin-bottom: auto;
}

.card {
    height: 420px;
    border-radius: 15px !important;
    border: 1px solid rgba(0, 0, 0, 0.4) !important;
    overflow: hidden;
}

.chat {
    position: fixed;
    right: 15px;
    left: inherit;
    bottom: 15px;
    z-index: 9999;
    max-width: 550px !important;
}

.bdhighlight {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #2880DA;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;

}

.contacts_body {
    padding: 0.75rem 0 !important;
    overflow-y: auto;
    white-space: nowrap;
}

.msg_card_body {
    overflow-y: auto;
    height: 300px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    background: #fff;

}

.card_header {
    border-radius: 15px 15px 0 0 !important;
    border-bottom: 0 !important;
}

.card_footer {
    border-radius: 0 0 15px 15px !important;
    border-top: 0 !important;
}

.container {
    align-content: center;
}

.search {
    border-radius: 15px 0 0 15px !important;
    background-color: rgba(0, 0, 0, 0.3) !important;
    border: 0 !important;
    color: white !important;
}

.search:focus {
    box-shadow: none !important;
    outline: 0px !important;
}

.card_footer textarea {
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-bottom: 0 !important;
    background-color: white !important;
    height: 60px !important;
    overflow-y: auto;
    font-size: 12px;
    border-radius: 0;
}

.card_footer textarea:focus {
    box-shadow: none !important;
    outline: 0px !important;
}

/* .attach_btn {
    border-radius: 15px 0 0 15px !important;
    background-color: rgba(0, 0, 0, 0.3) !important;
    border: 0 !important;
    color: white !important;
    cursor: pointer;
}

.send_btn {
    border-radius: 0 15px 15px 0 !important;
    background-color: rgba(0, 0, 0, 0.3) !important;
    border: 0 !important;
    color: white !important;
    cursor: pointer;
} */

.search_btn {
    border-radius: 0 15px 15px 0 !important;
    background-color: rgba(0, 0, 0, 0.3) !important;
    border: 0 !important;
    color: white !important;
    cursor: pointer;
}

.contacts {
    list-style: none;
    padding: 0;
}

.contacts li {
    width: 100% !important;
    padding: 5px 10px;
    margin-bottom: 15px !important;
}

.active {
    background-color: rgba(0, 0, 0, 0.3);
}

.user_img {
    height: 40px;
    width: 40px;
    border: 1.5px solid #f5f6fa;
    border-radius: 50%;


}

.user_img_msg {
    height: 40px;
    width: 40px;
    border: 1.5px solid #f5f6fa;
    border-radius: 50%;
}

.img_cont {
    position: relative;
    /* height: 40px;
    width: 40px; */
}

.img_cont_msg img {
    height: 40px;
    width: 40px;
    background-color: #2880da;
}

.online_icon {
    position: absolute;
    height: 8px;
    width: 8px;
    background-color: #4cd137;
    border-radius: 50%;
    bottom: 0.2em;
    right: 0.1em;
    border: 1.5px solid white;
}

.offline {
    background-color: #c23616 !important;
}

.user_info {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 15px;
}

.user_info span {
    font-size: 14px;
    font-weight: 600;
    color: white;
}

.user_info p {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.6);
    margin-bottom: 0;
}



.msg_cotainer {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: max-content;
}

.msg_cotainer label{
    background-color: #F2F2F2 !important;
    color: #000 !important;
}
.msg_cotainer label,
.msg_cotainer_send label{
    background-color: #2880DA;
    word-break: break-all;
    padding: 10px;
    color: #fff;
    font-size: 14px;
    border-radius: 5px;
}

.msg_cotainer_send {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: max-content;
   
}
.msg_cotainer .msg_time{
    text-align: left;
}
.msg_time {
    /* position: absolute; */
    /* left: 0;
    bottom: -15px; */
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
    width: 100%;
    text-align: end;
}

.msg_time_send {
    /* position: absolute; */
    /* right: 0;
    bottom: -15px; */
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
    text-align: end;
}

.msg_head {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    background: #217ed8;
    padding-right: 17px;
}

.closetag i {
    color: #fff;
}

.mic{
    background: rgba(0, 83, 205, 0.2);
    border-radius: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px;

}
.send{
    background: #217ed8;
    border-radius: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px;

}
.micsend{
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
}
.cardfootertextarea{
    padding-right: 100px;
    background-color: #fff;
    border-top: 1px solid rgba(0, 0, 0, 0.3) !important;
}

@media(max-width: 576px) {
    .contacts_card {
        margin-bottom: 15px !important;
    }
}