.welcomeloginleft p {
    font-size: 18px;
    font-weight: 300;
    line-height: 21.96px;
    text-align: left;
    font-family: "Poppins", sans-serif;
}

.welcomeloginleft span {
    font-size: 27px;
    font-weight: 300;
    line-height: 29.97px;
    text-align: left;
    font-family: "Poppins", sans-serif;
}

.welcomeloginleft h2 {
    font-size: 51px;
    font-weight: 600;
    line-height: 56.61px;
    letter-spacing: -0.03em;
    text-align: left;
    font-family: "Poppins", sans-serif;

}

.loginOuter {
    display: flex;
    height: 100%;
    align-items: center;
}

.loginLeft {
    width: 50%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    position: relative;
    background: linear-gradient(180deg, #DCECF9 46.45%, #B6E3F8 100%);

}

.logo {
    position: absolute;
    top: 25px;
    left: 30px;
}

.loginRight {
    width: 50%;
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
}

.topLogin {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 470px;
    padding: 40px 0 0;
}

.topLogin p {
    font-size: 14px;
    color: #0E0E0E;
    margin: 0;
}

.topLogin p a {
    color: #0053CD;
    text-decoration: none;
    font-weight: 600;
}

h6.feedText {
    text-align: center;
    color: #0053CD;
    font-size: 14px;
}

h6.feedText a {
    text-align: center;
    color: #0053CD;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
}

.topLogin button {
    border: 1px solid #0053CD;
    border-radius: 100px;
    background: #fff;
    color: #0053CD;
    font-size: 14px;
    font-weight: 600;
    padding: 11px 17px;
    position: absolute;
    right: 0px;
    font-family: "Poppins", sans-serif;
}

.loginRight form {
    width: 100%;
    max-width: 500px;
}

.loginRight form h4 {
    font-size: 32px;
    margin: 0 0 5px 0;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
}

.loginRight form p {
    font-size: 14px;
    color: #000;
    font-weight: 400;
    margin-bottom: 20px;
    font-family: "Poppins", sans-serif;
}

.loginRight .formGroup label {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #6E6E6E;
    margin-bottom: 010px;
    font-family: "Poppins", sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;

}

.loginRight .formGroup input {
    height: 56px;
    width: 100%;
    background: #fff;
    border-radius: 10px;
    border: 0;
    padding: 0 17px;
    font-size: 14px;
    border: 0.5px solid #0053CD33;
    
    
}

.loginRight .formGroup {
    margin-bottom: 25px;
    position: relative;
}

.loginRight .formGroup span {
    position: absolute;
    right: 10px;
    top: 50%;
    font-family: "Poppins", sans-serif;
    transform: translateY(-50%);
}

.loginRight .formGroup label a {
    font-size: 13px;
    text-decoration: none;
    color: #0053CD;
    font-family: "Poppins", sans-serif;
}
/* 
.loginRight .formGroup .loginwitharrow {
    position: relative;
    margin-top: 10px;
    border-radius: 50px;
    transition: all ease-in-out 0.5s;
    overflow: hidden;
}


.loginRight .formGroup .loginwitharrow:hover input{
    color: #fff;
}
.loginRight .formGroup .loginwitharrow:hover i{
    width: 100%;
    color: #fff;
    transition: all ease-in-out 0.5s;
    background: #0053CD;
    right: 0;
}

.loginRight .formGroup .loginwitharrow i img{
    width: 48px;
    height: 48px;
    background-color: #0053CD;
    padding: 18px;
    border-radius: 50px;
    position: absolute;
    right: 0;
}
.loginRight .formGroup .loginwitharrow span{
    color: #000;
    position: absolute;
    top: 50%;
    font-family: "Poppins", sans-serif;
    width: 100%;
    left: 0;
    text-align: center;
    right: 0;
    transform: translateY(-50%);
    z-index: -1;
    cursor: pointer;
    height: 48px;
    border-radius: 50px;
    background-color: #EAEAEA;
    line-height: 48px;
}
.loginRight .formGroup .loginwitharrow:hover span{

    color: #fff;
}
.loginRight .formGroup .loginwitharrow i{
    z-index: 1;
    background: #0053CD;
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 48px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;z-index: 99;
}
.loginRight .formGroup .loginwitharrow:hover input[type="submit"] {
    color: #fff;
    opacity: 1;
} */
.loginRight .formGroup input[type="submit"] {
    /* background: #0053CD; */
    background: #EAEAEA;
    border-radius: 5px;
    color: #000;
    font-weight: 600;
    font-size: 16px;
    transition: all ease-in-out 0.5;
    font-family: "Poppins", sans-serif;
    z-index: 999;
    position: relative;
    opacity: 0;
}

.Or span {
    font-size: 12px;
    color: #6E6E6E;
    letter-spacing: 2px;
    text-transform: uppercase;
    background: #f7f7f7;
    margin: 0 auto;
    display: table;
    padding: 0 18px;
    position: relative;
    z-index: 1;
}

.Or hr {
    background: #9c9c9c;
    border: 0;
    height: 1px;
    margin: -10px 0;
}

.socialLogin ul {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.socialLogin ul li img{
    width: 43px;
    height: 43px;
}
.socialLogin ul li {
    list-style: none;
    margin: 0 11px;
    cursor: pointer;
   
}

.Or {
    margin: 40px 0 49px;
}

/**/

/**/
/**/

.checkboxwrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
}

.checkboxwrapper input {
    position: absolute;
    opacity: 0;
}

.checkboxwrapper input:checked+label svg path {
    stroke-dashoffset: 0;
}

.checkboxwrapper input:focus+label {
    transform: scale(1.03);
}

.checkboxwrapper input+label {
    display: block;
    border: 2px solid #0053cd;
    width: 27px;
    height: 27px;
    border-radius: 6px;
    cursor: pointer;
    transition: all .2s ease;
    background: #ffffff;

}

.checkboxwrapper input+label:active {
    transform: scale(1.05);
    border-radius: 4px;
}

.checkboxwrapper input+label svg {
    pointer-events: none;
    padding: 5%;
}

.checkboxwrapper input+label svg path {
    fill: none;
    stroke: #000;
    stroke-width: 4px;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 100;
    stroke-dashoffset: 101;
    transition: all 250ms cubic-bezier(1, 0, .37, .91);
}

/**/
@media (min-height: 700px){
    .topLogin{
        padding: 15px 0;
    }
}
@media (max-width: 1240px) and (orientation: landscape) {
    .loginRight{
        position: relative;
        height: 100%;
        justify-content: flex-start;
        overflow: auto;
    }
    .topLogin button{
        right: 20px;
    }
    .topLogin{
        position: relative;max-width: 500px;
    }
    .welcomeloginleft span{
        font-size: 24px;
    }
    .welcomeloginleft h2{
        font-size: 40px;
        line-height: 36.61px;
    }
}
@media (min-height:800px) {
    .topLogin{
        position: relative;
    }
}
@media (min-width:1024px) {
    .topLogin button {
        right: -80px;
    }
}

@media (max-width:1200px) {
    .topLogin button{
        right: 0px;
    }
}
@media (max-width:991px) {
    .loginOuter {
        flex-wrap: wrap;
    }

    .loginLeft {
        width: 100%;
        height: auto;
        padding: 80px 0 40px;
    }

    .loginRight {
        width: 100%;
        height: auto;
        padding: 22px 0;
    }

    .loginRight form h4 {
        font-size: 26px;
        margin: 0 0 11px;
    }

    .loginRight form p {
        margin-bottom: 30px;
    }
}

@media (max-width:767px) {
    .loginLeft img {
        width: 100%;
    }

    .loginLeft {
        padding: 26px 20px 29px;
    }

    .logo {
        position: static;
        margin-bottom: 20px;
        margin-right: auto;
    }

    .loginRight {
        padding: 19px 16px;
    }
    .welcomeloginleft span {
        font-size: 17px;
    }
    .welcomeloginleft h2 {
        font-size: 27px;
        line-height: 38.61px;
    }
    br
    {
        display: none;
    }
    .welcomeloginleft p {
        font-size: 16px;
    }
    .topLogin {
        position: static;
        padding: 14px 0 16px;
    }
    .loginRight form p {
        font-size: 13px;
    }
    .topLogin button {
        font-size: 12px;
        padding: 8px 13px;
        position: static;
    }
    .Or span {
        background: #f7f7f7;
    }
    .checkboxwrapper input+label {
        border: 2px solid #0e53cd;
        width: 25px;
        position: relative;
        height: 25px;
    }

    .checkboxwrapper input+label svg {
  
        width: 12px;
        position: absolute;
        left: 5px;
        top: 5px;
    }
    .loginRight .formGroup input {
        border: 1px solid #e8f0fe;
    }
}

.error {
    color: red;
    font-size: 12px;
    margin-top: 5px;
}