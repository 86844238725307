.outerJobdetails h5 {
    font-size: 32px;
    font-weight: 600;
    text-align: center;
    background: #D7E7FC;
    margin: 0;
    padding: 20px 0;
}
.outerJobdetails {
    background: #fff;
    border-radius: 14px;
    overflow: hidden;
}
.innerFRomjob h6 {
    color: #0E0E0E;
    font-weight: 600;
    font-size: 22px;
}
.innerFRomjob {
    padding: 32px;
}
span.budget {
    font-size: 16px;
    color: #0E0E0E;
    font-weight: 600;
    margin: 0;
}
.radioBtn {
    display: flex;
    align-items: center;
    margin: 20px 0 11px;
    position: relative;
}
.radioBtn .formCheck {
    margin-right: 50px;
}
.radioBtn .formCheck input {
    margin-right: 8px;
}
.radioBtn .formCheck label {
    font-weight: 500;
    font-size: 16px;
}
span.instruction {
    background: #0BA51B1A;
    padding: 5px 18px;
    width: 100%;
    display: table;
    border-radius: 8px;
    font-weight: 500;
    font-size: 16px;
    color: #000;
    margin: 23px 0 32px;
}
.innerFRomjob table {
    width: 100%;
    margin-bottom: 30px;
}
.innerFRomjob table th {
    background: #D7E7FC;
    padding: 12px 18px;
    border: 1px solid #ddd;
}
.innerFRomjob table td {
    background: #fff;
    padding: 10px 18px;
    border: 1px solid #ddd;
    font-size: 15px;
    font-weight: 400;
}
.sheetDiscription p {
    margin: 0 0 1px;
    font-size: 14px;
    font-weight: 500;
}
.sheetDiscription label {
    font-size: 14px;
    font-weight: 600;
}
.sheetDiscription input {
    margin-right: 8px;
}
.jobDetails
{
    padding: 50px 0;
}