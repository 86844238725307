.card_view::before {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
    width: 100%;
    height: 100%;

}
.card_view {
    border-radius: 12px;
    text-align: center;
    position: relative;
    display: inline-block;
    overflow: hidden;
}

.card_view figure{
    margin-bottom: 0;
}
.card_view .card_status {
    background: rgba(0, 83, 205, 0.6);
    text-align: left;
    position: absolute;
    top: 10px;
    right: 0;
    border-radius: 12px 0 0 12px;
    padding: 4px 15px;
}

.card_view .card_status h4 {
    font-size: 9px;
    font-weight: 600;
    line-height: 13.5px;
    color: #fff;
    margin-bottom: 0;
}

.card_view .card_detail {
    position: absolute;
    bottom: 10px;
    right: 0;
    border-radius: 12px 0 0 12px;
    padding: 4px 10px;
    left: 0;
    text-align: center;
}

.card_view .card_detail p {
    font-size: 9px;
    font-weight: 500;
    line-height: 14px;
    margin-bottom: 0;
    color: #fff;
}

.card_view .card_detail h3 {
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    color: #fff;
}
.openwork i{
    margin-bottom: 10px;
}
.openwork p{
    font-size: 15px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    margin-bottom: 0;
    color: #0053CD;
}
.openwork{
    background: #fff;
    height: calc(100% - 6px);
    border: 1px dashed #0053CD;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 15px;
}
.new_feeds{
    margin-top: 20px;
    background-color: #F8F9FA;
    border-radius: 12px;
    padding: 25px 20px;
    border: 0.93px solid #D7D8E2
}
.slide_openwork .slick-arrow.slick-next{

}

/**/
.findcontract button{
    color: #0053CD !important;
}
.findcontract{
    background: url(../../../assets/images/find_contract_bg.svg) no-repeat center top;
    background-color: #0053CD;
    border-radius: 12px;
    padding: 25px;
    margin-bottom: 20px;
}
.findprofessionals button{
    color: #4FC219 !important;
}
.findprofessionals{
    background: url(../../../assets/images/find_contract_bg.svg) no-repeat center bottom;
    background-color: #4FC219;
    border-radius: 12px;
    padding: 20px 25px;
    margin-bottom: 20px;
}
.findjobs button{
    color: #E88715 !important;
}
.findjobs{
    background: url(../../../assets/images/find_contract_bg.svg) no-repeat center top;
    background-color: #E88715;
    border-radius: 12px;
    padding: 25px;
    margin-bottom: 20px;
}

/**/
.payrates p{
    font-weight: 500;
}
.payrates h3{
font-size: 14px;
font-weight: 500;
line-height: 21px;
text-align: left;

}
/**/
.switchToggle input[type=checkbox]{height: 0; width: 0; visibility: hidden; position: absolute; }
.switchToggle label {cursor: pointer; text-indent: -9999px; width: 41px; max-width: 41px; height: 20px; background: #d1d1d1; display: block; border-radius: 100px; position: relative; }
.switchToggle label:after {content: ''; position: absolute; top: 2px; left: 2px; width: 16px; height: 16px; background: #fff; border-radius: 90px; transition: 0.3s; }
.switchToggle input:checked + label, .switchToggle input:checked + input + label  {background: #3e98d3; }
.switchToggle input + label:before, .switchToggle input + input + label:before {content: ''; position: absolute; top: 0px; left: 5px; width: 16px; height: 16px; border-radius: 90px; transition: 0.3s; text-indent: 0; color: #fff; }
.switchToggle input:checked + label:before, .switchToggle input:checked + input + label:before {content: ''; position: absolute; top: 5px; left: 10px; width: 16px; height: 16px; border-radius: 90px; transition: 0.3s; text-indent: 0; color: #fff; }
.switchToggle input:checked + label:after, .switchToggle input:checked + input + label:after {left: calc(100% - 2px); transform: translateX(-100%); }
.switchToggle label:active:after {width: 41px; } 
.toggle-switchArea { margin: 10px 0 10px 0; }

/**/
.edit_share ul{
    gap: 15px;
    margin-top: 10px;
    margin-right: 10px;
}
.edit_share ul li{
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

/**/
.profilepicture{
    margin-top: -70px;
    position: relative;
    z-index: 9;
    margin-bottom: 10px;
}
/**/
.availabeforwork{
    background-color: #0053CD !important;
    background: url(../../../assets/images/availabeforwork_bg.png) no-repeat top right;
    border-radius: 12px;

}
/**/
.googleadsblock .googleads button{
    border-radius: 50px;
    
}
.googleadsblock .googleads{
    background-color: #003782;
    border-radius: 12px;
}

/**/
.postad::before{
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    background: url(../../../assets/images/dotsbg_left.svg) no-repeat top;
    background-size: contain;
    width: 150px;
    height: 150px;
}
.postad::after{
    position: absolute;
    right: 0;
    bottom: 0;
    content: "";
    background: url(../../../assets/images/dotsbg_right.svg) no-repeat top;
    background-size: contain;
    width: 150px;
    height: 100px;
}
.postad {
    background: linear-gradient(100.71deg, #9AF1AC -63.63%, #0053CD 55.87%);
    border-radius: 12px;
    position: relative;
}
.postad h2{
    color: #FFD600;
    font-size: 54px;
    position: relative;
    display: inline-block;
    margin-bottom: 30px;
}
.postad h2::before{
position: absolute;
content: "";
background-color: #FFD600;
left: 0;
right: 0;
bottom: -10px;
height: 4px;
width: 100%;
}

/**/