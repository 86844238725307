.OuterToptip {
    position: absolute;
    bottom: 40px;
    max-width: 375px;
    left: 8px;
    right: 0;
}
.Toltip p{
    color: #fff;
    font-size: 12px;
}

.Toltip {
    position: absolute;
    left: 0px;
    bottom: initial;
    max-width: 375px;
    width: 100%;
    right: 0;
    /* box-shadow: 0 0 5px 1px #eee; */
    box-shadow: 0px 0px 18px 0px #00000033;

    border-radius: 14px;
    padding: 10px 20px;
    /* background: linear-gradient(to bottom, #0053cd 0%,#ffffff 100%); */
    background: #fff;
    top: 36px;

}
.OuterToptip label:hover + .Toltip 
{
    opacity: 1;
    visibility: visible;
    transition: 0.5s;
}


.topTiphead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 5px;
    flex-wrap: wrap;
}
/* .topTiphead h4 {
    font-size: 30px;
    color: #FFBC01;
    font-weight: 700;
    margin: 0;
} */
/* .topTiphead h4 img 
{
    position: relative;
    left: -14px;
    top: -2px;
} */
.topTiphead h5 {
    font-size: 17px;
    color: #000;
    font-weight: 700;
}
.whtTol{
    margin-bottom: 10px;
}
.whtTol p {
    font-size: 12px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.7) !important;
}
.whtTol p:last-child {
    margin: 0;
}
/* .topTiphead h5 {
    font-size: 30px;
    color: #001E00;
    font-weight: 700;
    margin: -19px 0 15px;
} */
/* .Toltip p {
    font-size: 13px;
    font-weight: 400;
    color: #001E00;
    line-height: 19px;
} */
/* .Toltip h6 {
    font-size: 14px;
    color: #001E00;
    font-weight: 400;
    line-height: 18px;
} */
/* .Listen {
    display: flex;
    align-items: center;
    margin-top: 18px;
}
.Listen button {
    border-radius: 100px;
    margin: 0 0 0 5px;
    font-size: 12px;
    padding: 11px 16px 11px;
}
.Listen button.cntTol {
    font-size: 12px;
    background: #fff;
    border: 0;
    padding: 3px 4px;
    margin-left: 8px;
    padding-left: 16px;
}
.Listen button.cntTol img {
    margin-left: 16px;
} */

.cuatomeToptopoltip.tooltipClass {
    opacity: 1;
    visibility: visible;
  }
@media (max-width:767px)
{
    .OuterToptip{
        bottom: 58px;
        max-width: 290px;
        left: -10px;
    }
    /* ul.radioBTn li:nth-child(2n) .OuterToptip, ul.radioBTn li:last-child .OuterToptip {
        left: -53%;
    } */
    ul.radioBTn li:nth-child(2n) div {
        left: -53%;
    }
}