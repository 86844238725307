.Congratulation {
    background: #F6F6F6;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.innerCongra {
    background: #fff;
    padding: 50px 50px;
    border-radius: 20px;
    max-width: 860px;
    margin: 0 auto;
    text-align: center;
}
.innerCongra h4 {
    font-size: 23px;
    color: #101828;
    font-weight: 700;
    margin: 13px 0 10px;
}
.innerCongra p {
    color: #000000;
    padding: 0 80px;
}
.congBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 60px 0 0;
}
.congBtn a {
    border: 1px solid #D0D5DD;
    padding: 11px 40px;
    border-radius: 5px;
    text-decoration: none;
    font-size: 14px;
    color: #000000;
}
.congBtn button {
    padding: 11px 40px;
    border-radius: 5px;
    text-decoration: none;
    font-size: 14px;
    color: #ffffff;
    background: #0053CD;
    border: 0;
    min-width: 166px;
}