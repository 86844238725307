.modal p{
    font-size: 13px;
    margin: 0 0 0 0;
}
.modalLink{
    text-decoration: none;
}
.viewDashButton{
    min-width: 160px;
    font-size: 14px;
    height: 50px;
    padding: 0;
    margin: 0;
}
.viewDashButtonStyle{
    margin: 0;
    padding: 0;
}
/* CSS for modal */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    backdrop-filter: blur(10px); /* Blur the background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's above other content */
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
