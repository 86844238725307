.bggrey{
    border: 0.5px solid #0053CD33;
    background: #F6F6F6;
padding: 20px 25px;
border-radius: 10px;
}
@media (max-width: 767px) {
    .bggrey{
        padding: 15px;
    }
}