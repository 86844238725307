.boxContainer {
  display: flex;
  flex-wrap: wrap; /* This allows the boxes to wrap into new rows */
  gap: 15px; /* Space between the boxes */
  justify-content: space-evenly;
}

.box {
  background-color: #f6f8fa;
  /* width: 300px;
  height: 200px; */
  border-radius: 10px;
  /* top: 401px;
  left: 97px; */
  align-items: center;
  justify-content: center;
  text-align: left;
  border: 1px solid #f6f8fa ;
}


.box:hover button,
.box.active button{
  background-color: #4285f4 ;
  border-color: #4285f4  !important;
  color: #fff !important;
}
.box:hover,
.box.active{
  border: 1px solid #4285f4   ;
  background: #fff;
}

.connectiontab .connectiontabnav nav.navtabing .active{
 background: red !important;
}

.connectiontab{
  font-family: "Poppins", serif !important;
}

/**/

.box .badge {
  position: absolute;
  top: -15px;
  right: 10px;
  background-color: #4285f4;
  color: white;
  font-family: Arial, sans-serif;
  width: 75px;
  text-align: center;
  padding: 10px 5px 20px;
  border-radius: 0;
  clip-path: polygon(0 0, 100% 0, 100% 85%, 50% 100%, 0 85%);
}

.box .ribbon {
  position: absolute;
  top: -15px;
  right: 85px;
  width: 0px;
  height: 0;
  border-style: solid;
  border-width: 15px 0px 0px 15px;
  border-color: #244CB3 #244CB3 #244CB3 transparent;
  z-index: 1;
  transform: rotate(90deg);
}

.box .text {
  position: relative;
  z-index: 2;

}

.box .price {
  font-weight: bold;display: block; font-size: 24px;
  font-family: "Poppins", serif !important;
  font-weight: 600;
  /* line-height: 30px; */
}

.box .label {
  font-size: 12px;
  display: block;
  font-family: "Poppins", serif !important;

}

/**/

