h3 {
    font-size: 27px;
    color: #141414;
    font-weight: 600;
}

/* .ContractTabs {
    background: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0 0 20px 1px #dddddd42;
    padding: 35px;
} */
ul.tabList {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #BFBFBF;
    border-radius: 10px;
    margin-bottom: 0;
}

ul.tabList li {
    width: 100%;
    text-align: center;
    list-style: none;
    font-size: 20px;
    color: #00000073;
    font-weight: 600;

    padding: 13px 0;
    background: transparent;

}

ul.tabList li.active {
    background: #0053CD;
    color: #fff;
}

.AllContract {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0px 0 20px;
}

.AllContract h5 {
    font-size: 23px;
    color: #141414;
    font-weight: 600;
    margin: 0;
}

.AllContract a {
    font-size: 16px;
    color: #141414;
    font-weight: 600;
    text-decoration: none;
}

.AllContract a i {
    font-size: 14px;
    margin-left: 5px;
}

.ContarctOuter {
    border: 1px solid #D7D8E2;
    border-radius: 10px;
    margin: 0;
    max-width: 98%;
}

.leftContract h5 {
    color: #000000;
    font-size: 17px;
    font-weight: 600;
    margin: 0;
    line-height: 12px;
}

.leftContract span {
    font-size: 12px;
    color: #000000;
    font-weight: 400;
    line-height: 0;
}

.ContarctHead {
    display: flex;
    align-items: center;
    background: #F5F7FD;
    border-radius: 10px 10px 0 0;
    padding: 10px 21px;
    justify-content: space-between;
}

.leftContract button {
    background: #FFC702;
    border: 0;
    border-radius: 100px;
    margin-left: 7px;
    padding: 4px 12px;
    font-size: 13px;
    color: #000000;
    font-weight: 400;
}

.leftContract button.CoptText {
    background: #0e53cd;
    color: #fff;
}

.outerNameDetail {
    padding: 16px 20px;
    border-bottom: 1px solid #D7D8E2;
    background: #fff;
}

.timeSheets .col-md-3 {
    width: 50%;
}

.leftName h4 {
    font-size: 18px;
    color: #000000;
    font-weight: 500;
    margin-bottom: 24px;
}

.PrileImg {
    display: flex;
    align-items: center;
}

.PrileImg span img {
    max-width: 90px;
    max-height: 90px;
    border-radius: 100px;
    border: 2px solid #0f53cd;
}

.PrileImg span {
    margin-right: 15px;
}

.PrileImg h5 {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    color: #000000;
}

.PrileImg h6 {
    color: #525252;
    font-size: 16px;
    font-weight: 400;
    margin: 3px 0;
}

.PrileImg p {
    display: flex;
    align-items: center;
    margin: 0;
    color: #0053CD;
    font-size: 14px;
    font-weight: 500;
    position: relative;
    /* padding-left: 19px; */
}

.PrileImg p img {
    position: absolute;
    left: 0;
    top: 3px;
}

.rightName h6 {
    font-size: 18px;
    text-align: right;
    font-weight: 500;
    margin: 9px 0 17px;
}

.rightName p {
    font-size: 13px;
    margin: 0 0 2px;
    font-weight: 400;
    color: #000000CC;
}

.rightName::before {
    background: #D7D8E2;
    position: absolute;
    content: "";
    width: 1px;
    height: 78px;
    left: -31px;
    bottom: 1px;
}

.rightName {
    position: relative;
}

.timeSheets {
    padding: 16px 20px;
    background: #fff;
}

.timeSheets .col-md-3.col-6 {
    width: 50%;
}

.timesheetData {
    background: #F5F7FD;
    padding: 19px;
    border-radius: 16px;
    margin-bottom: 14px;
}

.timesheetData ul li {
    font-size: 14px;
    color: #000000;
    font-weight: 500;
    list-style: none;
    margin-top: 3px;
}

.timesheetData ul li.red {
    color: #FF424D;
}

.timesheetData ul li.yeloo {
    color: #FFC053;
}

.timesheetData ul li.gren {
    color: #2BC155;
}

.timesheetData ul {
    padding: 0;
    margin: 0;
}

.timesheetData h4 {
    font-size: 23px;
    color: #000;
    margin: 0;
}

.Horcontract {
    background: #0053CD17;
    border-radius: 11px;
    padding: 17px 19px;
    font-size: 14px;
    font-weight: 600;
    margin: 5px 0;
}

.Horcontract h6 {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    color: #000;
}

.MainLink {
    margin-top: 17px;
    margin-bottom: 11px;
}

.VideoCallBtn button {
    background: #D6D6D6;
    padding: 12px 20px;
    font-size: 16px;
    border: 0;
    width: 100%;
    margin: 0 8px;
    border-radius: 8px;
}

.VideoCallBtn button i {
    margin-right: 4px;
}

.VideoCallBtn button.videBtn {
    background: #0053CD;
    color: #fff;
}

.VideoCallBtn {
    display: flex;
    padding: 26px 20px;
}

.Infor p {
    display: flex;
    align-items: flex-start;
    color: #000000CC;
    font-weight: 400;
}

.Infor p img {
    margin-right: 6px;
}

.Infor {
    padding: 7px 20px;
    display: flex;
}

.leftContract .shedule {
    background: #70D4FF;
}

.CompletInerTime {
    background: #F5F7FD;
    padding: 17px 23px;
    margin: 7px 0;
}

.CompTimeheet {
    padding: 17px 20px;
}

.CompletInerTime h5 {
    font-size: 23px;
    color: #000000;
    font-weight: 600;
    margin-bottom: 13px;
}

.CompletInerTime h6 {
    font-size: 15px;
    color: #2BC155;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.leftContract .complete {
    background: #04F673;
}

.PendingCOnatctList {
    border: 1px solid #fff;
    border-radius: 10px;
    padding: 15px 16px;
}

.OuterPadd {
    padding: 19px;
}

.Loca p {
    display: flex;
    align-items: center;
    font-size: 15px;
    margin: 0 0 8px;
}

.PendingCOnatctList h6 {
    font-size: 17px;
    font-weight: 600;
    margin: 18px 0;
    padding-right: 100px;
}

.PenigSkil h5,
.PenigSkilRate h5 {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
}

.PenigSkil ul {
    padding: 0;
    margin: 0 0 22px;
    display: flex;
}

.PenigSkil ul li {
    background: #F5F7FD;
    border-radius: 100px;
    font-size: 14px;
    color: #000;
    padding: 9px 22px;
    margin-right: 6px;
}

.PenigSkilRate ul {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
}

.PenigSkilRate ul li {
    margin-right: 40px;
    width: auto;
}
.PenigSkil ul li p{
    margin-bottom: 0;
}

.PenigSkilRate ul li label {
    font-size: 18px;
    color: #0053CD;
    font-weight: 600;
}

.PenigSkilRate ul li span {
    font-size: 14px;
    color: #0E0E0ECC;
    font-weight: 500;
    width: 100%;
    display: table;
}

.RightFilyter button {
    background: #0f53cd;
    border: 0;
    padding: 7px 14px;
    border-radius: 5px;
    color: #fff;
    font-weight: 500;
}

.RightFilyter .FormGroup select {
    font-size: 15px;
    border: 0;
    color: #222B45;
    font-weight: 500;
}

.RightFilyter .FormGroup span {
    color: #96A5B8;
    font-size: 15px;
    margin-right: 6px;
}

.RightFilyter {
    display: flex;
    align-items: center;
}

.RightFilyter .FormGroup {
    margin-right: 23px;
}

.SearchData {
    background: #fff;
    border: 1px solid #E4E5E8;
    display: flex;
    border-radius: 6px;
    align-items: center;
    justify-content: space-between;
}

.SearchData .FormGroup:first-child {
    width: 60%;
    border-right: 1px solid #E4E5E8;
}

.SearchData .FormGroup {
    padding: 10px 19px;
    display: flex;
    position: relative;
    align-items: center;
    width: 40%;
}

.SearchData .FormGroup input {
    border: 0;
    outline: none;
    width: 100%;
}

.SearchData .FormGroup img {
    margin-right: 6px;
    object-fit: contain;
}

.SearchData .FormGroup input[type="submit"] {
    background: #0053CD;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    padding: 16px 38px;
    border-radius: 6px;
}

.SearchData .FormGroup:nth-child(2) {
    width: 52%;
}

.SeachIcgps {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 3px;
}

.FilerData li span {
    font-size: 14px;
    border: 1px solid #E4E5E8;
    background: #fff;
    padding: 14px 25px;
    border-radius: 100px;
    margin-right: 10px;
}

.FilerData ul {
    padding: 0;
    margin: 0;
    display: flex;
}

.FilerData ul li {
    float: left;
    display: flex;
}

.FilerData {
    margin-top: 25px;
}

.FondOpportuniy {
    padding: 50px 0;
}

.FilerData ul li button {
    font-size: 16px;
    border: 1px solid #0f53cd;
    background: #0e53cd;
    padding: 12px 25px;
    border-radius: 100px;
    margin-right: 10px;
    color: #fff;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.FilerData ul li button img {
    margin-right: 3px;
}

.opprBox .PendingCOnatctList {
    background: #fff;
    padding: 15px;
    /* margin-left: 30%; */
}

span.Hrrate {
    background: #BBFFBB80;
    padding: 4px 10px;
    border-radius: 4px;
    font-size: 14px;
    margin-bottom: 20px;
    color: #1A7B1A;
    font-weight: 500;
}

.opprBox .PenigSkil {
    margin-top: 14px;
}

.opprBox .PenigSkil ul li {
    font-size: 13px;
    padding: 7px 11px;
}

.LeftOpportunity .ContarctOuter {
    margin-bottom: 15px;
}

.SoftWareLicenece {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 3px 0 13px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 13px;
}

.SoftWareLicenece p {
    margin-bottom: 0;
}

.SoftWareLicenece div span {
    background: #FF8A00;
    border-radius: 100px;
    padding: 7px 14px;
    margin-right: 7px;
    float: left;
    font-size: 14px;
    color: #fff;
}

.SoftWareLicenece div span:nth-child(2) {
    background: #6ADB6A;
}

.SoftWareLicenece div span:nth-child(3) {
    background: #0053CD;
}

.Tagline label {
    font-size: 14px;
    color: #0E0E0E;
    position: relative;
}

.Tagline label {
    font-size: 14px;
    color: #0E0E0E;
    position: relative;
    margin-left: 30px;
    display: flex;
    align-items: center;
}

.Tagline label span {
    padding: 0;
    width: 16px;
    height: 16px;
    border-radius: 0;
    background: #6ADB6A;
}

.Tagline label:last-child span {
    background: #0053CD;
}

.Tagline {
    display: flex;
}

.OtherDetails {
    border-top: 1px solid #ddd;
    margin: 14px 0;
    padding: 16px 0;
}

.OtherDetails h5 {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;

}

.OtherDetails ul {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
}

.OtherDetails ul li {
    width: 34.33%;
    margin-bottom: 18px;
    display: flex;
}

.OtherDetails ul li span {
    padding-right: 10px;
}

.OtherDetails ul li div span {
    color: #0E0E0E;
    font-size: 15px;
    width: 100%;
    display: table;
    font-weight: 600;
}

.OtherDetails ul li div {
    line-height: 1.2;
}

.OtherDetails ul li div label {
    font-size: 13px;
    color: #0E0E0E;
    font-weight: 400;
}

.OtherDetails ul li:nth-last-child(2) {
    margin: 0;
}

.OtherDetails ul li:last-child {
    margin: 0;
}

.borderadd {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding-top: 23px;
}

.ApplyBtn {
    display: flex;
    align-items: center;
    border-top: 1px solid #ddd;
    padding-top: 22px;
    padding-bottom: 13px;
    padding: 24px 19px;
    justify-content: flex-end;
}

.clientProjectInner {
    background: #F5F7FD;
    border: 1px solid #0e53cd;
    border-radius: 7px;
    padding: 18px 20px;
    min-height: 134px;
}

.clientProjectInner h5 {
    font-size: 19px;
    font-weight: 600;
}

.clientProjectInner span {
    font-size: 30px;
    font-weight: 700;
    color: #2BC155;
    margin: 0;
}

.ClientProjects {
    border-top: 1px solid #ddd;
    padding-top: 36px;
}

.clientProjectInner.RejectedAppl {
    background: #FFE7E7;
    border: 1px solid #FF0000;

}

.clientProjectInner.RejectedAppl span {
    color: #FF0000;
}

.OpenContactOuter .OuterSlides {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    flex-wrap: wrap;
}

.OpenContactOuter .ContarctOuter {
    width: 49%;
    margin-bottom: 15px;
}

.commonNav ul {
    padding: 0;
    margin: 50px 0 0;
    display: flex;
    align-items: center;
}

.commonNav ul li.active span {
    font-weight: 700;
}
@media (max-width: 991px) {
    .SearchData .FormGroup:nth-child(2){
        width: 100%;
    }
    .SeachIcgps{
        top: 9px;
    }
}
@media (max-width: 1440px) {

    .timesheetData h4 {
        font-size: 19px;
    }
}

@media (max-width:991px) {
    .PenigSkilRate ul {
        gap: 15px;
    }
}
@media (max-width:767px) {
    .PenigSkil h5,
    .PenigSkilRate h5 {
        font-size: 13px;
    }
    .SearchData .FormGroup{
        width: 100% !important;
    }
    .PenigSkilRate ul li label{
        font-size: 14px;
    }
    .PenigSkilRate ul li span{
        font-size: 12px;
    }
    .PenigSkilRate ul {
        gap: 10px;
    }
    .PenigSkil h5, .PenigSkilRate h5{
        font-size: 12px;
    }
}
@media (max-width:575px) {
    .leftContract button, .leftContract span {
        font-size: 12px !important;
    }
}