.AllContract {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0px 0 20px;
}
.AllContract h5 {
    font-size: 23px;
    color: #141414;
    font-weight: 600;
    margin: 0;
}
.AllContract a {
    font-size: 16px;
    color: #141414;
    font-weight: 600;
    text-decoration: none;
}
.AllContract a i {
    font-size: 14px;
    margin-left: 5px;
}
.ContractTabs {

/* background: #FFFFFF; */
/* border-radius: 10px; */
/* box-shadow: 0 0 20px 1px #dddddd42; */
/* padding: 35px; */
}
.Jobs {
    /* padding: 33px 0; */
}
.Jobs h3 {
   margin-bottom: 14px;
}
.JobSlider {
    border: 1px solid #D7D8E2;
    border-radius: 10px;
    max-width: 100%;
    padding: 18px;
    margin-bottom: 15px;
}
.JobSlider h5 {
    /* display: flex;
    align-items: center;
    justify-content: space-between; */
}
.JobSlider h5 {
    font-size: 16px;
    font-weight: 600;
}
.JobSlider h5 span {
    background: #E671360D;
    font-size: 14px;
    color: #E67136;
    font-weight: 500;
    padding: 7px 10px;
    border-radius: 4px;
    display: flex;
    align-items: center;

}
.JobSlider h6 i{
    display: inline-block;
}
.JobSlider h6 {
    display: -webkit-box;
    font-size: 14px;
    font-weight: 400;
}
.JobSlider h6 img, .JobSlider h5 span img {
   margin-right: 7px;
}
.JobSlider h6:nth-of-type(2) {
    color: rgba(0, 0, 0, 0.7);
}
.JobSlider p {
    font-size: 14px;
    /* text-transform: uppercase; */
    font-weight: 600;
    color: #000000;
    margin: 13px 0 0;
}
.JobSlider p span {
    color: #1A7B1A;
}
.JobSlider p span.Close
{
color: #E62552;
}
.customerContarctSlides {
    border: 1px solid #D7D8E2;
    border-radius: 10px;
    max-width: 97%;
}
.CustomerSlideHEad {
    display: flex;
    align-items: center;
    background: #F5F7FD;
    border-radius: 10px 10px 0 0;
    padding: 10px 21px;
    justify-content: space-between;
}
.CustomerSlideHEad h5 {
    font-size: 17px;
    color: #000000;
    font-weight: 600;
    margin: 0;
}
.CustomerSlideHEad span {
    font-size: 13px;
    font-weight: 400;
    color: #000000;
}
.DateRane p {
    font-size: 14px;
    color: #000000CC;
    margin: 0;
}
.DateRane {
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
}
.DateRane h6 {
    font-size: 15px;
    font-weight: 700;
    color: #000000CC;
}
.Mainpara {
    padding: 10px 20px;
}
.Mainpara p {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
}
.Mainpara p a {
    font-size: 13px;
    color: #0053CD;
}
.VideoCallBtn {
    display: flex;
    padding: 26px 20px;
    border-top: 1px solid #ddd;
}
.VideoCallBtn button {
    background: #D6D6D6;
    padding: 12px 20px;
    font-size: 16px;
    border: 0;
    width: 100%;
    margin: 0 8px;
    border-radius: 8px;
}
.Meetbtn button {
    background: #D6D6D6;
    padding: 12px 20px;
    font-size: 16px;
    border: 0;
    width: 100%;
    margin: 0 8px;
    border-radius: 8px;
}
.Meetbtn {
    padding: 0 20px 23px;
}
.VideoCallBtn button.videBtn{
    background: #2BBC2B;
    color: #fff;
}
.Meetbtn button.videBtnmt
{
    background: #0053CD;
    color: #fff;
}
@media (max-width:1200px){
    .ContractTabs{
        /* padding: 10px 25px 25px 25px; */
    }
    .Jobs{
        padding-bottom: 0;
    }
}
@media (max-width:767px)
{
    .ContractTabs {
        /* padding: 20px 15px; */
    }
    .AllContract {
        margin: 6px 0 15px;
    }
    .AllContract h5 {
        font-size: 19px;
    }
    .AllContract a {
        font-size: 13px;
        font-weight: 500;
    }
    .Jobs {
        padding: 13px 0;
    }
    .JobSlider {
        max-width: 100%;
        padding: 15px;
    }
    .Mainpara{
        padding: 10px 14px;
    }
    .DateRane {
        padding: 15px 14px 0;
        flex-wrap: wrap;
    }
    .CustomerSlideHEad {
        padding: 10px 13px;
        flex-wrap: wrap;
    }
    .VideoCallBtn {
        padding: 16px 5px;
    }
    .Meetbtn {
        padding: 0 11px 23px;
    }
    .Meetbtn button.videBtnmt {
        background: #0053CD;
        color: #fff;
        margin: 0;
        font-size: 15px;
    }
}