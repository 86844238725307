.addroutes {
    background: #F7F7F7;
    padding: 70px 0;
}

.addroutes h3 {
    font-size: 30px;
    font-weight: 600;
    margin: 0px 0 30px;
}
.timeshettrd {
    border: 1px solid #00000045;
    background: #fff;
    padding: 30px;
}
.timeshethead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 14px;
}
.timeshethead h5 {
    font-size: 20px;
    font-weight: 700;
    margin: 0;
}
.timeshethead button {
    font-size: 14px;
    color: #141414;
    font-weight: 600;
    border: 0;
    padding: 0;
    margin-left: 54px;
}
.tabeltatabrd {
    width: 100%;
    border: 1px solid #ddd;
    padding: 14px 14px;
    font-size: 16px;
    font-weight: 600;
    border-bottom: 0;
}
.tabeltata 
{
    display: flex;
}
.addroutesouter {
    border-bottom: 1px solid #ddd;
}
.timeshettdatatable table {
    width: 100%;
    margin-top: 40px;
    border-top: 1px solid #ddd;
}
.timeshettdatatable tr th {
    border: 1px solid #ddd;
    padding: 14px 14px;
    font-size: 16px;
    font-weight: 600;
    border-top: 0;
}
.timeshettdatatable tr td {
    border: 1px solid #ddd;
    padding: 14px 14px;
    font-size: 16px;
    font-weight: 600;
    border-top: 0;
}
.timeshettdatatable ul
{
    display: flex;
    padding: 0;
    margin: 0;
}
.timeshettdatatable{
    margin-top: 20px;
    border-top: 1px solid #ddd;
    border-right: 1px solid #ddd;
}
.timeshettdatatable li {
    border: 1px solid #ddd;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-top: 0;
    display: flex;
    flex-direction: column;
    width: 16.9%;
    justify-content: center;
    border-right: 0;
}
.AddMonth textarea {
    width: 100%;
    border: 0;
}
.AddMonth {
    padding: 10px;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}
.timeshettdatatable li  input
{
    width: 100%;
    padding: 5px;
    border: 1px solid #ddd;
}
/* .timeshettdatatable li span {
    padding: 14px;
} */
.innerTotalleft ul {
    padding: 0;
    margin: 40px 0 0;
}
.innerTotalleft ul li {
    border: 1px solid #ddd;
    padding: 14px 24px;
    font-size: 16px;
    font-weight: 600;
    border-top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.innerTotalleft ul {
    border-top: 1px solid #ddd;
}
.finalDta p {
    font-size: 15px;
    font-weight: 600;
    margin: 0 0 9px;
}

.finalDta {
    margin-top: 50px;
}
.finalDta p.Red {
    color: #FF0000;
    margin-bottom: 40px;
}
.addshetbtn button {
    background: #0053CD;
    color: #fff;
    font-weight: 500;
    border: 0;
    border-radius: 6px;
    padding: 16px 30px;
}
.addshetbtn {
    margin-top: 33px;
}
