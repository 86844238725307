.textstatusongoing {
    position: relative;
    margin-top: 20px;
}
.textstatusongoing span{
    position: absolute;
    background: #EAB01B;
    right: -18px;
    top: -37px;
    padding: 4px 10px;
    color: #fff;
    border-radius: 0px 0 0 10px;
    font-size:  14px !important;
}

@media (max-width: 991px) {
    .textstatusongoing span {
        right: -9px;
        top: -30px;
    }
}