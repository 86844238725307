.innerSubscription span {
    background: #0053CD;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    display: table;
    text-align: center;
    padding: 12px 0;
    border-radius: 6px;
    margin-bottom: 28px;
}
.Subacription {
    background: #F6F6F6;
    padding: 80px 0;
}
.Subacription h3 {
    text-align: center;
    margin-bottom: 60px;
    font-size: 30px;
    font-weight: 600;
}
.innerSubscription {
    background: #fff;
    border: 3px solid #D7D8E2;
    border-radius: 10px;
    padding: 40px 30px;
}
.innerSubscription h4 {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 7px;
}
.innerSubscription h6 {
    font-size: 14px;
    text-align: center;
    color: #000000CC;
    max-width: 310px;
    margin: 11px auto 40px;
}
.innerSubscription ul {
    padding: 0;
    margin: 0;
}
.innerSubscription ul li {
    list-style: none;
}
.innerSubscription ul li label {
    font-size: 15px;
    color: #000000;
    font-weight: 600;
}
.innerSubscription ul li p {
    font-size: 13px;
    color: #000000CC;
}
.PricingConatiner {
    max-width: 990px;
    margin: 0 auto;
}
.GradBorder
{
    border: 10px solid #0e53cd;
    /* padding: 10px;
    border-radius: 8px; */
    position: relative;
    /* top: -23px; */
}