.textstatuscompleted {
    position: relative;
    margin-top: 20px;
}
.textstatuscompleted span{
    position: absolute;
    background: #09AF09;
    right: -18px;
    top: -37px;
    padding: 4px 10px;
    color: #fff;
    border-radius: 0px 0 0 10px;
    font-size:  14px !important;
}
@media (max-width: 991px) {
    .textstatuscompleted span {
        right: -9px;
        top: -30px;
    }
}