.card_view::before {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
    width: 100%;
    height: 100%;

}
.card_view {
    border-radius: 12px;
    text-align: center;
    position: relative;
    display: inline-block;
    overflow: hidden;
}

.card_view figure{
    margin-bottom: 0;
}
.card_view .card_status {
    background: rgba(0, 83, 205, 0.6);
    text-align: left;
    position: absolute;
    top: 10px;
    right: 0;
    border-radius: 12px 0 0 12px;
    padding: 4px 15px;
}

.card_view .card_status h4 {
    font-size: 9px;
    font-weight: 600;
    line-height: 13.5px;
    color: #fff;
    margin-bottom: 0;
}

.card_view .card_detail {
    position: absolute;
    bottom: 10px;
    right: 0;
    border-radius: 12px 0 0 12px;
    padding: 4px 10px;
    left: 0;
    text-align: center;
}

.card_view .card_detail p {
    font-size: 9px;
    font-weight: 500;
    line-height: 14px;
    margin-bottom: 0;
    color: #fff;
}

.card_view .card_detail h3 {
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    color: #fff;
}
