.signuppage {
    height: calc(100vh - 82.5px);
    display: flex;
    flex-direction: column;
    background-repeat: no-repeat;
    justify-content: center;
    background-size: 100% 100%;
    /* background-image: url("../../../../assets/images/signbk.png"); */
    position: relative;
}

.droptopsearch {
    overflow-y: auto;
    max-height: 260px;
    overflow-x: hidden;

}

body .occupationdetails .droptopsearch {
    margin-top: 70px;
}

body .occupationdetails .signUPContainer .fromGroup input {
    width: calc(100% - 30px) !important;
    top: 20px;
    left: 15px;
}

.signuppage::before {
    background: url(../../../../assets/images/reg_bg.jpg) repeat 50%;
    background-size: contain;
    -webkit-backface-visibility: .7;
    backface-visibility: .7;
    bottom: 0;
    content: "";
    left: 0;
    opacity: .07;
    position: relative;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
}

.signupHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 40px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.rightSign {
    display: flex;
    align-items: center;
}

.rightSign p {
    font-size: 14px;
    color: #000000;
    font-weight: 400;
    margin-bottom: 40px;
}

.rightSign p a {
    color: #0053CD;
    font-weight: 600;
    text-decoration: none;
}

.rightSign button {
    border: 1px solid #0053CD;
    border-radius: 100px;
    background: #fff;
    color: #0053CD;
    font-size: 14px;
    font-weight: 600;
    padding: 11px 17px;
    margin-left: 16px;
}

.signUPContainer form:before {
    background: url(../../../../assets/images/reg_bg.jpg) repeat 50%;
    background-size: contain;
    -webkit-backface-visibility: .7;
    backface-visibility: .7;
    bottom: 0;
    content: "";
    left: 0;
    opacity: .07;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
}

.signUPContainer form {
    max-width: 700px;
    margin: 0 auto;
    background-color: #fff;
    /* background: url("../../../../assets/images/reg_bg.jpg");
    background-size: contain;
    -webkit-backface-visibility: .07;
    backface-visibility: .07;  */
    background-position: center;
    border: 1px solid #0053CD33;
    border-radius: 10px;
    padding: 45px 40px 25px;
    position: relative;
    z-index: 1;
}

.signUPContainer h5 {
    font-size: 21px;
    color: #0053CD;
    text-align: center;
    font-weight: 600;
    margin: 0;
}

span.Leftntm {
    position: absolute;
    top: 34px;
    left: 40px;
    z-index: 9;
}

.signUPContainer p {
    font-size: 15px;
    margin: 8px 0 31px;
    text-align: center;
    font-weight: 400;
    color: #000;
    opacity: 0.5;
}

.signUPContainer .fromGroup input {
    background: #F7F7F7;
    border: 0;
    max-width: 360px;
    width: 100%;
    margin: 0 auto;
    display: table;
    padding: 0 22px;
    height: 56px;
    font-size: 15px;
    border-radius: 10px;
    outline: none;
}

.signUPContainer .fromGroup select {
    background: #F7F7F7;
    border: 0;
    max-width: 360px;
    width: 100%;
    margin: 0 auto;
    display: table;
    padding: 0 22px;
    height: 48px;
    font-size: 15px;
    border-radius: 50px;
    color: #727272;
    outline: none;
    appearance: none;
}

.signUPContainer .fromGroup {
    max-width: 360px;
    margin: 0 auto 23px;
    position: relative;
}

.signUPContainer .fromGroup span {
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
}

.signUPContainer .fromGroup input[type="submit"] {
    max-width: 180px;
    background: #0053CD;
    color: #fff;
    font-weight: 500;
}

.signUPContainer .fromGroup span.editNUmber {
    position: static;
    margin: 8px auto 23px;
    display: table;
    border: 1px solid #0053CD;
    border-radius: 100px;
    font-size: 15px;
    font-weight: 600;
    padding: 8px 30px;
}

.signUPContainer .fromGroup label {
    margin-bottom: 11px;
    font-size: 15px;
    font-weight: 500;
    display: block;
}

.signUPContainer .fromGroup .otpFields input[type="text"] {
    width: 53px !important;
    height: 53px !important;
    padding: 0;
    margin: 0 10px 0 0;
}

.signUPContainer .fromGroup h6 {
    display: flex;
    align-items: center;
    margin: 16px 0 0;
    font-size: 14px;
    justify-content: space-between;
}

.signUPContainer .fromGroup h6 a {
    color: #0053CD;
    font-weight: 600;
    text-decoration: none;
    padding: 0 4px;
}

.signUPContainer .fromGroup span.editNUmber img {
    margin-left: 10px;
}

.error {
    color: red;
    font-size: 12px;
    margin-top: 5px;
}

.otpFields>div {
    margin: 20px auto;
    gap: 10px;
    justify-content: center;
}

.otpFields input {
    width: 53px !important;
    height: 53px;
    background: #f2f2f2;
    border: 0;
    border-radius: 10px;
}

/**/
.PhoneInputInput {
    flex: 1 1;
    min-width: 0;
    border: 1px solid #0759cf;
    padding: 6px 10px;
    border-radius: 50px;
}
/**/
@media (max-width:991px) and (orientation: landscape) {
    .signuppage {
        display: block;
    }

    .signuppage .signUPContainer {
        /* background: linear-gradient(180deg, #0053cd 0%, rgba(20, 136, 255, 0.5) 100%); */
    }
}

/**/

@media (max-width:767px) {
    .otpFields input {
        width: 48px !important;
        height: 48px;
    }
    .signUPContainer form {
        padding: 25px 15px 8px;
    }

    .signUPContainer {
        padding: 60px 11px 16px;
    }

    span.Leftntm img {
        max-width: 33px;
    }

    span.Leftntm {
        position: absolute;
        top: 19px;
        left: 20px;
    }

    .signUPContainer h5 {
        margin: 0 0 17px;
    }

    .otpFields>div {
        gap: 5px;
    }

    .signUPContainer .fromGroup .otpFields input[type="text"] {
        width: 48px !important;
        height: 48px !important;
        margin-right: 1px;
    }

    .signUPContainer .fromGroup h6 div {
        width: 100%;
        text-align: center;
        padding-top: 11px;
    }

    .signUPContainer .fromGroup h6 {
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .signUPContainer p {
        margin: 8px 0 11px;
    }
}

@media (max-width: 575px) {
    .signUPContainer .fromGroup .otpFields input[type="text"] {
        width: 38px !important;
        height: 38px !important;
        margin-right: 1px;
    }
    .signUPContainer form {
        padding-top: 45px;
    }
    span.Leftntm {
        top: 8px;
    }
}