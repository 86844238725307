body {
    margin: 0;
  }
  /* #root {
    max-width: 100vw;
    margin: 0 auto;
    padding: 2rem;
    text-align: center;
  } */
  
  .logo {
    height: 6em;
    padding: 1.5em;
    will-change: filter;
    transition: filter 300ms;
  }
  .logo:hover {
    filter: drop-shadow(0 0 2em #099DFD);
  }
  
  .card {
    padding: 2em;
  }
  
  .read-the-docs {
    color: #888;
  }
  
  #channelName {
    display: block;
    padding: 15px;
    margin: 0 0 25px;
    width: 50vw;
    max-width: 500px;
  }
  
  .card button:hover {
    border-color: #099DFD;
    background-color: #099DFD;
  }
  
  #controlsToolbar {
    bottom: 35px;
    right: 35px;
    position: fixed;
    z-index: 99;
  }
  
  #mediaControls button {
    margin: 0 5px 7.5px;
    padding: 10px;
  }
  
  #mediaControls button:hover {
    border-color: #099DFD;
  }
  
  
  #endConnection {
    border-color: red;
    opacity: 50%;
  }
  
  #endConnection:hover {
    background-color: red;
    border-color: white;
    opacity: 100%;
  }
  
  #localVideo {
    width: 50vw;
    aspect-ratio: 16/9;
    max-width: 480px;
    min-width: 360px;
    position: fixed;
    right: 25px;
    bottom: 25px;
    margin: 0;
  }
  
  #remoteVideoGrid {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    /* justify-content: center; */
    align-items: center;
  }
  
  .remote-video-container {
    position: relative;
    width: 25vw;
    aspect-ratio: 4/3;
    max-width: 720px;
    min-width: 360px;
    overflow: hidden;
  }

  .room-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 100vh;
    padding: 20px;
    background-color: #f8f9fa; /* Light background */
    font-family: Arial, sans-serif;
  }
  
  .left-section {
    width: 30%;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .generate-link-btn {
    display: inline-block;
    width: 100%;
    padding: 10px 15px;
    margin-bottom: 15px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s;
  }
  
  .generate-link-btn:hover {
    background-color: #0056b3;
  }
  
  .meeting-link {
    margin-top: 20px;
    word-wrap: break-word;
  }
  
  .meeting-link p {
    font-size: 14px;
  }
  
  .meeting-link a {
    color: #007bff;
    text-decoration: none;
  }
  
  .meeting-link a:hover {
    text-decoration: underline;
  }
  
  .right-section {
    width: 65%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 20px;
  }
  
  .video-container {
    width: 100%;
    height: 500px;
    background-color: #000; /* Placeholder for the video container */
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  