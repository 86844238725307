.regpage{
    position: relative;
    height: 100%;
}
.regpage::before{
    background: url(../../../assets/images/reg_bg.jpg) repeat 50%;
    background-size: contain;
    -webkit-backface-visibility: .7;
    backface-visibility: .7;  
    bottom: 0;
    content: "";
    left: 0;
    opacity: .07;
    position: relative;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
}

/*--share-modal-css--*/

.ShareModal {
    padding: 25px;
}
.ShareModal .formGroup label, .shareLink label, .myfriendSearch label {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 4px;
}
.Linkbio {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #F7F7F7;
    height: 51px;
    border-radius: 7px;
    padding: 0 16px;
}
.Linkbio span {
    color: #0053CD;
    font-size: 16px;
}
.ShareModal .Linkbio label {
    font-size: 18px;
    margin: 0;
    font-weight: 400;
    display: flex;
    align-items: center;
    column-gap: 8px;
}
.shareLink {
    padding: 16px 0;
}
.shareLink ul {
    display: flex;
    align-items: center;
    column-gap: 20px;
}
.shareLink ul li span {
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #F7F7F7;
    padding: 15px 7px;
    gap: 10px;
    font-size: 12px;
}
.shareLink ul li {
    width: 20%;
}
.myfriendSearch .formGrop input {
    height: 50px;
    border: 1px solid #ddd;
    width: 100%;
    padding: 0 18px;
    border-radius: 6px;
}
.myfriendSearch .formGrop {
    position: relative;
    margin: 12px 0 20px;
}
.myfriendSearch ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7px 0;
}
.myfriendSearch ul li span img {
    max-width: 36px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin-right: 15px;
}
.myfriendSearch ul li span + label {
    font-size: 16px;
}
.myfriendSearch ul li span {
    font-size: 17px;
}
.myfriendSearch ul {
    padding-top: 14px;
}
.sharebtn button {
    height: 48px;
    background: #0053CD;
    border: 0;
    border-radius: 5px;
    width: 100%;
    color: #fff;
}
.myfriendSearch .formGrop button {
    height: 50px;
    position: absolute;
    right: 0;
    top: 0;
    background: #0053CD;
    color: #fff;
    border: 0;
    border-radius: 0 6px 6px 0;
    padding: 0 21px;
}
@media (max-width: 767px) {
    .shareLink ul {
        column-gap: 12px;
    }
}