.boostProfile {
    padding: 50px 0;
}

.boostBk {
    background: #fff;
    border-radius: 20px;
    padding: 30px;
    max-width: 80%;
    margin: 0 auto;
}

.boostBk h3 {
    font-size: 32px;
}

.boostBk p {
    font-size: 14px;
}

.bostBox {
    background: #FAFAFA;
    padding: 22px;
    border-radius: 10px;
}

.bosstLeft {
    display: flex;
    align-items: center;
    column-gap: 16px;
    border-right: 1px solid #ddd;
    height: 100%;
}

.bosstLeft img {
    border-radius: 100px;
    width: 70px;
    height: 70px;
}

.bosstLeft h5 {
    font-size: 24px;
    color: #000;
    font-weight: 600;
    margin: 0;
}

.bosstLeft span {
    font-size: 16px;
}

.bostDate h5 {
    font-size: 20px;
    margin: 0;
    font-weight: 600;
}

.bostDate span {
    font-size: 14px;
    font-weight: 400;
    color: #0E0E0E;
}

.bostalert p {
    color: #FF0000;
    font-size: 14px;
    font-weight: 500;
    margin: 14px 0 0;
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.boastPayment {
    padding-top: 40px;
}

.boastPayment h5 {
    font-size: 20px;
    font-weight: 600;
    opacity: 0.8;
}

.bostpaymentleft .formGroup select {
    padding: 18px 15px 0;
    height: 60px;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #ddd;
}

.ragioBtn p {
    margin: 0;
}

.bostpaymentleft .formGroup {
    position: relative;
}

.bostpaymentleft .formGroup span {
    position: absolute;
    top: 41px;
    left: 18px;
    font-size: 12px;
}

.paymentForm button {
    height: 48px;
    width: 100%;
    background: #0053CD;
    color: #fff;
    border: 0;
    border-radius: 10px;
    margin-top: 19px;
}

.paymentForm {
    border-left: 1px solid #ddd;
    padding-left: 35px;
    height: 100%;
}
.paymentBox {
    background: #FAFAFA;
    border-radius: 20px;
    padding: 24px;
}

.paymentBox ul li:last-child {
    font-weight: bold;
    color: #000;
    border-top: 1px dashed #979797;
    padding-top: 17px;
    margin-top: 15px;
}

.paymentBox h6 {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
}

.paymentBox ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #8890A0;
    padding: 4px 0;
}

.paymentBox label {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 14px;
}

.paymentForm h5 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 18px;
}

.selctCard {
    padding: 0px 15px 0;
    height: 60px;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}

.selctCard {
    background: #FAFAFA;
}

.selctCard h6 {
    margin: 0;
    display: flex;
    align-items: center;
    column-gap: 8px;
    position: relative;
    width: 100%;
}

.selctCard:nth-of-type(2) h6 {
    align-items: flex-start;
}

.selctCard:nth-of-type(2) h6 label {
    top: 21px;
}

.selctCard h6 label {
    width: 100%;
    position: absolute;
    left: 51px;
    top: 27px;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
}


.bostpaymentleft {
    padding-right: 22px;
}

/**/
.checkboxwrap {
    --gray: #636e72;

}

.checkboxwrap label {
    font-size: 1rem;
}

/* CORE STYLES */
.checkboxwrap input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 1em;
    height: 1em;
    font: inherit;
    border: 0.1em solid var(--gray);
    margin-bottom: -0.125em;
}

.checkboxwrap input[type=checkbox] {
    border-radius: 50px;
}

.checkboxwrap input:checked {
    border-color: transparent;
    background: var(--gray) border-box;
    box-shadow: 0 0 0 0.1em inset #fff;
}

.checkboxwrap input:not(:checked):hover {
    border-color: transparent;
    background: linear-gradient(#fff, #fff) padding-box, var(--gray) border-box;
}

/**/
.avaiablefeatures ol li {
    list-style: decimal;
}

.avaiablefeatures li {
    font-size: 14px;
    margin-bottom: 10px;
}

@media (max-width: 991px) {
    .bosstLeft {
        border-right: 0;
        margin-bottom: 30px;
    }

    .paymentForm {
        border-left: 0;
        padding-left: 0;
    }
}