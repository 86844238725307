h3 {
  color: #000;
  font-weight: 600;
  font-size: 30px;
}

.spinner {
  position: fixed;
  z-index: 1031;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/*--timesheet-css--*/
.timsheetInner {
  background: #0053cd;
  border-radius: 16px;
  padding: 30px;
  display: flex;
  align-items: center;
}

.Mydashboard {
  padding: 30px 0;
}

.timsheetInner span {
  margin-right: 14px;
}

.timsheetInner h6 {
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 0;
}

.timsheetInner h5 {
  font-size: 42px;
  font-weight: 600;
  color: #fff;
  display: flex;
  justify-content: space-between;
  margin: 0;
  align-items: baseline;
}

.timsheetInner h5 a {
  font-size: 12px;
  color: #fff;
  text-decoration: none;
}

.topDashborad h3 {
  margin-bottom: 20px;
}

.timsheetInner div {
  width: 100%;
  position: relative;
  top: 4px;
}

.timeTwo {
  background: #e6a90d;
}

.timeThree {
  background: #1bd084;
}

.timeFour {
  background: #1794ef;
}

/*--Profile-css--*/

.ProfieDash {
  background: #fff;
  box-shadow: 0 0 20px 1px #dddddd42;
  border-radius: 12px;
  margin-top: 41px;
  /* overflow: hidden; */
}

.ProfileLink a {
  background: #f8f9fa;
  color: #000;
  text-decoration: none;
  font-size: 14px;
  padding: 9px 13px 8px;
  margin-left: 10px;
  border-radius: 4px;
}

.ProfileLink button {
  background: #f8f9fa;
  text-decoration: none;
  font-size: 14px;
  padding: 9px 13px 8px;
  margin-left: 10px;
  border-radius: 4px;
  border: 0;
  color: #0053cd;
}

.ProfileLink {
  background: #0053cd;
  padding: 25px 30px;
  display: flex;
  justify-content: flex-end;
}

.ProfileDetails {
  display: flex;
  padding: 0px 0px 33px;
  align-items: center;
  justify-content: center;
}

.Imagero img {
  max-width: 174px;
  border-radius: 100px;
  min-height: 174px;
  object-fit: cover;
  margin-top: -95px;
}

.Imagero {
  /* margin-right: 20px; */
}

.NamePro h5 {
  font-size: 20px;
  font-weight: 600;
  color: #3d475c;
}

.NamePro h5 span {
  font-size: 12px;
  background: #0053cd;
  border-radius: 100px;
  color: #fff;
  padding: 5px 10px;
}

.NamePro h6 {
  font-size: 12px;
  color: #9499a1;
  margin-top: 26px;
  margin-bottom: 20px;
}

.NamePro h6 span label {
  color: #000;
}

.NamePro h6 span i {
  color: #fac300;
}

.NamePro h6 span {
  margin-left: 9px;
}

.NamePro ul.profile_des li {
  font-size: 14px;
  color: #9499a1;
  list-style: none;
  margin-right: 27px;
}

.NamePro ul.profile_des {
  padding: 0;
  margin: 12px 0 0;
  display: flex;
}

.SocialProfile label {
  color: #000000;
  font-size: 16px;
  float: left;
  padding-right: 47px;
}

.SocialProfile input {
  width: 57px !important;
  height: 30px;
}

.SocialProfile div {
  display: flex;
  align-items: center;
}

/* .SocailBRn ul {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
} */

/* .SocailBRn button {
    background: #E67136;
    border: 0;
    border-radius: 6px;
    font-size: 16px;
    color: #fff;
    padding: 10px 18px;
    font-weight: 600;
    margin-right: 22px;
} */

/* .SocailBRn ul span {
    font-size: 14px;
    color: #9499A1;
    margin-right: 7px;
}

.SocailBRn ul li {
    border: 1px solid #D4D4D4;
    width: 29px;
    height: 29px;
    border-radius: 7px;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 7px;
    color: #0053CD;
}

.SocailBRn {
    margin-top: 5px;
} */

.SocialProfile {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: auto;
}

.ContractTabs {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 20px 1px #dddddd42;
  /* padding: 35px; */
}

ul.tabList {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #bfbfbf;
  border-radius: 10px;
}

ul.tabList li {
  width: 100%;
  text-align: center;
  list-style: none;
  font-size: 20px;
  color: #00000073;
  font-weight: 600;
  cursor: pointer;
  padding: 13px 0;
  background: #fff;
}

ul.tabList li.active {
  background: #0053cd;
  color: #fff;
}
ul.tabList li.all {
  background: #0053cd;
  color: #fff;
}
ul.tabList li.ongoing {
  background: #28a745;
  color: #fff;
}
ul.tabList li.open {
  background: #ffc107;
  color: #fff;
}
ul.tabList li.pending {
  background: #007bff;
  color: #fff;
}
ul.tabList li.draft {
  background: #6c757d;
  color: #fff;
}
.timeSheets .col-md-3.col-6 {
  width: 50%;
}

.available {
  color: #0053cd;
  font-size: 14px;
}

.unavailable {
  color: black;
}

ul.homeFiletr li span {
  background: #2bc155;
  display: flex;
  padding: 10px 70px;
  color: #fff;
  border-radius: 8px;
  font-weight: 600;
}

ul.homeFiletr li {
  margin: 0 8px;
}

ul.homeFiletr li:nth-child(4) span {
  background: #f3000e;
}

ul.homeFiletr {
  display: flex;
  justify-content: center;
  margin: 32px 0 0;
  padding: 0;
}

ul.homeFiletr li:nth-child(2) span {
  background: #e6a90d;
}

ul.homeFiletr li:nth-child(3) span {
  background: #e67136;
}

/**/
.bostns {
  position: relative;
  justify-content: end;
}
button.ShareProfile::after {
  display: none;
}
button.ShareProfile {
  background: #0053cd14;
  border: 0;
  font-size: 16px;
  font-weight: 600 !important;
  color: #0053cd;
  padding: 5px 10px !important;
  padding-right: 38px;
  height: 38px !important;
  border-radius: 8px;
  position: relative;
  min-width: 100px !important;
}
button.ShareProfile::before {
  content: "";
  position: absolute;
  right: 17px;
  top: 44%;
  width: 9px;
  height: 9px;
  border: 2px solid #0053cd;
  border-top: 0;
  border-right: 0;
  transform: translateY(-50%) rotate(-45deg);
}
.bostns ul {
  position: absolute;
  width: 165px;
  border: 1px solid #0053cd;
  border-radius: 10px;
  right: 0;
  top: 50px;
  background: #fff;
  padding: 0px !important;
}
.bostns ul li:hover {
  background-color: #3e97d33d;
}
.bostns ul li {
  width: 100%;
  margin-right: 0;
}
.bostns ul li a {
  display: flex;
  align-items: center;
  column-gap: 8px;
  text-decoration: none;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  padding: 7px 10px;
}
/**/
@media (max-width: 1200px) {
  .ContractTabs {
    /* padding: 1.4rem !important; */
  }
  .NamePro ul li {
    margin-right: 12px;
  }
}
@media (max-width: 991px) {
  .timsheetInner {
    padding: 15px;
  }

  .ProfileDetails {
    flex-wrap: wrap;
  }
  .topDashborad h3 {
    margin-bottom: 10px;
  }
  .timsheetInner {
    margin: 5px 0;
  }
  .ProfieDash {
    margin-top: 13px;
  }
  .ProfileLink {
    padding: 15px 12px;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
  }
  .ProfileLink a {
    width: 48.2%;
    margin: 0;
    text-align: center;
  }
  .ProfileLink button {
    margin-left: 0;
    width: 100%;
  }
  .Imagero img {
    max-width: 104px;
    min-height: 104px;
    margin-top: 0;
    margin-bottom: 10px;
  }
  .Imagero {
    /* margin-right: 12px; */
  }
  .ProfileDetails {
    padding: 19px 15px 0px;
  }
  .NamePro h6 {
    font-size: 13px;
    margin-top: 7px;
    margin-bottom: 1px;
  }
  .SocialProfile div {
    margin-top: 5px;
  }
  .SocialProfile label {
    font-size: 15px;
  }
  .SocialProfile div {
    flex-wrap: wrap;
  }
  .SocailBRn button {
    /* margin-right: 0;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 13px; */
  }
  .Mydashboard {
    padding: 20px 0;
  }
  .ContractTabs {
    /* padding: 20px 15px; */
    margin-bottom: 18px;
  }
  ul.tabList {
    /* flex-wrap: wrap; */
  }

  ul.tabList {
    border-radius: 10px;
  }
  ul.tabList li {
    font-size: 12px;
    padding: 10px 7px;
  }
}
@media (max-width: 575px) {
  .SocailBRn button {
    margin-bottom: 10px;
    width: 100%;
  }
  .SocailBRn {
    flex-direction: column;
  }
  ul.tabList li {
    font-size: 9px;
  }
}
@media (max-width: 480px) {
  ul.tabList li {
    font-size: 8px;
  }
}