.myfriendsshare{
    position: absolute;
    bottom: 5px;
    right: 10px;
}
.status{
    background: #0053CD;    
    padding: 5px 10px;
    color: #fff;
    font-size: 14px;
    border-radius: 18px 0 0 18px;
    text-transform: capitalize;
    position: absolute;
    right: 0;
}