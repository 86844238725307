/* Add this to your CSS file or style module */
.loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.7); /* Optional background overlay */
    z-index: 9999; /* Ensures loader appears on top of other elements */
  }
  
  .loadingText {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
  }
  .noResults {
    text-align: center;
    font-size: 1.5rem;
    color: #888; /* Adjust the color as needed */
    margin-top: 20px; /* Add some space */
  }
  /* YourComponent.module.css */
.datePos {
  position: absolute;
  z-index: 99;
  top: 31px;
  max-width: 320px;
  background: #ffffff; /* White background */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  border-radius: 5px; /* Rounded corners */
  padding: 10px; /* Inner padding */
}

.rdrCalendarWrapper {
  max-width: 100%;
}

.rdrCalendarWrapper .rdrNextPrevButton {
  width: 24px;
  height: 24px;
  padding: 0;
  border-radius: 5px;
  background: #eff2f7; /* Light background for buttons */
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
}

.applyButton {
  display: flex;
  justify-content: center;
  margin-top: 10px; /* Space above the button */
}

.applyButton button {
  background-color: #7747ff; /* Your theme color */
  color: white; /* Text color */
  padding: 10px 15px; /* Button padding */
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor */
  transition: background-color 0.3s; /* Transition effect */
}

.applyButton button:hover {
  background-color: #6532d7; /* Darker shade on hover */
}

/**/
