h3 {
    font-size: 27px;
    color: #141414;
    font-weight: 600;
}

.button {
    width: 30%;
}

.chatAdmin a img {
    display: inline-block;
    margin: 0 7px 0 0;
}

.standardContact.contarctBag {
    background: #4F46C71A;
    color: #4F46C7;
}

.readMore {
    color: #0f53cd;
    cursor: pointer;
    text-decoration: underline;
    display: inline-block;
    text-align: center;
    border-radius: 5px;
    margin-bottom: 15px;
}

.desblock p strong,
.desblock p {
    display: inline-block !important;
}
.desblock > p {
    /* height: 23px; */
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}
.standardContact.contarctBag img {
    display: inline-block;
    margin-right: 6px;
}

.desc p {
    display: block !important;
}

.invoiceBox {
    /* display: flex;
    column-gap: 8px; */
}

.chatAdmin a {
    font-size: 14px;
    color: #0053CD;
    text-decoration: none;
    font-weight: 500;
    align-items: center;
    border: 1px solid #0053CD;
    display: inline-block;
    border-radius: 5px;
    padding: 10px 17px;
}

.chatAdmin {
    margin-top: 16px;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 30px 17px;
    column-gap: 15px;
}

.adrssLoc {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

span.contarctBag {
    background: #BBFFBB80;
    padding: 2px 9px;
    border-radius: 4px;
    color: #1A7B1A;
    font-size: 13px;
    display: flex;
    align-items: center;
    column-gap: 5px;
    max-width: 170px;
    margin-bottom: 10px;
}

/* .ContractTabs {
    background: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0 0 20px 1px #dddddd42;
    padding: 35px;
} */
ul.tabList {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #BFBFBF;
    border-radius: 10px;
}

ul.tabList li {
    width: 100%;
    text-align: center;
    list-style: none;
    font-size: 20px;
    color: #00000073;
    font-weight: 600;

    padding: 13px 0;
    background: transparent;

}

ul.tabList li.active {
    background: #0053CD;
    color: #fff;
}

.AllContract {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
}

.AllContract h5 {
    font-size: 23px;
    color: #141414;
    font-weight: 600;
    margin: 40px 0 20px;
}

.AllContract a {
    font-size: 16px;
    color: #141414;
    font-weight: 600;
    text-decoration: none;
}

.AllContract a i {
    font-size: 14px;
    margin-left: 5px;
}

.OngoingContractDetails .ContarctOuter {
    max-width: 100%;
}

.Tagline p {
    text-transform: uppercase;
    font-size: 12px;
    position: relative;
    top: 8px;
    margin: 0;
}

.Hrrate2 {
    color: rgba(79, 70, 199, 1) !important;
    background-color: rgba(79, 70, 199, 0.1) !important;
}

.HrrateRed {
    color: rgba(230, 113, 54, 1) !important;
    background-color: rgba(230, 113, 54, 0.05) !important;
}


.ContarctOuter {
    overflow: hidden;
    border: 1px solid rgba(215, 216, 226, 1);
    border-radius: 10px;
    margin: 0;
    max-width: 100%;
    max-width: 1080px;
    margin: 30px auto;
    margin: 0;
    background: #fff;
}

.leftContract h5 {
    color: #000000;
    font-size: 17px;
    font-weight: 600;
    margin: 0;
    line-height: normal;
}

.leftContract span {
    font-size: 12px;
    color: #000000;
    font-weight: 400;
    line-height: 0;
}

.ContarctHead {
    display: flex;
    align-items: center;
    background: #F5F7FD;
    border-radius: 10px 10px 0 0;
    padding: 16px 3px;
    justify-content: space-between;
}

.rightName h6 {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #1A7B1A;
    background: #BBFFBB80;
    margin: 0 auto 8px;
    text-align: center;
    justify-content: center;
    padding: 6px 0;
    border-radius: 3px;
    max-width: 193px;
}

.rightName h6 img {
    margin-right: 4px;
}

.leftContract button:nth-child(1) {
    margin-left: 0 !important;
}

.leftContract button {
    background: #fff;
    border: 0;
    border-radius: 4px;
    margin-left: 7px;
    padding: 4px 12px;
    font-size: 13px;
    color: #07B638;
    font-weight: 400;
    border: 1px solid #07B638;
}

.leftContract button.CoptText {
    background: #0e53cd;
    color: #fff;
    border: 0;
}

.OngoingContractDetails .ContarctOuter,
.OngoingContractDetails .ContarctHead {
    background-color: #fff;
}

.outerNameDetail {
    padding: 16px 15px;
    /* border-bottom: 1px solid #D7D8E2; */
    height: 100%;
}

.outerNameDetail .outerNDetail .rightName:before {
    display: none;
}

.OngoingContractDetails .leftName {
    background: #F8FAFE;
    padding: 15px;
    border-radius: 12px;
}

.leftName h4 {
    font-size: 18px;
    color: #000000;
    font-weight: 500;
    /* margin-bottom: 24px; */
    margin-bottom: 34px;
}

.OngoingContractDetails .PrileImg {
    align-items: flex-start;
}

.PrileImg {
    display: flex;
    align-items: center;
}

.OngoingContractDetails .PrileImg span img {
    border-radius: 12px;
    border: 0;
}

.PrileImg span img {
    max-width: 60px;
    max-height: 60px;
    border-radius: 100px;
    border: 2px solid #0f53cd;
    min-height: 60px;
    min-width: 60px;
    object-fit: cover;

    margin: 0 auto;
    object-position: top;
}

.PrileImg span {
    /* margin-right: 15px; */
}

.PrileImg h5 {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    color: #000000;
}

.OngoingContractDetails .PrileImg h6 {
    color: #000;
}

.profileView a {
    background: #0053CD1A;
    font-size: 10px;
    text-decoration: none;
    padding: 6px 10px;
    border-radius: 20px;
    display: inline-block;
    position: relative;
    left: -8px;
    margin-top: 8px;
}

.profileView {
    padding-right: 14px;
}

.PrileImg h6 {
    color: #525252;
    font-size: 14px !important;
    font-weight: 400 !important;
    margin: 3px 0 !important;
    padding-right: inherit !important;
    /* display: flex; */
    align-items: center;
}

.PrileImg h6 img {
    margin-left: 4px;
}

.PrileImg p i {
    display: inline-block;
}

.PrileImg p {
    /* display: inline-block; */
    align-items: center;
    margin: 0;
    color: #0053CD;
    font-size: 14px;
    font-weight: 500;
    position: relative;
    /* padding-left: 19px; */
}

.PrileImg p img {
    height: 14px;
    margin-right: 5px;
    object-fit: contain;
}

.OngoingContractDetails .rightName p::before {
    position: absolute;
    left: 0;
    content: "";
    width: 4px;
    height: 4px;
    background-color: #000;
    border-radius: 50%;
}

.OngoingContractDetails .rightName p {
    color: #000;
    position: relative;
    padding-left: 15px;
}

.OngoingContractDetails .rightName h5 {
    text-align: left;
}

.OngoingContractDetails .rightName::before {
    display: none;
}

.RightOpportunity .ContarctOuter {
    max-width: 100%;
}

.rightName h5 {
    font-size: 18px;
    text-align: right;
    font-weight: 500;
    /* margin: 9px 0 17px; */
    margin: 0 0 34px;
}

.rightName p {
    font-size: 13px;
    /* margin: 0 0 2px; */
    margin: 0 0 2px 0px;
    font-weight: 400;
    color: #000000CC;
}

.rightName::before {
    background: #D7D8E2;
    position: absolute;
    content: "";
    width: 1px;
    height: calc(100% - 20px);
    bottom: 1px;
    top: 0;
    bottom: 0;
    margin: auto;
    left: -12px;
}

.rightName {
    position: relative;
    /* height: 100%; */
    /* height : 180px; */
}

.timeSheets {
    padding: 16px 15px;
}

.timeSheets .col-md-3.col-6 {
    width: 50%;
}

.timesheetData {
    background: transparent;
    padding: 12px 15px;
    border-radius: 9px;
    margin-bottom: 0px;
    height: 100%;
    border: 1px solid #0053CD66;
}

.timesheetData ul li label {
    font-size: 14px;
}

.timesheetData ul li {
    font-size: 14px;
    color: #000000;
    font-weight: 500;
    list-style: none;
    margin-top: 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.timesheetData ul li.red label {
    color: #FF424D;
}

.timesheetData ul li.red {
    color: #FF424D;
}

.timesheetData ul li.yeloo label {
    color: #FFC053;
}

.timesheetData ul li.yeloo {
    color: #FFC053;
}

.timesheetData ul li.gren label {
    color: #2BC155;
}

.timesheetData ul li.gren {
    color: #2BC155;
}

.timesheetData ul {
    padding: 0;
    margin: 0;
}

.timesheetData h4 {
    font-size: 20px;
    color: #000;
    margin-bottom: 10px;
}

.Horcontract {
    background: #0053CD17;
    border-radius: 11px;
    padding: 17px 19px;
    font-size: 14px;
    font-weight: 600;
    margin: 5px 0;
}

.Horcontract span {
    font-size: 22px;
    font-weight: 600;
    padding-left: 18px;
}

.MainLink a {
    border: 1px solid #0053CD;
    padding: 12px 15px;
    width: 50%;
    border-radius: 8px;
    text-align: center;
    text-decoration: none;
    font-weight: 500;
    color: #0053CD;
}

.MainLink button {
    border: 1px solid #0053CD;
    padding: 12px 15px;
    width: 50%;
    border-radius: 8px;
    text-align: center;
    text-decoration: none;
    font-weight: 500;
    color: #0053CD;
    background: #0053CD;
    color: #fff;
    margin-left: 11px;
}

.rejected.Horcontract {
    background: #FFD8D8;
}

.Horcontract h6 {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    color: #000;
    display: flex;
    align-items: center;
}

.Horcontract h6 img {
    margin-right: 4px;
}

.MainLink {
    margin-top: 17px;
    margin-bottom: 11px;
    display: flex;
}

.VideoCallBtnreject .VideoCallBtn {
    gap: 20px;
}

.VideoCallBtnreject .VideoCallBtn button:nth-child(1),
.VideoCallBtnreject .VideoCallBtn button:nth-child(2) {
    /* width: 50%; */
}

.VideoCallBtnreject .VideoCallBtn button:nth-child(3) {
    /* width: 50%; */
}


.VideoCallBtn button {
    background: #0053CD;
    padding: 12px 20px;
    font-size: 14px;
    border: 0;
    width: 48%;
    /* margin: 0 8px 8px; */
    border-radius: 8px;
    color: #fff;
}

.VideoCallBtn button i {
    margin-right: 4px;
}

.VideoCallBtn button.videBtn {
    background: #0053CD;
    color: #fff;
}

.VideoCallBtn {
    display: flex;
    padding: 26px 20px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.Infor p {
    display: flex;
    align-items: flex-start;
    color: #000000CC;
    font-weight: 400;
}

.Infor p img {
    margin-right: 6px;
}

.Infor {
    padding: 7px 20px;
    display: flex;
}

.leftContract .shedule {
    background: #ffffff;
}

.leftContract .copy {
    background: #0053CD;
    color: #fff;
}

.leftContract .contractbtn {
    background: #0053CD;
    color: #fff;
    border-radius: 5px;
}

.CompletInerTime {
    background: #F5F7FD;
    padding: 17px 23px;
    margin: 7px 0;
}

.CompTimeheet {
    padding: 17px 20px;
}

.CompletInerTime h5 {
    font-size: 23px;
    color: #000000;
    font-weight: 600;
    margin-bottom: 13px;
}

.CompletInerTime h6 {
    font-size: 15px;
    color: #2BC155;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.leftContract .complete {
    background: #04F673;
}

.PendingCOnatctList {
    border: 1px solid rgba(215, 216, 226, 1);
    border-radius: 10px;
    padding: 15px 16px;
    /* min-height: 350px; */
    /* margin-bottom: 30px; */
}

.OuterPadd {
    padding: 12px 10px;
    background: #fff;
}


.Local p {
    display: flex;
    align-items: center;
    font-size: 15px;
    margin: 0 0 8px;
}

.Local span {
    display: flex;
    align-items: center;
    font-size: 12px;
    margin: 0 0 8px;
    padding: 5px;
}

/* .Local p img{
    margin-right: 8px;
} */

.Local img {
    margin-right: 3px;
    max-width: 16px;
}

/* .Local span img{
    max-width: 16px;
    margin-right: 8px;
} */


.PendingCOnatctList h6 {

    font-size: 14px;
    font-weight: 600;
    margin-bottom: 7px;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */

}

.PendingCOnatctList h6 span.boosted {
    border-radius: 100px !important;
    background: #E67136 !important;
    margin-left: 20px;
    justify-self: unset;
    text-transform: capitalize;

}

.PendingCOnatctList h6 span {
    background: #0e53cd;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    padding: 6px 12px;
    border-radius: 100px;
}

.FondOpportuniy .opprBox .active.PendingCOnatctList {
    border: 1px solid #0053CD;
    border-radius: 8px;
    overflow: hidden;
}

.FondOpportuniy .PendingCOnatctList h6 span {
    background: #0053CD;
    border-radius: 0px 0 0 5px;
}

.PenigSkil h5,
.PenigSkilRate h5 {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.80);
}

.PenigSkil ul {
    padding: 0;
    margin: 0 0 18px;
    display: flex;
}

.PenigSkil ul li {
    margin-right: 30px;
}

.PenigSkil ul li p {
    background: #F5F7FD;
    border-radius: 100px;
    font-size: 14px;
    color: #000;
    padding: 6px 22px;
    margin-right: 6px;
    width: max-content;
}

.PenigSkil ul li p {
    margin-bottom: 0;
}

.PenigSkilRate ul {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    gap: 60px;
}

.PenigSkilRate.fullcontractSkilRate ul li {
    min-width: 22%;
    width: auto;
}

.PenigSkilRate ul li {
    width: auto;
}

.PenigSkilRate ul li label {
    font-size: 18px;
    color: #0053CD;
    font-weight: 600;
}

.Peras,
.Peras p {
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
}

.PenigSkilRate ul li span {
    font-size: 14px;
    color: #0E0E0ECC;
    font-weight: 500;
    width: 100%;
    display: table;
}

.RightFilyter button {
    background: #0f53cd;
    border: 0;
    padding: 7px 14px;
    border-radius: 5px;
    color: #fff;
    font-weight: 500;
}

.RightFilyter .FormGroup select {
    font-size: 15px;
    border: 0;
    color: #222B45;
    font-weight: 500;
}

.RightFilyter .FormGroup span {
    color: #96A5B8;
    font-size: 15px;
    margin-right: 6px;
}

.RightFilyter {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.RightFilyter .FormGroup {
    margin-right: 23px;
}

.SearchData {
    background: #fff;
    border: 1px solid #E4E5E8;
    display: flex;
    border-radius: 6px;
    align-items: center;
    justify-content: space-between;
}

.SearchData .FormGroup:first-child {
    width: 60%;
    border-right: 1px solid #E4E5E8;
}

.SearchData .FormGroup .textwhite input::placeholder {
    color: #fff !important;
    font-weight: 500;
    text-align: center;
}

.SearchData .FormGroup {
    padding: 10px 10px 10px 10px;
    display: flex;
    position: relative;
    align-items: center;
    width: 40%;
}

.SearchData .FormGroup input {
    border: 0;
    outline: none;
    width: 100%;
}

.SearchData .FormGroup img {
    margin-right: 6px;
    height: 20px;
    object-fit: contain;
}

.SearchData .FormGroup input[type="submit"] {
    background: #0053CD;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    padding: 16px 38px;
    border-radius: 6px;
}

.SearchData .FormGroup:nth-child(2) {
    width: 52%;
}

.SeachIcgps {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 3px;
}


.FilerData li .basic-multi-select,
.FilerData li span {
    font-size: 14px;
    border: 1px solid #fff;
    background: #fff;
    padding: 10px 25px;
    border-radius: 100px;
    margin-right: 10px;
    height: 40px;
}

.FilerData ul {
    padding: 0;
    margin: 0;
    display: flex;
}

.FilerData ul li {
    float: left;
    display: flex;
}

.FilerData {
    margin-top: 25px;
}

.FondOpportuniy {
    padding: 50px 0;
}

.FilerData ul li button {
    font-size: 16px !important;
    border: 1px solid #0f53cd;
    background: #0e53cd;
    padding: 12px 25px;
    border-radius: 100px;
    margin-left: 20px;
    color: #fff;
    font-weight: 500;
    padding: 12px 25px;
    position: relative;
    height: 40px;
    gap: 5px;
    display: flex;
    align-items: center;
}

.FilerData ul li button::after {
    content: '';
    position: absolute;
    left: -15px;
    top: 0;
    height: 100%;
    border: 1px solid rgba(228, 229, 232, 1);
}

.FilerData ul li button img {
    margin-right: 3px;
}

.opprBox .PendingCOnatctList {
    background: #fff;
    padding: 15px;
    /* min-height: 150vh; */
}

span.Hrrate {
    background: #BBFFBB80;
    padding: 4px 10px;
    border-radius: 4px;
    font-size: 14px;
    margin-bottom: 15px;
    color: #1A7B1A;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    gap: 5px;
}

.opprBox .PenigSkil {
    /* margin-top: 6px; */
    /* border-top: 0; */
    /* border-bottom: 1px solid #ddd;
    margin-bottom: 14px;
    padding-bottom: 16px; */
}

.opprBox .PenigSkil ul li {
    font-size: 14px;
    padding: 7px 10px 0 0;
    /* height: 33px; */
    /* display: flex;
    align-items: center;
    justify-content: center; */
}

.ContarctOuter {
    /* margin-bottom: 15px !important; */
    background: #fff;
}

.OtherDetails ul li i {
    background: #0053CD;
    padding: 14px;
    border-radius: 100px;
    color: #fff;
    margin-top: 5px;
}

.downPdf {
    display: flex;
    align-items: center;
}

.SoftWareLicenece {
    display: flex;
    column-gap: 26px;
}

.PenigSkilRate {
    padding-top: 17px;
}

.softarName {
    background: transparent;
    padding: 0;
    color: #000;
    position: relative;
    top: 5px;
    text-transform: uppercase;
    font-size: 16px;
}

label.softawreTag {
    margin: -12px 0 0;
    display: table;
    font-size: 17px !important;
    color: #000;
    font-weight: 600 !important;
}




.PendingCOnatctList .PendingCOnatctList {
    border: 0;
    padding: 0;
}

.PendingCOnatctList .outerNameDetail {
    height: auto;
}

/* .SoftWareLicenece div {
    margin-bottom: 15px;
    display: table;
} */

.SoftWareLicenece .secuityTag {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.SoftWareLicenece div label {
    font-size: 11px;
    font-weight: 600;
}

.OtherDetailsOption ul {
    flex-wrap: wrap;
    max-width: 60%;
    gap: 0;
    row-gap: 20px;
}

.OtherDetailsOption ul li {
    width: 24.33%;
}




.Tagline label {
    font-size: 14px;
    color: #0E0E0E;
    position: relative;
}

.Tagline label {
    font-size: 14px;
    color: #0E0E0E;
    position: relative;
    margin-left: 30px;
    display: flex;
    align-items: center;
}

.Tagline label span {
    padding: 0;
    width: 16px;
    height: 16px;
    border-radius: 0;
    background: #6ADB6A;
}

.keyMap {
    background: #0053cd0a;
    padding: 10px;
    border-radius: 10px;
}

.Tagline label:last-child span {
    background: #0053CD;
}

.Tagline {
    display: flex;
    color: #0053CD;
}

.OtherDetails {
    border-top: 1px solid #ddd;
    margin: 14px 0;
    padding: 16px 0;
}

.OtherDetails h5 {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    color: #000;
}

.OtherDetails ul {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
}

.OtherDetails ul li {
    width: 34.33%;
    margin-bottom: 18px;
    display: flex;
}

.OtherDetails ul li span {
    padding-right: 10px;
}

.OtherDetails ul li div span {
    color: #0E0E0E;
    font-size: 15px;
    width: 100%;
    display: table;
    font-weight: 600;
}

.OtherDetails ul li div {
    line-height: 1.2;
}

.OtherDetails ul li div label {
    font-size: 13px;
    color: #0E0E0E;
    font-weight: 400;
}

.OtherDetails ul li:nth-last-child(2) {
    margin: 0;
}

.OtherDetails ul li:last-child {
    margin: 0;
}

.borderadd {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding-top: 23px;
}

.ApplyBtn {
    display: flex;
    align-items: center;
    border-top: 1px solid #ddd;
    padding-top: 22px;
    padding-bottom: 13px;
    padding: 24px 19px;
    justify-content: flex-end;
}

.clientProjectInner {
    background: #F5F7FD;
    border: 1px solid #0e53cd;
    border-radius: 7px;
    padding: 18px 14px;
    min-height: 134px;
}

.clientProjectInner h5 {
    font-size: 18px;
    font-weight: 600;
}

.clientProjectInner span {
    font-size: 30px;
    font-weight: 700;
    color: #2BC155;
    margin: 0;
}

.ClientProjects {
    border-top: 1px solid #ddd;
    padding-top: 36px;
}

.clientProjectInner.RejectedAppl {
    background: #FFE7E7;
    border: 1px solid #FF0000;

}

.clientProjectInner.RejectedAppl span {
    color: #FF0000;
}

.OpenContactOuter .OuterSlides {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    flex-wrap: wrap;
}

.OpenContactOuter .ContarctOuter {
    width: 49%;
    margin-bottom: 15px;
}

.commonNav ul {
    padding: 0;
    margin: 50px 0 0;
    display: flex;
    align-items: center;
}

.commonNav ul li.active span {
    font-weight: 700;
    position: relative;
}

.commonNav ul li {
    margin-right: 30px;
    cursor: pointer;
}


.OpenContactData p {
    display: flex;
    align-items: center;
    font-size: 15px;
    color: #0E0E0E;
    font-weight: 400;
    margin: 8px 0;
}

.OpenContactData p img {
    max-width: 16px;
    margin-right: 7px;
}

.OpenContactData {
    border: 1px solid #D7D8E2;
    border-radius: 10px;
    padding: 15px 16px;
}

span.switchBtn {
    border: 1px solid #0053CD;
    border-radius: 100px;
    background: #fff;
    padding: 5px 10px;
    margin-left: 8px;
}

.OpenContactData h6 {
    font-size: 17px;
    font-weight: 600;
    margin: 15px 0;
}

.RightContract {
    display: flex;
    align-items: center;
}

span.switchBtn div input {
    float: right !important;
    margin-left: auto !important;
    margin-right: -2.6em !important;
}

span.switchBtn label {
    padding-left: 0;
    font-size: 14px;
    font-weight: 500;
}

span.switchBtn div {
    padding-left: 0;
    padding-right: 38px;
}

.InnrRecveContarct {
    background: #F5F7FD;
    border-radius: 9px;
    padding: 13px 16px;
    margin-top: 14px;
}

.InnrRecveContarct h5 {
    font-size: 14px;
    color: #000000;
    font-weight: 600;
}

.InnrRecveContarct span {
    font-size: 21px;
    font-weight: 700;
    color: #2BC155;
}

.ApplyBTN button {
    background: #0053CD;
    width: 100%;
    border: 0;
    padding: 10px 0;
    color: #fff;
    border-radius: 6px;
    margin-top: 17px;
}

.InnrRecveContarct.RedBox {
    background: #FFE7E7;

}

.InnrRecveContarct.RedBox span {
    color: #FF0000;

}

.commonNav ul li.active span::before {
    width: 80%;
    height: 2px;
    background: #000;
    border-radius: 10px;
    content: "";
    position: absolute;
    left: 0;
    bottom: -7px;
}

.timeSheets .col-md-3 {
    width: 50%;
}

.ExtraPadding .SoftWareLicenece {
    /* padding-bottom: 40px; */
}

.ExtraPadding {
    padding: 20px 0 20px;
}

.worklogo {
    max-width: 100px;
    border-radius: 100px;
}

.textstatuscompleted {
    position: relative;
    margin-top: 20px;
}

.textstatuscompleted span {
    position: absolute;
    background: #09AF09;
    right: -18px;
    top: -37px;
    padding: 4px 10px;
    color: #fff;
    border-radius: 0px 0 0 10px;
    font-size: 14px !important;
}



ul.homeFiletr li span {
    background: #2BC155;
    display: flex;
    padding: 10px 50px;
    color: #fff;
    border-radius: 8px;
    font-weight: 600;
}

ul.homeFiletr li {
    margin: 0 8px;
}

ul.homeFiletr li:nth-child(4) span {
    background: #f3000e;
}

ul.homeFiletr {
    display: flex;
    justify-content: center;
    margin: 32px 0 0;
    padding: 0;
}

ul.homeFiletr li:nth-child(2) span {
    background: #E6A90D;
}

ul.homeFiletr li:nth-child(3) span {
    background: #70D4FF;
}

/* right side */
.name_robo {
    font-size: 22px !important;
    font-weight: 600 !important;
    margin-bottom: 10px;
}

.Locate {
    display: flex;
    align-items: center;
    color: rgba(14, 14, 14, 1);
    font-size: 14px;
    font-weight: 400;
    gap: 5px;
    text-transform: capitalize;

}

.Taglines {
    justify-content: end;
}

ul {
    padding-left: 0px !important;
}

.PenigSkilRate22 h5 {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 7px;
}

.TaglinesButton {
    gap: 8px;
}

.TaglinesButton li span {
    height: 33px;
    padding: 5px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 300;
    color: #fff;
    margin-bottom: 5px;
    border-radius: 20px;
}

.orangeButtons {
    background-color: rgba(255, 138, 0, 1) !important;
}

.greenButtons {
    background-color: #6ADB6A !important;
}

.blueButtons {
    background-color: #0053CD !important;
}

.Taglines li {
    font-size: 14px;
    color: #0E0E0E;
    line-height: 21px;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 5px;
}

.Taglines {
    gap: 22px;
}

.Taglines li .greenBOx {
    height: 16px;
    width: 16px;
    background-color: #6ADB6A;
}

.Taglines li .blueBOx {
    height: 16px;
    width: 16px;
    background-color: #0053CD;
}

.bordeerBottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 13px;
    margin-bottom: 13px;
}

.RightOpportunity .PendingCOnatctList {
    padding: 20px 25px;
}

.payrate {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-weight: 600;
}

.datesFlex p,
.datesFlex h6:nth-of-type(1) {
    font-weight: 700;
    margin-bottom: 2px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.7);
}

.datesFlex p,
.datesFlex h6 {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: #0E0E0E;
    margin-bottom: 15px;
}

.datesFlex {
    padding-left: 20px;
    position: relative;
    margin-bottom: 15px;

}

.datesFlex i {
    color: #0053CD;

    font-size: 14px;
    position: absolute;
    left: 0px;
    top: 4px;
}

.datesFlex p {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #0E0E0E;

}

.dollar {
    font-size: 18px;
    font-weight: 500;
    color: #0053CD;
    line-height: 21px;
}

.datesFlex2 h6 {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;

}

.commonbtn {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 14px !important;
    font-weight: 500;
    margin: 7px 0px;
    border: 1px solid #0053CD !important;
    padding: 5px 25px !important;
    border-radius: 4px;
}

.applybtn {
    background-color: #0053CD !important;
    color: #fff !important;
    width: 139px;

}

.sharebtn {
    background-color: #fff !important;
    color: #0053CD !important;
    width: 139px;
}

.flexbtns {
    display: flex;
    gap: 20px;
}

.mapView {
    width: 100%;
    height: auto;
    min-height: 575px;
}

.draftCity {
    border: 1px solid #ddd;
    width: 100%;
    max-width: 95%;
    margin: 13px auto;
    border-radius: 10px;
    padding: 15px 16px;
}

.rightFilter {
    display: flex;
    column-gap: 22px;
}

.gratCtyLoc div {
    /* width: 50%; */
}

.rightFilter .formGroup select {
    border: 0;
    outline: none;
    font-size: 14px;
}

.rightFilter .formGroup span {
    color: #96A5B8;
    font-size: 15px;
    padding-right: 7px;
}

.gratCtyLoc div p {
    font-size: 14px;
    margin: 0 0 6px;
    display: flex;
    align-items: center;
}

.gratCtyLoc div p img {
    max-height: 16px;
    margin-right: 5px;
}

.gratCtyLoc {
    display: flex;
    align-items: center;
}

.gratCtyLoc span {
    background: #BBFFBB80;
    margin-left: auto;
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding: 5px 14px;
}

.gratCtyLoc span img {
    margin-right: 5px;
}

.draftCity h6 {
    font-size: 17px;
    font-weight: 600;
    margin: 12px 0 16px;
}

.draftSkills h5,
.draftPayrate h5 {
    font-size: 15px;
    font-weight: 600;
    margin: 0 0 11px;
    text-transform: uppercase;
}

.MainLink button.edit {
    background: #F0F0F0;
    border: 0;
    color: #000;
    width: 30%;
}

.draftSkills ul {
    display: flex;
    flex-wrap: wrap;
    column-gap: 14px;
}

.draftSkills ul li span {
    background: #F5F7FD;
    font-size: 14px;
    padding: 7px 13px;
    border-radius: 100px;
    display: inline-block;
}

.draftPayrate ul {
    display: flex;
}

.draftPayrate ul li {
    width: 33.33%;
    text-align: left;
}

.draftPayrate ul li span {
    font-size: 14px;
    font-weight: 500;
    width: 100%;
    display: table;
}

.draftPayrate ul li label {
    color: #0053CD;
    font-size: 18px;
    font-weight: 500;
}

.disc {
    padding: 0 20px;
    border-bottom: 1px solid #D7D8E2;
    margin-bottom: 11px;
}

.disc h4 {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.5;
    margin-bottom: 16px;
}

.disc p {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 0;
    margin-bottom: 30px;
    padding-right: 10px;
}

.detailsInfo .shedule {
    background-color: #0053CD;
    border-color: #0053CD;
    color: #fff;
    text-transform: capitalize;
}

.detailsInfo {
    background-color: #fff;
    padding: 25px 0;
}

.headingBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.headingBar ul {
    display: flex;
    margin-bottom: 0;
}

.headingBar ul li a {
    display: inline-block;
    color: #222B45;
    text-decoration: none;
    font-size: 17px;
    font-weight: 600;
    padding: 0 10px;
}

.headingBar ul li:first-child a {
    padding-left: 0;
}

.headingBar ul li:last-child a {
    padding-right: 0;
}

.headingBar ul li.activeList a {
    color: #0053CD;
}

.headingBar button {
    display: inline-block;
    background-color: #fff;
    border: 1px solid #7B91B01A;
    padding: 9px;
    border-radius: 7px;
    font-size: 16px;
    line-height: 1.5;
}

.headingBar button i {
    padding-right: 5px;
}

.invoiceBoxInner h4 {
    font-size: 13px;
    font-weight: 600;
    max-width: 90%;
    min-height: 35px;
}

.invoiceBoxInner {
    background: #F5F7FD;
    border-radius: 8px;
    padding: 10px;
    /* width: 20%; */
}

.invoiceBoxInner h5 {
    margin: 0;
    color: #2BC155;
    font-size: 21px;
    font-weight: 600;
}

.PendingCOnatctList {
    position: relative;
}

.PendingCOnatctList .worktype {
    position: absolute;
    right: 0;
    top: 0;
    background: #6532d7;
}

.trevelexpense {
    margin-top: 20px;
}

.trevelexpense p {
    font-size: 14px !important;
}

.trevelexpense h6 {
    font-weight: 600;
    font-size: 12px;
}

.tabingnav {
    /* border: 1px solid #0053CD; */
    border-radius: 5px;
    overflow: hidden;
}

/**/
.statusdetails {
    right: -10px;
    border-radius: 22px 0px 0 22px !important;
    border-right: 0 !important;
    border-color: #07B63833 !important;
    background: #07B63833 !important;
    color: #07B638 !important;


}

.draftstatusdetails {
    right: -10px;
    border-radius: 22px 0px 0 22px !important;
    border-right: 0 !important;
    border-color: #FFF4E3 !important;
    background: #FFF4E3 !important;
    color: #FF990B !important;


}

/**/
.closejob {
    color: #F30C0C;
    border: 1px solid #F30C0C;
}

/**/
.paymentmenthods .selctCard {
    background-color: #fff;
}

.paymentmenthods .selctCard.active {
    border: 1px solid #000;
    background-color: #fff;
}

.paymentmenthods .selctCard.disabled {
    background-color: #FAFAFA;
    border: 1px solid #fafafa;
}

/**/

/**/

@media (max-width: 1440px) {
    .leftContract button {
        padding: 4px 10px;
    }

    .timesheetData h4 {
        font-size: 19px;
    }
}

@media (max-width: 1200px) {
    .ContractTabs {
        padding: 0;
    }

    .PenigSkilRate ul {
        gap: 25px;
    }

    .invoiceBox>div {
        max-width: 33.33%;
        flex: auto;
    }
}

@media (max-width: 991px) {
    .SearchData .FormGroup:nth-child(2) {
        width: 100%;
    }

    .SeachIcgps {
        /* top: 9px; */
    }

    .SearchData .FormGroup input[type="submit"] {
        padding: 6px 38px;
    }

    .textstatuscompleted span {
        right: -9px;
        top: -30px;
    }

    .FilerData ul {
        flex-wrap: wrap;
        gap: 15px;
    }

    .FilerData li span {
        margin-right: 0;
        padding: 8px 15px;
        display: flex;
        align-items: center;
    }

    .invoiceBox {
        /* display: flex; */
        /* column-gap: 10px; */
    }

    .SearchData {
        flex-direction: column;
    }

    .SearchData .FormGroup:first-child {
        width: 100%;
    }

    .PrileImg span img {
        max-width: 60px;
        max-height: 60px;
        min-height: 60px;
        min-width: 60px;
    }
    .SearchData .FormGroup{
        width: 100% !important;
    }
}

@media (max-width:767px) {

    .PenigSkil h5,
    .PenigSkilRate h5 {
        font-size: 13px;
    }

    .invoiceBoxInner h4 {
        font-size: 12px;
    }

    .FilerData ul {
        gap: 6px;
    }

    .AllContract {
        flex-wrap: wrap;
    }

    .ContractTabs {
        padding: 0px;
    }

    .AllContract {
        margin: 0;
    }

    .AllContract h5 {
        font-size: 19px;
        margin: 10px 0 10px;
    }

    .RightFilyter {
        flex-wrap: wrap;
        row-gap: 8px;
        margin-bottom: 15px;
    }

    .RightFilyter .FormGroup {
        margin-right: 0;
        width: 33.33%;
    }

    .RightFilyter .FormGroup:nth-child(2) {
        padding-left: 20px;
    }

    .ContarctHead {
        /* padding: 15px 13px; */
        justify-content: space-between;
        flex-direction: column;
        align-items: flex-start;
    }

    .leftContract button {
        /* margin-left: 0; */
    }

    .leftContract {
        /* gap: 5px;
        display: flex;
        flex-wrap: wrap; */
    }

    .leftContract span {
        font-size: 13px;
        line-height: 1;
        margin: 3px 0 12px;
    }

    .PendingCOnatctList {
        padding: 13px;
    }

    .OuterPadd {
        padding: 11px;
    }

    .adrssLoc {
        /* align-items: flex-start; */
        /* flex-direction: column; */
    }

    .PenigSkilRate {
        padding-top: 0;
    }
    .PenigSkil h5, .PenigSkilRate h5{
        font-size: 12px;
    }
    .PenigSkilRate ul li label{
        font-size: 14px;
    }
    .PenigSkilRate ul li span{
        font-size: 12px;
    }

    .PenigSkilRate ul {
        gap: 10px;
    }

    .chatAdmin a {
        font-size: 13px;
        padding: 8px 12px;
    }

    .AllContract a {
        font-size: 13px;
        position: relative;
        top: -2px;
    }

    .ContarctOuter {
        margin: 0px 0px 15px;
    }

    .leftName h4 {
        margin-bottom: 15px;
    }

    .rightName h6 {
        max-width: 100%;
        margin: 12px 0 10px;
    }

    .rightName::before {
        display: none;
    }

    .rightName p {
        margin: 0 0 2px 0px;
    }

    .VideoCallBtn button {
        padding: 11px 4px;
        font-size: 13px;
        width: 47%;
        margin: 0 4px 8px;
    }

    .VideoCallBtn {
        padding: 16px 10px;
    }

    .Infor {
        padding: 7px 13px;
    }

    .Infor p {
        font-size: 13px;
    }

}

@media (max-width: 575px) {
    .RightFilyter .FormGroup {
        margin-right: 0;
        width: 50%;
    }

    .leftContract button,
    .leftContract span {
        font-size: 12px !important;
    }
}