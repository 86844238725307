.selctCard {
    padding: 0px 15px 0;
    height: 60px;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}

.selctCard {
    background: #FAFAFA;
}

.selctCard h6 {
    margin: 0;
    display: flex;
    align-items: center;
    column-gap: 8px;
    position: relative;
    width: 100%;
    color: #000;
    font-weight: 600;
    font-size: 18px;
}

.selctCard:nth-of-type(2) h6 {
    align-items: flex-start;
}

.selctCard:nth-of-type(2) h6 label {
    top: 21px;
}

.selctCard h6 label {
    width: 100%;
    position: absolute;
    left: 51px;
    top: 27px;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
}

/**/

.paymentForm button {
    height: 48px;
    width: 100%;
    background: #0053CD;
    color: #fff;
    border: 0;
    border-radius: 10px;
    margin-top: 19px;
}

.paymentForm {
    border-left: 1px solid #ddd;
    padding-left: 35px;
    height: 100%;
}
.paymentBox {
    background: #F3F8FF;
    border-radius: 20px;
    padding: 24px;
}

.paymentBox ul li:last-child {
    font-weight: bold;
    color: #000;
    border-top: 1px dashed #979797;
    padding-top: 17px;
    margin-top: 15px;
}

.paymentBox h6 {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
}

.paymentBox ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #8890A0;
    padding: 4px 0;
}

.paymentBox label {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 14px;
}


.paymentForm h5 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 18px;
}

/**/
.paymentForm h5 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 18px;
}