.pageContainer {
    display: flex;
    flex-wrap: wrap;
    background: #F8FAFE;
}

.leftContainer {
    width: 16%;
}

.rightContainer {
    width: 100%;
    padding: 0;
}

.pageOuter {
    padding: 40px 0;
}

.graphPage img {
    width: 100%;
}

.graphPage {
    margin-bottom: 27px;
}

.timeShetet {
    background: #0053CD;
    border-radius: 10px;
    padding: 28px;
    display: flex;
    align-items: center;
}

.timeShetet span {
    border: 1px solid #FFFFFF;
    width: 80px;
    height: 80px;
    border-radius: 10px;
    margin-right: 14px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.rightTime h6 {
    font-size: 19px;
    color: #fff;
    margin-bottom: 13px;
}

.rightTime h5 {
    font-size: 48px;
    color: #fff;
    font-weight: 800;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.rightTime h5 label {
    font-size: 13px;
}

.rightTime {
    width: 62%;
}

.secTimesheet {
    background: #48A9F8;
}

.thirdTimesheet {
    background: #1BD084;
}

.rightContainer h2 {
    font-size: 30px;
    font-weight: 600;
    margin: 0 0 23px;
}

.OuterTime {
    padding-bottom: 25px;
}

.revenueBox {
    background: #fff;
    border: 1px solid #EFEFEF;
    border-radius: 10px;
    padding: 25px;
}

.revenueBoxHead {
    display: flex;
    justify-content: space-between;
    min-height: 50px;
    align-items: center;
}

.revenueBoxHead h6 {
    margin: 0;
    font-size: 16px;
    color: #141414;
}

.revenueBoxHead span {
    font-size: 12px;
    color: #1DBF73;
    border: 1px solid;
    padding: 4px 8px;
    border-radius: 22px;
    background: #8fe7be1f;
}

.revenueBoxFoter h4 span {
    color: #989CA0;
}

.revenueBoxFoter span {
    color: #0053CD;
    font-weight: 600;
}

.revenueBoxFoter h4 {
    font-size: 13px;
    margin: 0;
}

.revenueBoxFoter {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.revenueBox h5 {
    font-size: 26px;
    font-weight: 800;
}

.baseInfo {
    background: #fff;
    padding: 30px;
    border: 1px solid #D7D8E2;
    border-radius: 20px;
}

.baseinfoHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.baseinfoHead h5 {
    font-size: 16px;
    color: #141414;
    font-weight: 700;
    margin: 0;
}

.baseinfoHead button {
    font-size: 14px;
    background: #0053CD;
    color: #fff;
    padding: 8px 13px;
    border-radius: 5px;
    border: 0;
}

.baseinfoHead button img {
    margin-right: 4px;
}

.innerbaseinfo {
    background: #F8FAFE;
    border-radius: 10px;
    padding: 20px 60px;
    margin: 24px 0 0;
    display: flex;
}

.leftInfo h6 {
    font-size: 16px;
    color: #2E2E2E;
    margin: 12px 0 7px;
    text-align: center;
}

.leftInfo {
    text-align: center;
    padding-right: 20px;
}

.leftInfo img {
    max-width: 70px;
}

.leftInfo label {
    background: #0053CD1A;
    padding: 7px 15px;
    border-radius: 30px;
    color: #0053CD;
    font-size: 11px;
    font-weight: 600;
}

.rightInfo h5 {
    font-size: 21px;
    font-weight: 500;
}

.rightInfo h6 {
    color: #0053CD;
    font-size: 18px;
    font-weight: 500;
}

.rightInfo ul {
    margin: 0;
    padding-left: 20px;
}

.rightInfo ul li {
    font-size: 15px;
    color: #000000;
    font-weight: 500;
    list-style: disc;
}

.baseInfoTable {
    background: #fff;
    padding: 30px;
    margin-top: 20px;
}

.baseTabHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.baseTabHead h5 {
    font-size: 16px;
    color: #141414;
    font-weight: 600;
    margin: 0;
}

.TabLeftFilter {
    display: flex;
    align-items: center;
}

.TabLeftFilter .formGroup {
    position: relative;
    margin-right: 20px;
}

.TabLeftFilter .formGroup span {
    position: absolute;
    top: 8px;
    left: 18px;
}

.TabLeftFilter button {
    border: 1px solid #EBF2FF;
    background: #fff;
    font-size: 14px;
    font-weight: 500;
    border-radius: 100px;
    padding: 12px 19px;
}

.TabLeftFilter button img {
    margin-right: 4px;
}

.formGroup input {
    height: 44px;
    background: #00000008;
    border: 0;
    border-radius: 100px;
    padding: 0 34px;
    font-size: 14px;
    min-width: 400px;
    padding-left: 45px;
}

.baseInfoTable tr th {
    background: #F6F7F9;
    border: 1px solid #EBF2FF;
    font-size: 12px;
    color: #989CA0;
    padding: 13px 18px;
}

.baseInfoTable tr td {
    border: 1px solid #EBF2FF;
    font-size: 12px;
    color: #141414;
    padding: 13px 18px;
}

.baseInfoTable table {
    width: 100%;
}

span.green {
    border: 1px solid #6ADB6A;
    color: #6ADB6A;
    padding: 6px 12px;
    border-radius: 100px;
    font-weight: 600;
    background: #F4F3FB;
}

span.red {
    border: 1px solid #FB3B52;
    color: #FB3B52;
    padding: 6px 12px;
    border-radius: 100px;
    font-weight: 600;
    background: #FFF0F2;
}

span.yellow {
    border: 1px solid #FFC053;
    color: #FFC053;
    padding: 6px 12px;
    border-radius: 100px;
    font-weight: 600;
    background: #FFF9F0;
}

.timeSheetdetils {
    border: 1px solid #00000045;
    border-radius: 11px;
    margin-top: 30px;
    padding: 27px;
}

.timeSheetdetilshead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 13px;
}

.timeSheetdetilshead h4 {
    font-size: 20px;
    color: #141414;
}

.timeSheetdetilshead h4 img {
    margin-left: 4px;
}

.editBtns {
    display: flex;
    align-items: center;
}

.editBtns button,
.UploaRecHead button {
    border: 1px solid #EBF2FF;
    background: #fff;
    font-size: 15px;
    border-radius: 100px;
    padding: 8px 17px;
    margin-left: 10px;
    color: #141414;
    font-weight: 500;
}

.editBtns button img,
.UploaRecHead button img {
    margin-right: 3px;
    position: relative;
    top: -1px;
}

.innerbrd {
    border-left: 1px solid #ddd;
    padding: 10px 14px;
    font-size: 15px;
    min-width: 26%;
    min-height: 43px;
}

.topBrd {
    align-items: center;
    width: 100%;
    display: flex;
    border-top: 1px solid #ddd;
}

.personalDetials {
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
    margin-bottom: 33px;
}

.topBrd.Morefieldss .innerbrd {
    min-width: 18%;
}

.topBrd.brdColor .innerbrd {
    min-width: 18%;
    background: #F8FAFE;
}

.topBrd.brdColor {
    background: #F8FAFE;

}

.billingDeailLeft ul {
    padding: 0;
    margin: 0;
}

.billingDeailLeft ul li {
    list-style: none;
    border: 1px solid #ddd;
    padding: 0px 20px;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0;
}

.billingDeailLeft ul li:last-child {
    border-bottom: 1px solid #ddd;
}

.billingDeailLeft ul li label {
    padding: 9px 0;
    border-left: 1px solid #ddd;
    min-width: 120px;
    text-align: right;
}

.addComment {
    margin-top: 24px;
}

.addComment h4 {
    font-size: 20px;
    color: #141414;
    font-weight: 600;
    margin-bottom: 11px;
}

.addComment textarea {
    width: 100%;
    border: 1px solid #00000045;
    border-radius: 10px;
    padding: 11px 14px;
    height: 100px;
}

.commentBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 14px 0 10px;
}

.commentBtn button {
    background: #0053CD;
    border-radius: 10px;
    font-size: 16px;
    color: #fff;
    border: 0;
    width: auto;
    height: 50px;
    margin: 0 7px;
    padding: 0 40px;
}

.commentBtn button.reject {
    background: #FF0000;
}

.UploadRecipt {
    border-top: 1px solid #dddd;
    padding-top: 26px;
    margin-top: 25px;
}

.UploaRecHead h5 {
    font-size: 20px;
    color: #141414;
    font-weight: 600;
    margin-bottom: 11px;
}

.UploaRecHead h5 span {
    font-size: 15px;
    font-weight: 400;
    width: 100%;
    display: table;
}

.UploaRecHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 13px;
}

.UploadRecipt table {
    width: 100%;
}

.UploadRecipt table tr th {
    background: #0053CD;
    padding: 9px 16px;
    color: #fff;
    font-size: 15px;
    font-weight: 700;
}

.UploadRecipt tr td {
    background: #F8FAFE;
    padding: 9px 16px;
    color: #2d2d2d;
    font-size: 15px;
    font-weight: 600;
    border: 1px solid #D9D9D9;
}

.UploadRecipt tr td span {
    display: flex;
    align-items: center;
}

.UploadRecipt tr td span img {
    margin-right: 9px;
}

.UploadRecipt table tr th:first-child,
.UploadRecipt table tr th:nth-child(2) {
    width: 32%;
}

ul.timeSheetFilter {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 27px 0 0;
    background: #0053CD1A;
    border-radius: 13px 13px 0 0;
    overflow: hidden;
}

ul.timeSheetFilter li span {
    font-size: 15px;
    padding: 11px 18px;
    border-right: 1px solid #ddd;
    display: flex;
    font-weight: 500;
    color: #9499A1;
}

ul.timeSheetFilter li.active span {
    background: #0053CD;
    color: #fff;
    font-weight: 500;
}

.yimesheetBrd .TimeShettTableOuter {
    border: 0;
    padding: 0;
}

.TimeShettTableOuter table span {
    border: 1px solid #FFC053;
    color: #FFC053;
    padding: 6px 11px;
    border-radius: 100px;
    font-weight: 600;
    font-size: 12px;
}

.TimeShettTableOuter table .Decloine {
    color: #FB3B52;
    border-color: #FB3B52;
}

.TimeShettTableOuter table .approvedf {
    color: #6ADB6A;
    border-color: #6ADB6A;

}

.Starptimsshetet h3 {
    margin: 0;
    color: #fff;
    font-size: 24px;
}

.Starptimsshetet {
    display: flex;
    align-items: center;
    background: #0053CD;
    padding: 23px 20px;
    border-radius: 10px 10px 0 0;
    justify-content: center;
    position: relative;
    margin-top: 30px;
}

.approved {
    display: flex;
    align-items: center;
    background: #07B638;
    padding: 23px 20px;
    border-radius: 10px 10px 0 0;
    justify-content: center;
    position: relative;
    margin-top: 30px;
}

.approved button {
    background: #FFC702;
    font-size: 16px;
    color: #000;
    font-weight: 500;
    border-radius: 100px;
    border: 0;
    padding: 9px 20px;
    margin-left: auto;
    position: absolute;
    right: 20px;
}

.Starptimsshetet button {
    background: #FFC702;
    font-size: 16px;
    color: #000;
    font-weight: 500;
    border-radius: 100px;
    border: 0;
    padding: 6px 20px;
    margin-left: auto;
    position: absolute;
    right: 20px;
}

.ExpenseReport {
    margin-top: 30px;
}

.ExpenseReportBord {
    border: 1px solid #ddd;
    border-radius: 12px;
    padding: 37px;
}

.expenseLogo img {
    margin-bottom: 20px;
}

.expenseLogo h3 {
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 37px;
}

.expenseTable .innerbrd {
    min-width: 10%;
    min-height: 52px;
    display: flex;
    align-items: center;
    padding: 0 13px;
    line-height: 1.1;
}

.Starptimsshetet h3 {
    margin: 0;
    color: #fff;
    font-size: 24px;
}

.noMarg {
    margin: 0;
    border-radius: 0 0 15px 15px;
}

.expenseModal {
    padding: 18px 18px;
}

.expenseModal label {
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 4px;
    color: #000;
}

.expenseModal input {
    background: #F7F7F7;
    height: 51px;
    border-radius: 12px;
    padding: 0 23px;
    border: 0;
    width: 100%;
}

.expenseModal textarea {
    background: #F7F7F7;
    height: 147px;
    border-radius: 12px;
    padding: 15px 23px;
    border: 0;
    width: 100%;
}

.expenseModal button {
    background: #0053CD1A;
    width: 100%;
    border-radius: 12px;
    border: 0;
    padding: 15px 0;
    color: #0e53cd;
    font-size: 16px;
    font-weight: 500;
}

.Fileupload {
    height: 87px;
    position: relative;
    border: 2px dashed #094E85;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    margin: 23px 0;
}

.Fileupload input {
    background: transparent;
    font-size: 0;
    padding: 20px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.expenseTable .topBrd {
    padding: auto;
}

.expenseModal .formGroup input[type="submit"] {
    width: auto;
    background: #0e53cd;
    color: #fff;
    font-weight: 500;
}

.Fileupload span {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: #fff;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    pointer-events: none;
}

.Fileupload span h6 {
    font-size: 14px;
    font-weight: 500;
    color: #094E85;
    margin: 0;
}

.Fileupload span p {
    margin: 4px 0 0;
    font-size: 15px;
    font-weight: 400;
}

.Fileupload span h6 img {
    margin-right: 6px;
}

.expenseModal .formGroup {
    margin-bottom: 15px;
}

.invoiceLogo h3 {
    text-align: center;
    text-transform: uppercase;
    margin: 18px 0 22px;
    font-weight: 500;
    font-size: 38px;
}

.leftInvoice ul li {
    border: 1px solid #ddd;
}

.leftInvoice ul li span {
    padding: 10px 20px;
    display: inline-block;
    border-right: 1px solid #ddd;
    width: 50%;
    font-weight: 700;
    font-size: 15px;
}

.leftInvoice ul li span:last-child {
    border: 0;
}

.leftInvoice ul h6 {
    font-size: 26px;
    margin: 0;
    border: 1px solid #ddd;
    border-bottom: 0;
    padding: 14px 20px;
    font-weight: 600;
}

.leftInvoice ul {
    padding: 0;
}

.BillingRate table {
    width: 100%;
    margin: 17px 0;
}

.BillingRate table tr th {
    border: 1px solid #ddd;
    padding: 9px 15px;
    font-size: 15px;
}

.BillingRate table tr td {
    border: 1px solid #ddd;
    padding: 9px 15px;
    font-size: 15px;
}

.leftInvoice ul li label {
    padding: 10px 20px;
    width: 50%;
    FONT-WEIGHT: 500;
}


/**/
.tabletimesheet table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
}

.tabletimesheet th span, .tabletimesheet td span{
    background-color: transparent;
    border: 0;
}
.tabletimesheet th, .tabletimesheet td {
    border: 1px solid #ddd;
    text-align: left;
    padding: 8px;
    min-width: 150px;
    font-size: 14px;
    font-family: "Poppins", serif !important;

}

/*table expense*/


.tableexpense tfoot td {
    font-weight: 600;
}
.tableexpense thead th {
    background-color: #f4f4f4;
    border-bottom: 2px solid #ddd;
    text-align: center;
    position: relative;
    padding: 10px 0;

}
.tableexpense thead th , .tableexpense tbody td, .tableexpense tfoot td {
    max-width: 100px;
    min-width: 90px;
    font-size: 12px;
}
.tableexpense thead th small {
display: block;
margin-top: 5px;
font-size: 12px;
color: #666;
border-top: 1px solid #ddd; /* Border separating th and small */
padding-top: 5px;
}

.tableexpense tbody td {
    padding: 7px;
    border: 1px solid #ddd;
    line-height: 1.2;
}

.tableexpense tfoot td:not(:first-child):not(:last-child):not(:nth-last-child(2)),
.tableexpense tbody td:not(:first-child):not(:last-child):not(:nth-last-child(2)) {
    text-align: end;
}
.tableexpense tbody td input {
width: 70%;
text-align: start;
border: 1px solid #ccc;
border-radius: 4px;
padding: 5px;
background: #f5f5f5;
}

.tableexpense tbody td:last-child a {
    color: #0794F2;
    text-decoration: none;
}
.uploadtimetable th{
    background: #0053CD;
    color: #fff;
}
.uploadtimetable tbody tr td{
    background: #F8FAFE;
}
/**/
.bginfo{
   background: #F5F7FF;
}
.tablelesswidth tr th, 
.tablelesswidth tr td {
    min-width: 100px;
}
/**/

@media (max-width:1500px) {
    .rightContainer {
        width: 80%;
        padding: 25px 18px;
        margin: auto;
    }

    .leftContainer {
        width: 20%;
    }
}

@media (max-width:1199px) {
    .Tbalelayout table tr th {
        font-size: 14px;
    }

    .Tbalelayout table tr td {
        padding: 9px 0;
        font-size: 14px;
    }

    .Tbalelayout table {
        width: 125%;
    }

    .Tbalelayout {
        overflow-x: auto;
    }

    .rightContainer h2 {
        font-size: 23px;
        margin: 0 0 16px;
    }
}

@media (max-width:991px) {
    ul.timeSheetFilter li span {
        font-size: 13px;
    }
    .leftContainer {
        width: 50%;
        position: absolute;
        display: none;
    }

    .rightContainer {
        width: 100%;
        padding: 25px 14px;
    }
}

@media (max-width:767px) {
    .zOuterTime {
        padding-bottom: 13px;
    }

    .rightContainer h2 {
        font-size: 20px;
        margin: 0 0 10px;
    }

    .tableTime {
        padding: 15px;
    }

    .Tabstable {
        padding: 14px 0 14px;
    }

    .Tabstable ul li a {
        font-size: 13px;
    }

    .Tabstable ul li {
        margin-right: 20px;
    }

    .Tbalelayout table tr th {
        padding: 9px 0;
        font-size: 12px;
    }

    .Tbalelayout table tr td {
        padding: 9px 10px 9px 0;
        font-size: 12px;
    }

    .Tbalelayout table {
        width: 258%;
    }

    .leftContainer {
        width: 80%;
    }
    ul.timeSheetFilter li span {
        font-size: 10px;
    }
}